import { useMutation, UseMutationOptions } from 'react-query'
import { fetcher } from 'src/fetcher'
import { BASE_API_URL } from 'src/globals'
import { MedplumImmunizationItem } from '../../../types'

async function createImmunization(body: MedplumImmunizationItem[]) {
    return await fetcher(
        `${BASE_API_URL}/member-service/fhir/immunization`,
        JSON.stringify(body),
        'POST'
    )
}

export const useCreateImmunizations = (
    options?: Omit<UseMutationOptions<any, any, any, any>, 'mutationFn'>
) => useMutation(createImmunization, options)
