import { createRoot } from 'react-dom/client'
import App from './App'
import * as serviceWorker from './serviceWorker'
import { initialize } from '@sumologic/opentelemetry-rum'
import { ampli, LoadOptions, Environment } from './ampli'
import { sessionReplayPlugin } from '@amplitude/plugin-session-replay-browser'

const APP_ENVIRONMENT = process.env.REACT_APP_ENVIRONMENT || 'development'
const SUMO_RUM_ENDPOINT = process.env.REACT_APP_SUMO_RUM_ENDPOINT

const loadConfig: LoadOptions = {
    environment: (process.env.REACT_APP_ITLY_ENV ||
        'development') as Environment,
    client: { configuration: { defaultTracking: true } }
}

console.log('Initializing Ampli with', { loadConfig })
ampli.load(loadConfig)

// Enabling Session Replay
const sessionReplayTracking = sessionReplayPlugin()
ampli.client.add(sessionReplayTracking)

if (SUMO_RUM_ENDPOINT) {
    initialize({
        collectionSourceUrl: SUMO_RUM_ENDPOINT,
        serviceName: `www-client-${APP_ENVIRONMENT}`,
        applicationName: `www-${APP_ENVIRONMENT}`,
        samplingProbability: 1,
        defaultAttributes: {
            'deployment.environment': APP_ENVIRONMENT
        }
    })
}

const container = document.getElementById('root')
if (!container) {
    throw new Error('No container')
}

const root = createRoot(container)

root.render(
    <>
        <App />
    </>
)

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister()
