import {
    Box,
    Button,
    Card,
    Flex,
    Heading,
    ThemeStyleObject,
    Text,
    TiaModal,
    Paragraph,
    Link,
    Spinner
} from '@asktia/tia-ui'

import { ArrowRightIcon } from 'src/components/Blocks/Icons'

import { startOfToday, subMinutes } from 'date-fns'
import { useCallback } from 'react'
import { useModal } from 'react-modal-hook'
import { generatePath } from 'react-router'
import { AppointmentProfileCard } from 'src/components/AppointmentProfileCard'
import { Divider } from 'src/components/Blocks'
import {
    useAvailableSlots,
    useClinicFiltersFromLocation
} from 'src/flows/AppointmentBooking/useAvailableSlots'
import { useAmpli, useNavigation } from 'src/hooks'
import {
    Appointment,
    AppointmentCardVariant,
    AppointmentProfile,
    AppointmentProfilePricing,
    AppointmentTreatment,
    AppointmentUIStatus,
    AvailableSlot,
    Clinic
} from 'src/types'
import { getDisplayTimestamp } from 'src/utils'
import { BOOKING_STEPS } from './useBookingFlow'
import { CloseIcon } from 'src/components/Blocks/Icons'
import capitalize from 'lodash/capitalize'
import { THREAD_LABELS } from 'src/globals'
import {
    Link as RouterLink,
    Navigate as NavigateComponent
} from 'react-router-dom'
import { useAmpliFeatureFlag } from 'src/AmplitudeExperimentProvider'
import { ProviderAccordion } from './ProviderAccordion'

const BookingCardCTA = ({
    appointmentProfile,
    onCTAClick
}: {
    appointmentProfile: AppointmentProfile
    onCTAClick: () => void
}) => {
    const careTypes = appointmentProfile.careTypes.map(
        careType => careType.name
    )
    const ctaText =
        appointmentProfile.preBookingQuestion ||
        careTypes.some(careType =>
            ['group_therapy', 'support_group'].includes(careType)
        )
            ? 'See booking options'
            : 'Book this appointment'

    return (
        <Box
            sx={{
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'space-between'
            }}
        >
            <Button onClick={onCTAClick} sx={{ px: '24px !important' }}>
                {ctaText}
            </Button>
        </Box>
    )
}

const LocationCard = ({
    clinic,
    onClick
}: {
    clinic: Clinic
    onClick: () => void
}) => {
    return (
        <Box
            onClick={onClick}
            sx={{
                'fontSize': 2,
                'alignItems': 'center',
                'background': 'cardBackground',
                'textDecoration': 'none',
                'border': t => `1px solid ${t.colors?.cardOutline}`,
                'fontWeight': 'unset !important',
                'userSelect': 'none',
                'borderRadius': 2,
                'py': 4,
                'px': 5,
                'mb': 4,
                ':hover': {
                    borderColor: 'inputOutlineHover'
                },
                ':active': {
                    background: 'inputBackgroundSelected',
                    borderColor: 'inputOutlineHover'
                }
            }}
        >
            <Flex sx={{ justifyContent: 'space-between' }}>
                <Flex
                    sx={{
                        justifyContent: 'flex-start',
                        flexDirection: 'column'
                    }}
                >
                    <Text
                        variant="h4"
                        sx={{
                            my: 0,
                            color: 'text'
                        }}
                    >
                        {clinic.name.toLowerCase().includes('url')
                            ? 'Tia Virtual'
                            : `Tia ${clinic.name}`}
                    </Text>
                </Flex>
                <Flex sx={{ alignItems: 'center', justifyContent: 'center' }}>
                    <ArrowRightIcon color="text" />
                </Flex>
            </Flex>
        </Box>
    )
}

export const ClinicsWithOfferings = ({
    appointmentProfile
}: {
    appointmentProfile?: AppointmentProfile
}) => {
    const { navigate } = useNavigation()
    const { preSlotSearchLocationSelected } = useAmpli()

    const clinics = appointmentProfile?.clinicsWithOffering

    const virtualIndex = clinics?.findIndex(clinic =>
        clinic.name.toLowerCase().includes('url')
    )

    const virtualAndAlphabeticalComparison = (c1: Clinic, c2: Clinic) => {
        // virtual always comes first
        if (c1.name.toLowerCase().includes('url')) {
            return -1
        }
        if (c2.name.toLowerCase().includes('url')) {
            return 1
        }

        return c1.name < c2.name ? -1 : 1
    }

    return (
        <>
            {clinics &&
                clinics.sort(virtualAndAlphabeticalComparison).map(clinic => (
                    <LocationCard
                        clinic={clinic}
                        onClick={() => {
                            preSlotSearchLocationSelected(clinic.name)
                            navigate(
                                `${generatePath(BOOKING_STEPS.deeplink.path, {
                                    appointmentProfileUuid:
                                        appointmentProfile.appointmentProfileUuid
                                })}${`?clinicUuids=${clinic.uuid}`}`
                            )
                        }}
                    ></LocationCard>
                ))}
        </>
    )
}

const PreSearchLocationSelection = (props: {
    appointmentProfile: AppointmentProfile
}) => {
    return (
        <>
            <Heading h4 sx={{ color: 'white' }}>
                Choose where you want to have your appointment
            </Heading>
            <ClinicsWithOfferings
                appointmentProfile={props.appointmentProfile}
            />
        </>
    )
}

// The modal that opens when we load slots
const BookingModal = (props: {
    hideModal: () => void
    appointmentProfile: AppointmentProfile
}) => {
    const isBookingMaintenanceMode =
        useAmpliFeatureFlag('booking-maintenance-mode') === 'on'

    return (
        <TiaModal sx={{ p: 0 }}>
            <Box
                onClick={() => props.hideModal()}
                sx={{ float: 'right', p: 5, cursor: 'pointer' }}
            >
                <CloseIcon color="text" />
            </Box>
            <AppointmentProfileCard
                appointmentProfile={props.appointmentProfile}
                sx={{
                    borderBottomLeftRadius: 0,
                    borderBottomRightRadius: 0,
                    flex: 1
                }}
            />
            <Box
                sx={{
                    bg: 'primaryButton',
                    py: 4,
                    px: 5,
                    borderBottomRightRadius: 2,
                    borderBottomLeftRadius: 2
                }}
            >
                {isBookingMaintenanceMode ? (
                    <Paragraph sx={{ color: 'white' }}>
                        Sorry, our booking service is down for scheduled
                        maintenance. Please check back after 9PM PT to book an
                        appointment.
                    </Paragraph>
                ) : (
                    <PreSearchLocationSelection
                        appointmentProfile={props.appointmentProfile}
                    />
                )}
            </Box>
        </TiaModal>
    )
}

// Hook that coordinates state for booking card
// returns current footer to show and a method to start process
function useBookingModal(appointmentProfile: AppointmentProfile) {
    const [showBookingModal, hideBookingModal] = useModal(
        () => (
            <BookingModal
                hideModal={hideBookingModal}
                appointmentProfile={appointmentProfile}
            />
        ),
        [appointmentProfile]
    )

    return showBookingModal
}

const SmallBookingCard = (props: {
    appointmentProfile: AppointmentProfile
    careType?: string
    sx?: ThemeStyleObject
    disabled?: boolean
    descriptionMaxLines?: number
    onClick: () => void
}) => {
    const { appointmentProfile, disabled, descriptionMaxLines, sx, onClick } =
        props

    return (
        <Flex
            sx={{
                'mb': 4,
                'flexDirection': 'column',
                'borderRadius': 2,
                'border': '1px solid',
                'borderColor': disabled ? 'inactiveText' : 'oat',
                'cursor': 'pointer',
                'height': '100%',
                ':hover': {
                    borderColor: !disabled ? 'inputOutlineHover' : undefined
                }
            }}
            onClick={onClick}
        >
            <AppointmentProfileCard
                appointmentProfile={appointmentProfile}
                sx={{
                    borderBottom: 'none',
                    flex: 1,
                    ...sx
                }}
                descriptionMaxLines={descriptionMaxLines}
                disabled={disabled}
            />
        </Flex>
    )
}

const LargeBookingCard = (props: {
    appointmentProfile: AppointmentProfile
    careType?: string
    sx?: ThemeStyleObject
    disabled?: boolean
    descriptionMaxLines?: number
    onClick: () => void
}) => {
    const { appointmentProfile, disabled, descriptionMaxLines, sx, onClick } =
        props
    return (
        <Flex
            sx={{
                mb: 4,
                flexDirection: 'column',
                borderRadius: 2,
                border: '1px solid',
                borderColor: disabled ? 'inactiveText' : 'oat'
            }}
        >
            <AppointmentProfileCard
                appointmentProfile={appointmentProfile}
                sx={{
                    borderBottomLeftRadius: 0,
                    borderBottomRightRadius: 0,
                    flex: 1,
                    ...sx
                }}
                descriptionMaxLines={descriptionMaxLines}
                disabled={disabled}
            />

            <Box
                sx={{
                    bg: 'white',
                    p: 5,
                    pt: 0,
                    borderBottomRightRadius: 2,
                    borderBottomLeftRadius: 2,
                    cursor: disabled ? 'inherit' : 'pointer'
                }}
            >
                <BookingCardCTA
                    appointmentProfile={appointmentProfile}
                    onCTAClick={onClick}
                />
            </Box>
        </Flex>
    )
}

// Renders booking card
export const BookingCard = (props: {
    appointmentProfile: AppointmentProfile
    variant?: 'small' | 'large'
    careType?: string
    sx?: ThemeStyleObject
    disabled?: boolean
    descriptionMaxLines?: number
    isCardClickable?: boolean
}) => {
    const { appointmentProfile, disabled, variant = 'large' } = props
    const showDeactivatedModal = useDeactivatedBookingModal()

    const { locationFilters } = useClinicFiltersFromLocation()

    const onCtaClick = useCallback(() => {
        if (disabled) {
            showDeactivatedModal()
            return
        }

        appointmentCardCtaClicked(appointmentProfile)
        const careTypes = appointmentProfile.careTypes.map(
            careType => careType.name
        )
        if (
            careTypes.some(careType =>
                ['group_therapy', 'support_group'].includes(careType)
            )
        ) {
            navigate(
                `/book-appointment/profile/${appointmentProfile.appointmentProfileUuid}/time-selection`
            )
        } else if (!appointmentProfile.preBookingQuestion) {
            if (appointmentProfile.clinicsWithOffering.length < 2) {
                navigate(
                    `${generatePath(BOOKING_STEPS.deeplink.path, {
                        appointmentProfileUuid:
                            props.appointmentProfile.appointmentProfileUuid
                    })}${
                        locationFilters ? `?locations=${locationFilters}` : ''
                    }`
                )
            } else {
                showBookingModal()
            }
        } else {
            navigate(
                `/book-appointment/profile/${appointmentProfile.appointmentProfileUuid}/pre-questions`
            )
        }
    }, [])

    const showBookingModal = useBookingModal(appointmentProfile)
    const { appointmentCardCtaClicked } = useAmpli()
    const { navigate } = useNavigation()

    return variant === 'small' ? (
        <SmallBookingCard {...props} onClick={onCtaClick}></SmallBookingCard>
    ) : (
        <LargeBookingCard {...props} onClick={onCtaClick}></LargeBookingCard>
    )
}

// TODO: merge with AppointmentCard (https://asktia.atlassian.net/browse/EN-196)
export const BookingCardDetails = (props: {
    appointmentProfile: AppointmentProfile
    selectedTreatments: AppointmentTreatment[]
    hideDescription?: boolean
    hidePricing?: boolean
    slot?: AvailableSlot
    sx?: ThemeStyleObject
}) => {
    const { appointmentProfile, hideDescription, hidePricing, slot } = props
    const hasExtras =
        !!slot || (!hidePricing && appointmentProfile.pricingDetails)
    const stylesForCardWithExtras = hasExtras
        ? { borderBottomLeftRadius: 0, borderBottomRightRadius: 0 }
        : {}

    return (
        <Box
            sx={{
                border: '1px solid',
                borderRadius: 2,
                borderColor: 'oat',
                ...props.sx
            }}
        >
            <AppointmentProfileCard
                appointmentProfile={appointmentProfile}
                hideDescription={hideDescription}
                sx={{ ...stylesForCardWithExtras }}
                location={slot ? slot.locationDetails.modality : undefined}
                locationDetails={slot ? slot.locationDetails : undefined}
                flatBottom={false}
                provider={
                    slot?.providerName && slot?.providerGender
                        ? {
                              name: slot.providerName,
                              gender: slot.providerGender,
                              pronoun: slot.providerPronoun
                          }
                        : undefined
                }
            />
            {hasExtras && (
                <Card
                    sx={{
                        border: 'none',
                        borderTopLeftRadius: 0,
                        borderTopRightRadius: 0
                    }}
                >
                    {slot && <BookingCardSlotDetails slot={slot} />}
                    {slot && appointmentProfile.pricingDetails && <Divider />}
                    {appointmentProfile.pricingDetails && (
                        <BookingCardPricingDetails
                            pricing={appointmentProfile.pricingDetails}
                            selectedTreatments={props.selectedTreatments}
                        />
                    )}
                </Card>
            )}
        </Box>
    )
}

// Helper component for virtual instructions copy
export const VirtualInstructions = ({
    slot,
    variant,
    appointmentStatus,
    appointment
}: {
    slot: Pick<AvailableSlot, 'locationDetails' | 'startTime'>
    variant?: AppointmentCardVariant
    appointmentStatus?: AppointmentUIStatus
    appointment?: Appointment
}): JSX.Element => {
    const joinTime = subMinutes(slot.startTime, 5)
    const lowerTimeBound = getDisplayTimestamp(
        joinTime,
        slot.locationDetails.timezone
    )

    if (variant === 'homepage') {
        if (new Date() >= joinTime) {
            return (
                <Paragraph sx={{ fontSize: 0, color: 'supportText' }}>
                    When it's time, tap the button below to start your
                    appointment. It will become active at {lowerTimeBound.time}{' '}
                    {lowerTimeBound.timezone} - 5 minutes before it is scheduled
                    to start.
                </Paragraph>
            )
        }

        return (
            <Paragraph sx={{ fontSize: 0, color: 'supportText' }}>
                Your appointment will take place via video.
            </Paragraph>
        )
    } else if (variant === 'ACI') {
        return (
            <Paragraph sx={{ fontSize: 0, color: 'supportText' }}>
                Your appointment will take place via video.
            </Paragraph>
        )
    }

    return (
        <Paragraph sx={{ fontSize: 0, color: 'supportText' }}>
            Your appointment will take place via video. Look out for a message
            with a secure link beforehand!
        </Paragraph>
    )
}

export type CalendarExportType = 'in-date' | 'button' | 'none'

export const BookingCardSlotDetails = ({ slot }: { slot: AvailableSlot }) => {
    const startTimeDisplay = getDisplayTimestamp(
        slot.startTime,
        slot.locationDetails.timezone
    )
    const locationLine1 = `${slot.locationDetails.clinic.address1} ${
        slot.locationDetails.clinic.address2 || ''
    }`
    const locationLine2 = `${slot.locationDetails.clinic.city}, ${slot.locationDetails.clinic.state} ${slot.locationDetails.clinic.postalCode}`

    const calendarExportProps = {}

    return (
        <Box>
            <Heading h4 sx={{ mb: 2 }}>
                <Text {...calendarExportProps}>
                    {`${startTimeDisplay.day}, ${startTimeDisplay.time} ${startTimeDisplay.timezone}`}
                    {slot.cadence && `, ${capitalize(slot.cadence)}`}
                </Text>
            </Heading>
            {slot.locationDetails.modality === 'clinic' ? (
                <Box sx={{ fontSize: 0 }}>
                    <Text sx={{ mb: 1 }} as="div">
                        {slot.locationDetails.clinic.name}
                    </Text>
                    <Text sx={{ color: 'supportText' }}>
                        {locationLine1}
                        <br />
                        {locationLine2}
                    </Text>
                </Box>
            ) : (
                <VirtualInstructions slot={slot} />
            )}

            {slot.providerName && (
                <ProviderAccordion
                    title={slot.providerName}
                    pronoun={slot.providerPronoun}
                    description={slot.providerBio}
                    avatarUrl={slot.providerAvatarUrl}
                />
            )}
        </Box>
    )
}

export const BookingCardPricingDetails = ({
    pricing,
    selectedTreatments
}: {
    pricing: AppointmentProfilePricing
    selectedTreatments: AppointmentTreatment[]
}) => {
    const { text, showServicePriceList, treatmentPrice } = pricing
    return (
        <Box>
            {selectedTreatments.map(t => (
                <Paragraph>{`+$${treatmentPrice} ${t.name}`}</Paragraph>
            ))}
            <Paragraph sx={{ fontSize: 0, color: 'supportText' }}>
                {text}
            </Paragraph>
            {showServicePriceList && (
                <Paragraph>
                    <Link
                        sx={{ fontSize: 0, color: 'primaryButton' }}
                        href="https://asktia.com/tia-service-list"
                        target="_blank"
                    >
                        See cash price
                    </Link>
                </Paragraph>
            )}
        </Box>
    )
}
function useDeactivatedBookingModal() {
    const [showDeactivatedBookingModal, hideDeactivatedBookingModal] = useModal(
        () => (
            <BookingCardDeactivatedModal
                hideModal={hideDeactivatedBookingModal}
            />
        )
    )

    return showDeactivatedBookingModal
}
const BookingCardDeactivatedModal = (props: { hideModal: () => void }) => {
    return (
        <TiaModal shouldCloseOnOverlayClick={false}>
            <Box
                onClick={() => props.hideModal()}
                sx={{ float: 'right', cursor: 'pointer' }}
            >
                <CloseIcon color="text" />
            </Box>
            <Flex
                sx={{
                    justifyContent: 'space-between',
                    alignItems: 'left',
                    mb: 4
                }}
            >
                <Heading h2 sx={{ m: 0, p: 0 }}>
                    Your membership is no longer active.
                </Heading>
            </Flex>
            <Text as="p" sx={{ mb: 5 }}>
                To reactivate your membership, please message your Care
                Coordinator.
            </Text>
            <Button
                as={RouterLink}
                // @ts-ignore: TS doesn't understand this is a RouterLink now
                to={`/chat/new?label=${THREAD_LABELS.billing}&subject=My deactivated membership&message=I would like to re-purchase my membership`}
                sx={{ fontSize: 'inherit' }}
                fullWidth
                onClick={() => props.hideModal()}
            >
                Message Care Coordinator
            </Button>
        </TiaModal>
    )
}
