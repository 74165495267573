import { isNil, omitBy, uniq } from 'lodash'
import { useMemo } from 'react'
import { useQuery, useQueryClient } from 'react-query'
import { fetcher } from 'src/fetcher'
import { BASE_API_URL } from 'src/globals'
import { AvailableSlot, DataBlob, QueryParams } from 'src/types'

// TODO: can we use the same endpoint name for this?
// listing off /tmd-api/appointments/available-slots
// and fetching off /appointments/appointment-slots/{slotId}/start-time/{startTime}
// is weird 🥴

export async function findAvailableSlots(queryParams: QueryParams) {
    const apiSlots = await fetcher<DataBlob[]>(
        `${BASE_API_URL}/tmd-api/appointments/available-slots?apiVersion=v2`,
        JSON.stringify(queryParams),
        'POST',
        {
            errorMsg: 'Failed to fetch available slots'
        }
    )

    return apiSlots.map(apiSlot => castSlot(apiSlot))
}

export const useAvailableSlots = (args: {
    appointmentProfileUuid: string
    startDate: Date
    endDate?: Date
    maxSlots?: number
    filters?: QueryParams
    enabled?: boolean
    appointmentType?: string
    rescheduleAppointmentUuid?: string
    appointmentSuggestionUuid?: string
}): { slots: AvailableSlot[]; isLoading: boolean } => {
    const { enabled = true } = args
    const queryClient = useQueryClient()

    // Flatten query for filtering
    const queryParams = {
        appointmentProfileUuid: args.appointmentProfileUuid,
        startDate: args.startDate,
        endDate: args.endDate,
        maxSlots: args.maxSlots,
        uniqueStartTime: true,
        reschedule: args.rescheduleAppointmentUuid,
        appointmentSuggestionUuid: args.appointmentSuggestionUuid,
        ...args.filters
    }
    const slotsQuery = useQuery<AvailableSlot[]>(
        ['appointment-profile-slots', JSON.stringify(queryParams)],
        async () => {
            return await findAvailableSlots(queryParams)
        },
        {
            staleTime: 1000 * 60 * 3, // refetch rendered data every 3 minutes
            enabled: enabled,
            // if something is wrong, show error state quickly
            retry: false
        }
    )

    // Pre-warm cache for any future fetches of these slots
    if (slotsQuery.data) {
        slotsQuery.data.forEach(slot => {
            queryClient.setQueryData(['appointment-slot', slot.slotId], slot)
        })
    }

    return {
        slots: slotsQuery.data || [],
        isLoading: slotsQuery.isLoading
    }
}

async function getAppointmentSlot(slotId: string) {
    return await fetcher(
        `${BASE_API_URL}/tmd-api/appointments/appointment-slots/${slotId}?apiVersion=v2`,
        undefined,
        'GET',
        {
            errorMsg: 'Failed to fetch available slot'
        }
    )
}

export const useAvailableSlot = (
    slotId?: string
): { slot: AvailableSlot | undefined; isLoading: boolean } => {
    const query = useQuery<AvailableSlot>(
        [`appointment-slot`, slotId],
        async () => {
            const apiSlot = await getAppointmentSlot(slotId!)
            return castSlot(apiSlot)
        },
        {
            enabled: !!slotId
        }
    )

    return {
        slot: query.data,
        isLoading: query.isLoading
    }
}

const castSlot = (apiSlot: DataBlob) => {
    const startTime = new Date(apiSlot.startTime as string)
    return {
        ...apiSlot,
        startTime
    } as AvailableSlot
}

export const getFiltersFromUrl = () => {
    const searchParams = new URLSearchParams(window.location.search)

    return omitBy(
        {
            context: searchParams.get('context'),
            reschedule: searchParams.get('reschedule'),
            clinicUuids: searchParams.get('clinicUuids')?.split(',') || [],
            startDate: searchParams.get('startDate') || new Date(),
            providerGender:
                searchParams.get('providerGender')?.split(',') || [],
            providerUuids: searchParams.get('providerUuids')?.split(',') || [],
            providerCommunicationStyle:
                searchParams.get('providerCommunicationStyle') || [],
            providerEthnicity: searchParams.get('providerEthnicity') || [],
            cadence: searchParams.get('cadence') || 'weekly'
        },
        value =>
            isNil(value) ||
            (Array.isArray(value) && value.length === 0) ||
            value === ''
    )
}

export const useClinicFiltersFromLocation = () => {
    const queryParams: any = getFiltersFromUrl()

    return useMemo(
        () => ({
            locationFilters: queryParams.locations,
            filters: queryParams as any
        }),
        []
    )
}
