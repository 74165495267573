export const BASE_API_URL = [
    window.location.protocol,
    window.location.host
].join('//')

export const APPOINTMENT_PROFILE_NAMES = {
    TALK_THERAPY_INTAKE: 'talk_therapy_intake',
    TALK_THERAPY_PROGRAM: 'talk_therapy_program',
    TALK_THERAPY_RETURNING: 'talk_therapy_returning',
    WHOLE_HEALTH_EXAM: 'tia_whole_health_exam',
    MASSAGE: 'massage',
    MENOPAUSE_FOLLOWUP: 'menopause_followup',
    MENOPAUSE_PERIMENOPAUSE: 'menopause_perimenopause',
    FERTILITY_CONCEPTION: 'fertility_conception',
    FERTILITY_ASSESSMENT: 'fertility_planning',
    FERTILITY_FOLLOWUP: 'fertility_follow_up',
    GROUP_THERAPY: 'group_therapy',
    ANNUAL_EXAM: 'annual_preventative_exam'
} as const

export const PREVENTATIVE_CARE_APPOINTMENTS = [
    'tia_whole_health_exam',
    'annual_preventative_exam'
]

/**
 * [EN-484] Make Tia Holiday dates and Tia Clinic Hours data driven
 * https://asktia.atlassian.net/browse/EN-484
 */
export const TIA_HOLIDAYS = [
    '1/15/2024',
    '5/27/2024',
    '6/19/2024',
    '7/4/2024',
    '9/2/2024',
    '11/28/2024',
    '11/29/2024',
    '12/24/2024',
    '12/25/2024',
    '12/31/2024',
    '1/1/2025'
]

/**
 * [EN-388] thread labels shouldn't be hardcoded, they should come from the BE
 */
export const THREAD_LABELS = {
    scheduling: 'Appointment Scheduling',
    billing: 'Billing Question',
    membership: 'Membership Question',
    prescription: 'Prescription Refill',
    medical: 'Medical Question',
    insurance: 'Insurance Question',
    formsAndDocs: 'Forms & Documents',
    checkIn: 'Appointment Check-in Question',
    feedback: 'Share Feedback',
    tech: 'Tech Issue',
    other: 'Other'
} as const

export type ThreadLabel = (typeof THREAD_LABELS)[keyof typeof THREAD_LABELS]

// Token provider constants used in the X-TOKEN-PROVIDER headers of request
// (as seen in tia-common/lib/constants.js)
// PS: X_TOKEN_PROVIDER_DRCHRONO is 1, if you're wondering
export const X_TOKEN_PROVIDER_CLIENT_CREDS = 2
