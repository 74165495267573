import { FC } from 'react'
import { Container, Flex, Text, LayoutGrid } from '@asktia/tia-ui'
import { ReactComponent as CatastrophicError } from 'src/assets/catastrophic-error.svg'

export const ErrorComponent: FC<Record<string, never>> = () => {
    return (
        <Container>
            <LayoutGrid columns="one">
                <Flex sx={{ flexDirection: 'column' }}>
                    <CatastrophicError />
                    <Text sx={{ pt: 1, fontSize: 4 }}>
                        Something went wrong, please try again. We've notified
                        our team about it.
                    </Text>
                </Flex>
            </LayoutGrid>
        </Container>
    )
}
