/* tslint:disable */
/* eslint-disable */
// @ts-nocheck
/**
 * Ampli - A strong typed wrapper for your Analytics
 *
 * This file is generated by Amplitude.
 * To update run 'ampli pull web'
 *
 * Required dependencies: @amplitude/analytics-browser@^1.3.0
 * Tracking Plan Version: 218
 * Build: 1.0.0
 * Runtime: browser:typescript-ampli-v2
 *
 * [View Tracking Plan](https://data.amplitude.com/asktia/Asktia/events/main/latest)
 *
 * [Full Setup Instructions](https://data.amplitude.com/asktia/Asktia/implementation/web)
 */

import * as amplitude from '@amplitude/analytics-browser'

export type Environment = 'production' | 'development'

export const ApiKey: Record<Environment, string> = {
    production: 'd2c588dc1f8a91987d7540e4521eef21',
    development: '9018a17347d3e4c10774fd74f8eaef99'
}

/**
 * Default Amplitude configuration options. Contains tracking plan information.
 */
export const DefaultConfiguration: BrowserOptions = {
    plan: {
        version: '218',
        branch: 'main',
        source: 'web',
        versionId: 'f650b3a8-0284-44e4-b68d-3f925d27a720'
    },
    ...{
        ingestionMetadata: {
            sourceName: 'browser-typescript-ampli',
            sourceVersion: '2.0.0'
        }
    }
}

export interface LoadOptionsBase {
    disabled?: boolean
}

export type LoadOptionsWithEnvironment = LoadOptionsBase & {
    environment: Environment
    client?: { configuration?: BrowserOptions }
}
export type LoadOptionsWithApiKey = LoadOptionsBase & {
    client: { apiKey: string; configuration?: BrowserOptions }
}
export type LoadOptionsWithClientInstance = LoadOptionsBase & {
    client: { instance: BrowserClient }
}

export type LoadOptions =
    | LoadOptionsWithEnvironment
    | LoadOptionsWithApiKey
    | LoadOptionsWithClientInstance

export interface IdentifyProperties {
    /**
     * If this is an active member or not. Set this as active when a user's membership payment is verified, and inactive after cancellation.
     */
    activeMembership?: string
    /**
     * Tells us appts completed per month by user over 12 months
     *
     * | Rule | Value |
     * |---|---|
     * | Type | number |
     */
    appointment_frequency?: number
    /**
     * Tells us the total appointments completed by the user
     *
     * | Rule | Value |
     * |---|---|
     * | Type | integer |
     */
    appointment_volume?: number
    /**
     * Set the arousal result from the stress quiz.
     */
    arousal?: string
    /**
     * Contains the last 4 digits of the latest card on file for a member. This is updated whenever a member updates their card on file
     */
    cardLast4?: string
    dashboard_group?: string
    /**
     * Stores the remaining deductible amount of the patient's insurance plan whenever we receive this as part of the eligibility response.
     *
     * | Rule | Value |
     * |---|---|
     * | Type | number |
     */
    deductibleRemaining?: number
    /**
     * Email
     */
    email?: string
    /**
     * Date of when user created their account.
     */
    emailCreationDate?: string
    /**
     * Set the energy result from the stress quiz.
     */
    energy?: string
    /**
     * UUID/GUID
     */
    externalId?: string
    /**
     * Tells us the first IRL clinic the user visited
     */
    first_irl_clinic?: string
    /**
     * First Name
     */
    first_name?: string
    /**
     * Name of current clinic location. This is the same as 'originatingClinicLocation' unless updated in Settings or elsewhere in the experience.
     */
    homeLocation?: string
    initial_referrer?: string
    initial_referring_domain?: string
    initial_utm_campaign?: string
    initial_utm_content?: string
    initial_utm_id?: string
    initial_utm_medium?: string
    initial_utm_source?: string
    initial_utm_term?: string
    insuranceEligibilityStatus?: string
    insuranceNetworkStatus?: string
    /**
     * Whether or not the user has insurance on file.
     */
    insuranceOnFile?: boolean
    /**
     * The insurance status of the user, whether it is 'eligible' | 'ineligible' | 'unsure'.
     */
    insuranceStatus?: string
    /**
     * Tells us the most recent IRL clinic the user visited
     */
    last_irl_clinic?: string
    /**
     * Last Name
     */
    last_name?: string
    /**
     * last time meber completed annual preventative exam
     */
    lastCompletedAnnualPreventativeExam?: string
    /**
     * last time someone completed whole health exam in clinic
     */
    lastCompletedTiaWholeHealthInClinicCare?: string
    mailingAddressCity?: string
    mailingAddressLine1?: string
    mailingAddressLine2?: string
    mailingAddressPostalCode?: string
    mailingAddressState?: string
    /**
     * Tells us the date the user's membership was deactivated
     */
    member_end_date?: string
    /**
     * Indicates the current status of the membership, either active, frozen, cancelled or (none). None indicates either an internal user or a Tia user that didn't convert into a member.
     */
    membership?: string
    /**
     * Date member originally signed up for their Tia account.
     */
    memberSignUpDate?: string
    /**
     * Tells us the date of the user's next schedule annual (tia_whole_health_in_clinic_care)
     */
    next_scheduled_tia_whole_health_in_clinic_care?: string
    /**
     * Location where user originally signed up.
     */
    originatingClinicLocation?: string
    /**
     * Tracks the user's latest payment method brand
     */
    paymentMethodBrand?: string
    /**
     * Phone Number
     */
    phone?: string
    /**
     * What pricing model the user is under currently. In most instances the property will be monthly or annual. But in some of the old members they could have a pricingModel of 1 and 2.
     *
     * * 1: monthly
     *
     * * 2: annual
     */
    pricingModel?: string
    /**
     * The promo code that was used during registration.
     */
    promoCodeUsed?: string
    /**
     * The referral code the user used initially during sign up, if available.
     */
    referralCode?: string
    /**
     * The last page the user was on (for example, https://amplitude.com/behavioral-analytics-platform?ref=nav)
     */
    referrer?: string
    /**
     * The domain that the user was last on (for example, https://amplitude.com)
     */
    referring_domain?: string
    /**
     * The date the membership is due for renewal
     */
    renewalTimestamp?: string
    /**
     * The cookie ID or session ID.
     */
    sessionId?: string
    show_appointments_page_group?: string
    twhe_1_goals_group?: string
    twhe_onboarding_group?: string
    userFromWebinar?: boolean
    /**
     * If this property is set, it means this user was created because they took part of a CDP event, like zoom, intercom, braze, etc.
     */
    userOriginatedFrom?: string
    using_www_for_member_creation?: boolean
    usingMemberService?: boolean
    /**
     * This identifies a specific campaign used (for example, "summer_sale")
     */
    utm_campaign?: string
    /**
     * This identifies what brought the user to the site and is commonly used for A/B testing (for example, "banner-link", "text-link")
     */
    utm_content?: string
    utm_id?: string
    utm_medium?: string
    /**
     * This identifies which website sent the traffic (for example, Google, Facebook)
     */
    utm_source?: string
    /**
     * This identifies paid search terms used (for example, product+analytics)
     */
    utm_term?: string
    /**
     * The UTM Campaign Code.
     */
    utmCampaign?: string
    /**
     * The UTM Source code.
     */
    utmSource?: string
}

export interface AddToCalendarClickedProperties {
    /**
     * the calendar that the user selected. Options are:
     *
     * * Google
     *
     * * iCal
     *
     * * Outlook
     */
    calendarSelected?: string
}

export interface AddToWaitlistNoTimeSelectedProperties {
    appointmentDate?: string
    /**
     * The day of the week the appointment is scheduled.
     *
     * https://github.com/AskTia/tmd-server/blob/19bd959d827eb8cfdf9b825b84c6c5f639256a75/services/appointment-booking/index.js#L638
     */
    appointmentDayOfWeek?: string
    /**
     * The stated goal of the appointment.
     *
     * https://github.com/AskTia/tmd-server/blob/19bd959d827eb8cfdf9b825b84c6c5f639256a75/services/appointment-booking/index.js#L638
     */
    appointmentGoal?: string
    /**
     * The unique identifier for the appointment.
     *
     *
     * https://github.com/AskTia/tmd-server/blob/19bd959d827eb8cfdf9b825b84c6c5f639256a75/services/appointment-booking/index.js#L658
     */
    appointmentId?: string
    /**
     * The clinic location of the appointment being booked.
     *
     *
     *
     * https://github.com/AskTia/tmd-server/blob/19bd959d827eb8cfdf9b825b84c6c5f639256a75/services/appointment-booking/index.js#L639
     */
    appointmentLocation?: string
    /**
     * The appointment profile
     *
     * https://github.com/AskTia/tmd-server/blob/19bd959d827eb8cfdf9b825b84c6c5f639256a75/services/appointment-booking/index.js#L577
     *
     * Its uuid is saved on the appointment further down in the function
     *
     * ```
     * ehr.appointment.appointment_profile_uuid
     * ```
     */
    appointmentProfile?: string
    appointmentSuggestionUuid?: string
    /**
     * The time of the appointment
     */
    appointmentTime?: string
    /**
     * The type of appointment.
     *
     * https://github.com/AskTia/tmd-server/blob/19bd959d827eb8cfdf9b825b84c6c5f639256a75/services/appointment-booking/index.js#L577
     *
     * This is accessible throught the
     *
     * ```
     * ehr.appointment_profile.appointment_type_uuid
     * ```
     */
    appointmentType?: string
    /**
     * Did the user or the admin make this change to the booking?
     */
    booker?: string
    /**
     * The name of the pathway taken to book the appointment.
     *
     * Examples:
     * Care Type Card, Quick Book
     */
    bookingPath?: string
    careType?: string
    /**
     * The unique identifier of the exam room chosen for the appointment.
     *
     * https://github.com/AskTia/tmd-server/blob/19bd959d827eb8cfdf9b825b84c6c5f639256a75/services/appointment-booking/index.js#L624
     */
    examRoomId?: string
    /**
     * Appointment has intake y/n
     *
     * This can be resolved using the goal the appointment is booked with and the ehr.goal_intake_map
     *
     * This data is not fetched for booking, and will need to be resolved separately.
     */
    intake?: boolean
    /**
     * Whether the appoinment virtual or not
     */
    isVirtual?: boolean
}

export interface AddToWaitlistTimeSelectedClickedProperties {
    appointmentDate?: string
    /**
     * The day of the week the appointment is scheduled.
     *
     * https://github.com/AskTia/tmd-server/blob/19bd959d827eb8cfdf9b825b84c6c5f639256a75/services/appointment-booking/index.js#L638
     */
    appointmentDayOfWeek?: string
    /**
     * The stated goal of the appointment.
     *
     * https://github.com/AskTia/tmd-server/blob/19bd959d827eb8cfdf9b825b84c6c5f639256a75/services/appointment-booking/index.js#L638
     */
    appointmentGoal?: string
    /**
     * The unique identifier for the appointment.
     *
     *
     * https://github.com/AskTia/tmd-server/blob/19bd959d827eb8cfdf9b825b84c6c5f639256a75/services/appointment-booking/index.js#L658
     */
    appointmentId?: string
    /**
     * The clinic location of the appointment being booked.
     *
     *
     *
     * https://github.com/AskTia/tmd-server/blob/19bd959d827eb8cfdf9b825b84c6c5f639256a75/services/appointment-booking/index.js#L639
     */
    appointmentLocation?: string
    /**
     * The appointment profile
     *
     * https://github.com/AskTia/tmd-server/blob/19bd959d827eb8cfdf9b825b84c6c5f639256a75/services/appointment-booking/index.js#L577
     *
     * Its uuid is saved on the appointment further down in the function
     *
     * ```
     * ehr.appointment.appointment_profile_uuid
     * ```
     */
    appointmentProfile?: string
    appointmentSuggestionUuid?: string
    /**
     * The time of the appointment
     */
    appointmentTime?: string
    /**
     * The type of appointment.
     *
     * https://github.com/AskTia/tmd-server/blob/19bd959d827eb8cfdf9b825b84c6c5f639256a75/services/appointment-booking/index.js#L577
     *
     * This is accessible throught the
     *
     * ```
     * ehr.appointment_profile.appointment_type_uuid
     * ```
     */
    appointmentType?: string
    /**
     * Did the user or the admin make this change to the booking?
     */
    booker?: string
    /**
     * The name of the pathway taken to book the appointment.
     *
     * Examples:
     * Care Type Card, Quick Book
     */
    bookingPath?: string
    careType?: string
    /**
     * The unique identifier of the exam room chosen for the appointment.
     *
     * https://github.com/AskTia/tmd-server/blob/19bd959d827eb8cfdf9b825b84c6c5f639256a75/services/appointment-booking/index.js#L624
     */
    examRoomId?: string
    /**
     * Appointment has intake y/n
     *
     * This can be resolved using the goal the appointment is booked with and the ehr.goal_intake_map
     *
     * This data is not fetched for booking, and will need to be resolved separately.
     */
    intake?: boolean
    /**
     * Whether the appoinment virtual or not
     */
    isVirtual?: boolean
}

export interface AnnouncementCtaProperties {
    /**
     * the type of announcement: whether that be for therapy, acupuncture, covid, etc
     */
    announcement_name: string
    path: string
}

export interface AppointmentCardCtaClickedProperties {
    appointmentDate?: string
    /**
     * The day of the week the appointment is scheduled.
     *
     * https://github.com/AskTia/tmd-server/blob/19bd959d827eb8cfdf9b825b84c6c5f639256a75/services/appointment-booking/index.js#L638
     */
    appointmentDayOfWeek?: string
    /**
     * The stated goal of the appointment.
     *
     * https://github.com/AskTia/tmd-server/blob/19bd959d827eb8cfdf9b825b84c6c5f639256a75/services/appointment-booking/index.js#L638
     */
    appointmentGoal?: string
    /**
     * The unique identifier for the appointment.
     *
     *
     * https://github.com/AskTia/tmd-server/blob/19bd959d827eb8cfdf9b825b84c6c5f639256a75/services/appointment-booking/index.js#L658
     */
    appointmentId?: string
    /**
     * The clinic location of the appointment being booked.
     *
     *
     *
     * https://github.com/AskTia/tmd-server/blob/19bd959d827eb8cfdf9b825b84c6c5f639256a75/services/appointment-booking/index.js#L639
     */
    appointmentLocation?: string
    /**
     * The appointment profile
     *
     * https://github.com/AskTia/tmd-server/blob/19bd959d827eb8cfdf9b825b84c6c5f639256a75/services/appointment-booking/index.js#L577
     *
     * Its uuid is saved on the appointment further down in the function
     *
     * ```
     * ehr.appointment.appointment_profile_uuid
     * ```
     */
    appointmentProfile?: string
    appointmentSuggestionUuid?: string
    /**
     * The time of the appointment
     */
    appointmentTime?: string
    /**
     * The type of appointment.
     *
     * https://github.com/AskTia/tmd-server/blob/19bd959d827eb8cfdf9b825b84c6c5f639256a75/services/appointment-booking/index.js#L577
     *
     * This is accessible throught the
     *
     * ```
     * ehr.appointment_profile.appointment_type_uuid
     * ```
     */
    appointmentType?: string
    /**
     * Did the user or the admin make this change to the booking?
     */
    booker?: string
    /**
     * The name of the pathway taken to book the appointment.
     *
     * Examples:
     * Care Type Card, Quick Book
     */
    bookingPath?: string
    careType?: string
    /**
     * The unique identifier of the exam room chosen for the appointment.
     *
     * https://github.com/AskTia/tmd-server/blob/19bd959d827eb8cfdf9b825b84c6c5f639256a75/services/appointment-booking/index.js#L624
     */
    examRoomId?: string
    /**
     * Appointment has intake y/n
     *
     * This can be resolved using the goal the appointment is booked with and the ehr.goal_intake_map
     *
     * This data is not fetched for booking, and will need to be resolved separately.
     */
    intake?: boolean
    /**
     * Whether the appoinment virtual or not
     */
    isVirtual?: boolean
}

export interface AppointmentFilteredByCadenceProperties {
    /**
     * biweekly vs. weekly
     */
    AppointmentCadence: string
    /**
     * what appointment is selected
     */
    appointmentProfile: string
}

export interface AppointmentFilteredByCommunicationStyleProperties {
    /**
     * The appointment profile uuid
     */
    appointmentProfile: string
    /**
     * The type of appointment the user selected
     */
    appointmentType?: string
    /**
     * communication style
     */
    providerCommunication?: string
}

export interface AppointmentFilteredByDateProperties {
    /**
     * The appointment profile uuid
     */
    appointmentProfile: string
    /**
     * The type of appointment the user selected
     */
    appointmentType?: string
    /**
     * The date that the user selects
     */
    date?: string
}

export interface AppointmentFilteredByEthnicityProperties {
    /**
     * The appointment profile uuid
     */
    appointmentProfile: string
    /**
     * The type of appointment the user selected
     */
    appointmentType?: string
    /**
     * race/ethnicity that is selected
     */
    providerEthnicity?: string
}

export interface AppointmentFilteredByLocationProperties {
    /**
     * Location selected
     */
    appointmentLocation?: string
    /**
     * The appointment profile uuid
     */
    appointmentProfile: string
    /**
     * The type of appointment the user selected
     */
    appointmentType?: string
}

export interface AppointmentFilteredByProviderProperties {
    /**
     * The appointment profile
     *
     * https://github.com/AskTia/tmd-server/blob/19bd959d827eb8cfdf9b825b84c6c5f639256a75/services/appointment-booking/index.js#L577
     *
     * Its uuid is saved on the appointment further down in the function
     *
     * ```
     * ehr.appointment.appointment_profile_uuid
     * ```
     */
    appointmentProfile?: string
    /**
     * The type of appointment.
     *
     * https://github.com/AskTia/tmd-server/blob/19bd959d827eb8cfdf9b825b84c6c5f639256a75/services/appointment-booking/index.js#L577
     *
     * This is accessible throught the
     *
     * ```
     * ehr.appointment_profile.appointment_type_uuid
     * ```
     */
    appointmentType?: string
    /**
     * | Rule | Value |
     * |---|---|
     * | Item Type | string |
     */
    providersUuids: string[]
}

export interface AppointmentFilteredByProviderGenderProperties {
    /**
     * The appointment profile uuid
     */
    appointmentProfile: string
    /**
     * The type of appointment the user selected
     */
    appointmentType?: string
    /**
     * Gender of the provider selected
     */
    providerGender?: string
}

export interface AppointmentReviewViewedProperties {
    appointmentDate?: string
    /**
     * The day of the week the appointment is scheduled.
     *
     * https://github.com/AskTia/tmd-server/blob/19bd959d827eb8cfdf9b825b84c6c5f639256a75/services/appointment-booking/index.js#L638
     */
    appointmentDayOfWeek?: string
    /**
     * The stated goal of the appointment.
     *
     * https://github.com/AskTia/tmd-server/blob/19bd959d827eb8cfdf9b825b84c6c5f639256a75/services/appointment-booking/index.js#L638
     */
    appointmentGoal?: string
    /**
     * The unique identifier for the appointment.
     *
     *
     * https://github.com/AskTia/tmd-server/blob/19bd959d827eb8cfdf9b825b84c6c5f639256a75/services/appointment-booking/index.js#L658
     */
    appointmentId?: string
    /**
     * The clinic location of the appointment being booked.
     *
     *
     *
     * https://github.com/AskTia/tmd-server/blob/19bd959d827eb8cfdf9b825b84c6c5f639256a75/services/appointment-booking/index.js#L639
     */
    appointmentLocation?: string
    /**
     * The appointment profile
     *
     * https://github.com/AskTia/tmd-server/blob/19bd959d827eb8cfdf9b825b84c6c5f639256a75/services/appointment-booking/index.js#L577
     *
     * Its uuid is saved on the appointment further down in the function
     *
     * ```
     * ehr.appointment.appointment_profile_uuid
     * ```
     */
    appointmentProfile?: string
    appointmentSuggestionUuid?: string
    /**
     * The time of the appointment
     */
    appointmentTime?: string
    /**
     * The type of appointment.
     *
     * https://github.com/AskTia/tmd-server/blob/19bd959d827eb8cfdf9b825b84c6c5f639256a75/services/appointment-booking/index.js#L577
     *
     * This is accessible throught the
     *
     * ```
     * ehr.appointment_profile.appointment_type_uuid
     * ```
     */
    appointmentType?: string
    /**
     * Did the user or the admin make this change to the booking?
     */
    booker?: string
    /**
     * The name of the pathway taken to book the appointment.
     *
     * Examples:
     * Care Type Card, Quick Book
     */
    bookingPath?: string
    careType?: string
    /**
     * The unique identifier of the exam room chosen for the appointment.
     *
     * https://github.com/AskTia/tmd-server/blob/19bd959d827eb8cfdf9b825b84c6c5f639256a75/services/appointment-booking/index.js#L624
     */
    examRoomId?: string
    /**
     * Appointment has intake y/n
     *
     * This can be resolved using the goal the appointment is booked with and the ehr.goal_intake_map
     *
     * This data is not fetched for booking, and will need to be resolved separately.
     */
    intake?: boolean
    /**
     * Whether the appoinment virtual or not
     */
    isVirtual?: boolean
    /**
     * | Rule | Value |
     * |---|---|
     * | Type | number |
     */
    preselectedGoalId?: number
}

export interface AppointmentStartedProperties {
    /**
     * The date and time of the appointment
     */
    appointmentDateAndTime?: string
    /**
     * The stated goal of the appointment.
     * https://github.com/AskTia/tmd-server/blob/19bd959d827eb8cfdf9b825b84c6c5f639256a75/services/appointment-booking/index.js#L638
     */
    appointmentGoal?: string
    /**
     * The unique identifier for the appointment.
     * https://github.com/AskTia/tmd-server/blob/19bd959d827eb8cfdf9b825b84c6c5f639256a75/services/appointment-booking/index.js#L658
     */
    appointmentId?: string
    /**
     * The clinic location of the appointment being booked.
     * https://github.com/AskTia/tmd-server/blob/19bd959d827eb8cfdf9b825b84c6c5f639256a75/services/appointment-booking/index.js#L639
     */
    appointmentLocation?: string
    /**
     * The appointment profile
     * https://github.com/AskTia/tmd-server/blob/19bd959d827eb8cfdf9b825b84c6c5f639256a75/services/appointment-booking/index.js#L577
     * Its uuid is saved on the appointment further down in the function
     *
     * ```
     * ehr.appointment.appointment_profile_uuid
     * ```
     */
    appointmentProfile?: string
    /**
     * The type of care provided during the appointment.
     * https://github.com/AskTia/tmd-server/blob/19bd959d827eb8cfdf9b825b84c6c5f639256a75/services/appointment-booking/index.js#L651
     */
    careType?: string
}

export interface AppointmentSuggestionBannerClickedProperties {
    /**
     * Appointment that is being suggested to book
     */
    appointmentProfile: string
    /**
     * The appointment id for the suggested appointment
     */
    appointmentSuggestionUuid: string
}

export interface AppointmentSuggestionBookCtaProperties {
    appointmentProfile: string
    appointmentSuggestionUuid: string
    careType?: string
    path: string
    /**
     * whether the suggestion is from a provider/care coordinator or a tia reminder
     */
    sourceType?: string
}

export interface AppointmentSuggestionDismissCtaProperties {
    appointmentProfile: string
    appointmentSuggestionUuid: string
    careType?: string
    path: string
    sourceType?: string
}

export interface AppointmentUpdateConfirmedProperties {
    appointmentDate?: string
    /**
     * The day of the week the appointment is scheduled.
     *
     * https://github.com/AskTia/tmd-server/blob/19bd959d827eb8cfdf9b825b84c6c5f639256a75/services/appointment-booking/index.js#L638
     */
    appointmentDayOfWeek?: string
    /**
     * The stated goal of the appointment.
     *
     * https://github.com/AskTia/tmd-server/blob/19bd959d827eb8cfdf9b825b84c6c5f639256a75/services/appointment-booking/index.js#L638
     */
    appointmentGoal?: string
    /**
     * The unique identifier for the appointment.
     *
     *
     * https://github.com/AskTia/tmd-server/blob/19bd959d827eb8cfdf9b825b84c6c5f639256a75/services/appointment-booking/index.js#L658
     */
    appointmentId?: string
    /**
     * The clinic location of the appointment being booked.
     *
     *
     *
     * https://github.com/AskTia/tmd-server/blob/19bd959d827eb8cfdf9b825b84c6c5f639256a75/services/appointment-booking/index.js#L639
     */
    appointmentLocation?: string
    /**
     * The appointment profile
     *
     * https://github.com/AskTia/tmd-server/blob/19bd959d827eb8cfdf9b825b84c6c5f639256a75/services/appointment-booking/index.js#L577
     *
     * Its uuid is saved on the appointment further down in the function
     *
     * ```
     * ehr.appointment.appointment_profile_uuid
     * ```
     */
    appointmentProfile?: string
    appointmentSuggestionUuid?: string
    /**
     * The time of the appointment
     */
    appointmentTime?: string
    /**
     * The type of appointment.
     *
     * https://github.com/AskTia/tmd-server/blob/19bd959d827eb8cfdf9b825b84c6c5f639256a75/services/appointment-booking/index.js#L577
     *
     * This is accessible throught the
     *
     * ```
     * ehr.appointment_profile.appointment_type_uuid
     * ```
     */
    appointmentType?: string
    /**
     * Did the user or the admin make this change to the booking?
     */
    booker?: string
    /**
     * The name of the pathway taken to book the appointment.
     *
     * Examples:
     * Care Type Card, Quick Book
     */
    bookingPath?: string
    careType?: string
    /**
     * The unique identifier of the exam room chosen for the appointment.
     *
     * https://github.com/AskTia/tmd-server/blob/19bd959d827eb8cfdf9b825b84c6c5f639256a75/services/appointment-booking/index.js#L624
     */
    examRoomId?: string
    /**
     * Appointment has intake y/n
     *
     * This can be resolved using the goal the appointment is booked with and the ehr.goal_intake_map
     *
     * This data is not fetched for booking, and will need to be resolved separately.
     */
    intake?: boolean
    /**
     * Whether the appoinment virtual or not
     */
    isVirtual?: boolean
}

export interface BackButtonClickedProperties {
    /**
     * Name of page, same as the Page Viewed event.
     */
    pageName?: string
}

export interface BookingConfirmationCtaClickedProperties {
    appointmentDate?: string
    /**
     * The day of the week the appointment is scheduled.
     *
     * https://github.com/AskTia/tmd-server/blob/19bd959d827eb8cfdf9b825b84c6c5f639256a75/services/appointment-booking/index.js#L638
     */
    appointmentDayOfWeek?: string
    /**
     * The stated goal of the appointment.
     *
     * https://github.com/AskTia/tmd-server/blob/19bd959d827eb8cfdf9b825b84c6c5f639256a75/services/appointment-booking/index.js#L638
     */
    appointmentGoal?: string
    /**
     * The unique identifier for the appointment.
     *
     *
     * https://github.com/AskTia/tmd-server/blob/19bd959d827eb8cfdf9b825b84c6c5f639256a75/services/appointment-booking/index.js#L658
     */
    appointmentId?: string
    /**
     * The clinic location of the appointment being booked.
     *
     *
     *
     * https://github.com/AskTia/tmd-server/blob/19bd959d827eb8cfdf9b825b84c6c5f639256a75/services/appointment-booking/index.js#L639
     */
    appointmentLocation?: string
    /**
     * The appointment profile
     *
     * https://github.com/AskTia/tmd-server/blob/19bd959d827eb8cfdf9b825b84c6c5f639256a75/services/appointment-booking/index.js#L577
     *
     * Its uuid is saved on the appointment further down in the function
     *
     * ```
     * ehr.appointment.appointment_profile_uuid
     * ```
     */
    appointmentProfile?: string
    appointmentSuggestionUuid?: string
    /**
     * The time of the appointment
     */
    appointmentTime?: string
    /**
     * The type of appointment.
     *
     * https://github.com/AskTia/tmd-server/blob/19bd959d827eb8cfdf9b825b84c6c5f639256a75/services/appointment-booking/index.js#L577
     *
     * This is accessible throught the
     *
     * ```
     * ehr.appointment_profile.appointment_type_uuid
     * ```
     */
    appointmentType?: string
    /**
     * Did the user or the admin make this change to the booking?
     */
    booker?: string
    /**
     * The name of the pathway taken to book the appointment.
     *
     * Examples:
     * Care Type Card, Quick Book
     */
    bookingPath?: string
    careType?: string
    /**
     * The unique identifier of the exam room chosen for the appointment.
     *
     * https://github.com/AskTia/tmd-server/blob/19bd959d827eb8cfdf9b825b84c6c5f639256a75/services/appointment-booking/index.js#L624
     */
    examRoomId?: string
    /**
     * Appointment has intake y/n
     *
     * This can be resolved using the goal the appointment is booked with and the ehr.goal_intake_map
     *
     * This data is not fetched for booking, and will need to be resolved separately.
     */
    intake?: boolean
    /**
     * Whether the appoinment virtual or not
     */
    isVirtual?: boolean
}

export interface BookingGoalSelectionViewedProperties {
    /**
     * The appointment profile
     *
     * <https://github.com/AskTia/tmd-server/blob/19bd959d827eb8cfdf9b825b84c6c5f639256a75/services/appointment-booking/index.js#L577>
     *
     * Its uuid is saved on the appointment further down in the function
     *
     * ```
     * ehr.appointment.appointment_profile_uuid
     * ```
     */
    appointmentProfile: string
}

export interface CancelConfirmedProperties {
    appointmentDate?: string
    /**
     * The day of the week the appointment is scheduled.
     *
     * https://github.com/AskTia/tmd-server/blob/19bd959d827eb8cfdf9b825b84c6c5f639256a75/services/appointment-booking/index.js#L638
     */
    appointmentDayOfWeek?: string
    /**
     * The stated goal of the appointment.
     *
     * https://github.com/AskTia/tmd-server/blob/19bd959d827eb8cfdf9b825b84c6c5f639256a75/services/appointment-booking/index.js#L638
     */
    appointmentGoal?: string
    /**
     * The unique identifier for the appointment.
     *
     *
     * https://github.com/AskTia/tmd-server/blob/19bd959d827eb8cfdf9b825b84c6c5f639256a75/services/appointment-booking/index.js#L658
     */
    appointmentId?: string
    /**
     * The clinic location of the appointment being booked.
     *
     *
     *
     * https://github.com/AskTia/tmd-server/blob/19bd959d827eb8cfdf9b825b84c6c5f639256a75/services/appointment-booking/index.js#L639
     */
    appointmentLocation?: string
    /**
     * The appointment profile
     *
     * https://github.com/AskTia/tmd-server/blob/19bd959d827eb8cfdf9b825b84c6c5f639256a75/services/appointment-booking/index.js#L577
     *
     * Its uuid is saved on the appointment further down in the function
     *
     * ```
     * ehr.appointment.appointment_profile_uuid
     * ```
     */
    appointmentProfile?: string
    appointmentSuggestionUuid?: string
    /**
     * The time of the appointment
     */
    appointmentTime?: string
    /**
     * The type of appointment.
     *
     * https://github.com/AskTia/tmd-server/blob/19bd959d827eb8cfdf9b825b84c6c5f639256a75/services/appointment-booking/index.js#L577
     *
     * This is accessible throught the
     *
     * ```
     * ehr.appointment_profile.appointment_type_uuid
     * ```
     */
    appointmentType?: string
    /**
     * Did the user or the admin make this change to the booking?
     */
    booker?: string
    /**
     * The name of the pathway taken to book the appointment.
     *
     * Examples:
     * Care Type Card, Quick Book
     */
    bookingPath?: string
    /**
     * The cancellation reason the user selected
     */
    cancelReason?: string
    careType?: string
    /**
     * The unique identifier of the exam room chosen for the appointment.
     *
     * https://github.com/AskTia/tmd-server/blob/19bd959d827eb8cfdf9b825b84c6c5f639256a75/services/appointment-booking/index.js#L624
     */
    examRoomId?: string
    /**
     * Appointment has intake y/n
     *
     * This can be resolved using the goal the appointment is booked with and the ehr.goal_intake_map
     *
     * This data is not fetched for booking, and will need to be resolved separately.
     */
    intake?: boolean
    /**
     * Whether the appoinment virtual or not
     */
    isVirtual?: boolean
}

export interface CancelMembershipClickedProperties {
    userID: string
}

export interface CancelMembershipReasonsClickedProperties {
    /**
     * Captures the reason that the member wants to cancel
     *
     * \-
     *
     *
     *
     */
    cancel_membership_reason: string
    userID: string
}

export interface CancelMembershipReasonsViewedProperties {
    userID: string
}

export interface CancelOrChangeAppointmentClickedProperties {
    appointmentDate?: string
    /**
     * The day of the week the appointment is scheduled.
     *
     * https://github.com/AskTia/tmd-server/blob/19bd959d827eb8cfdf9b825b84c6c5f639256a75/services/appointment-booking/index.js#L638
     */
    appointmentDayOfWeek?: string
    /**
     * The stated goal of the appointment.
     *
     * https://github.com/AskTia/tmd-server/blob/19bd959d827eb8cfdf9b825b84c6c5f639256a75/services/appointment-booking/index.js#L638
     */
    appointmentGoal?: string
    /**
     * The unique identifier for the appointment.
     *
     *
     * https://github.com/AskTia/tmd-server/blob/19bd959d827eb8cfdf9b825b84c6c5f639256a75/services/appointment-booking/index.js#L658
     */
    appointmentId?: string
    /**
     * The clinic location of the appointment being booked.
     *
     *
     *
     * https://github.com/AskTia/tmd-server/blob/19bd959d827eb8cfdf9b825b84c6c5f639256a75/services/appointment-booking/index.js#L639
     */
    appointmentLocation?: string
    /**
     * The appointment profile
     *
     * https://github.com/AskTia/tmd-server/blob/19bd959d827eb8cfdf9b825b84c6c5f639256a75/services/appointment-booking/index.js#L577
     *
     * Its uuid is saved on the appointment further down in the function
     *
     * ```
     * ehr.appointment.appointment_profile_uuid
     * ```
     */
    appointmentProfile?: string
    appointmentSuggestionUuid?: string
    /**
     * The time of the appointment
     */
    appointmentTime?: string
    /**
     * The type of appointment.
     *
     * https://github.com/AskTia/tmd-server/blob/19bd959d827eb8cfdf9b825b84c6c5f639256a75/services/appointment-booking/index.js#L577
     *
     * This is accessible throught the
     *
     * ```
     * ehr.appointment_profile.appointment_type_uuid
     * ```
     */
    appointmentType?: string
    /**
     * Did the user or the admin make this change to the booking?
     */
    booker?: string
    /**
     * The name of the pathway taken to book the appointment.
     *
     * Examples:
     * Care Type Card, Quick Book
     */
    bookingPath?: string
    careType?: string
    /**
     * The unique identifier of the exam room chosen for the appointment.
     *
     * https://github.com/AskTia/tmd-server/blob/19bd959d827eb8cfdf9b825b84c6c5f639256a75/services/appointment-booking/index.js#L624
     */
    examRoomId?: string
    /**
     * Appointment has intake y/n
     *
     * This can be resolved using the goal the appointment is booked with and the ehr.goal_intake_map
     *
     * This data is not fetched for booking, and will need to be resolved separately.
     */
    intake?: boolean
    /**
     * Whether the appoinment virtual or not
     */
    isVirtual?: boolean
}

export interface CancelOrChangeAppointmentResponseClickedProperties {
    appointmentDate?: string
    /**
     * The day of the week the appointment is scheduled.
     *
     * https://github.com/AskTia/tmd-server/blob/19bd959d827eb8cfdf9b825b84c6c5f639256a75/services/appointment-booking/index.js#L638
     */
    appointmentDayOfWeek?: string
    /**
     * The stated goal of the appointment.
     *
     * https://github.com/AskTia/tmd-server/blob/19bd959d827eb8cfdf9b825b84c6c5f639256a75/services/appointment-booking/index.js#L638
     */
    appointmentGoal?: string
    /**
     * The unique identifier for the appointment.
     *
     *
     * https://github.com/AskTia/tmd-server/blob/19bd959d827eb8cfdf9b825b84c6c5f639256a75/services/appointment-booking/index.js#L658
     */
    appointmentId?: string
    /**
     * The clinic location of the appointment being booked.
     *
     *
     *
     * https://github.com/AskTia/tmd-server/blob/19bd959d827eb8cfdf9b825b84c6c5f639256a75/services/appointment-booking/index.js#L639
     */
    appointmentLocation?: string
    /**
     * The appointment profile
     *
     * https://github.com/AskTia/tmd-server/blob/19bd959d827eb8cfdf9b825b84c6c5f639256a75/services/appointment-booking/index.js#L577
     *
     * Its uuid is saved on the appointment further down in the function
     *
     * ```
     * ehr.appointment.appointment_profile_uuid
     * ```
     */
    appointmentProfile?: string
    appointmentSuggestionUuid?: string
    /**
     * The time of the appointment
     */
    appointmentTime?: string
    /**
     * The type of appointment.
     *
     * https://github.com/AskTia/tmd-server/blob/19bd959d827eb8cfdf9b825b84c6c5f639256a75/services/appointment-booking/index.js#L577
     *
     * This is accessible throught the
     *
     * ```
     * ehr.appointment_profile.appointment_type_uuid
     * ```
     */
    appointmentType?: string
    /**
     * Did the user or the admin make this change to the booking?
     */
    booker?: string
    /**
     * The name of the pathway taken to book the appointment.
     *
     * Examples:
     * Care Type Card, Quick Book
     */
    bookingPath?: string
    careType?: string
    /**
     * The unique identifier of the exam room chosen for the appointment.
     *
     * https://github.com/AskTia/tmd-server/blob/19bd959d827eb8cfdf9b825b84c6c5f639256a75/services/appointment-booking/index.js#L624
     */
    examRoomId?: string
    /**
     * Appointment has intake y/n
     *
     * This can be resolved using the goal the appointment is booked with and the ehr.goal_intake_map
     *
     * This data is not fetched for booking, and will need to be resolved separately.
     */
    intake?: boolean
    /**
     * Whether the appoinment virtual or not
     */
    isVirtual?: boolean
    /**
     * The option that was selected on the frontend to the response to cancel.
     *
     * Examples:
     * No, Reschedule, Confirm Cancel
     */
    optionSelected?: string
}

export interface CancelOrChangeMembershipViewedProperties {
    /**
     * member ID
     *
     * | Rule | Value |
     * |---|---|
     * | Type | number |
     */
    userID: number
}

export interface CancelReasonSelectedProperties {
    appointmentDate?: string
    /**
     * The day of the week the appointment is scheduled.
     *
     * https://github.com/AskTia/tmd-server/blob/19bd959d827eb8cfdf9b825b84c6c5f639256a75/services/appointment-booking/index.js#L638
     */
    appointmentDayOfWeek?: string
    /**
     * The stated goal of the appointment.
     *
     * https://github.com/AskTia/tmd-server/blob/19bd959d827eb8cfdf9b825b84c6c5f639256a75/services/appointment-booking/index.js#L638
     */
    appointmentGoal?: string
    /**
     * The unique identifier for the appointment.
     *
     *
     * https://github.com/AskTia/tmd-server/blob/19bd959d827eb8cfdf9b825b84c6c5f639256a75/services/appointment-booking/index.js#L658
     */
    appointmentId?: string
    /**
     * The clinic location of the appointment being booked.
     *
     *
     *
     * https://github.com/AskTia/tmd-server/blob/19bd959d827eb8cfdf9b825b84c6c5f639256a75/services/appointment-booking/index.js#L639
     */
    appointmentLocation?: string
    /**
     * The appointment profile
     *
     * https://github.com/AskTia/tmd-server/blob/19bd959d827eb8cfdf9b825b84c6c5f639256a75/services/appointment-booking/index.js#L577
     *
     * Its uuid is saved on the appointment further down in the function
     *
     * ```
     * ehr.appointment.appointment_profile_uuid
     * ```
     */
    appointmentProfile?: string
    appointmentSuggestionUuid?: string
    /**
     * The time of the appointment
     */
    appointmentTime?: string
    /**
     * The type of appointment.
     *
     * https://github.com/AskTia/tmd-server/blob/19bd959d827eb8cfdf9b825b84c6c5f639256a75/services/appointment-booking/index.js#L577
     *
     * This is accessible throught the
     *
     * ```
     * ehr.appointment_profile.appointment_type_uuid
     * ```
     */
    appointmentType?: string
    /**
     * Did the user or the admin make this change to the booking?
     */
    booker?: string
    /**
     * The name of the pathway taken to book the appointment.
     *
     * Examples:
     * Care Type Card, Quick Book
     */
    bookingPath?: string
    /**
     * The cancellation reason the user selected
     */
    cancelReason?: string
    careType?: string
    /**
     * The unique identifier of the exam room chosen for the appointment.
     *
     * https://github.com/AskTia/tmd-server/blob/19bd959d827eb8cfdf9b825b84c6c5f639256a75/services/appointment-booking/index.js#L624
     */
    examRoomId?: string
    /**
     * Appointment has intake y/n
     *
     * This can be resolved using the goal the appointment is booked with and the ehr.goal_intake_map
     *
     * This data is not fetched for booking, and will need to be resolved separately.
     */
    intake?: boolean
    /**
     * Whether the appoinment virtual or not
     */
    isVirtual?: boolean
}

export interface CarePlanDetailsViewedProperties {
    /**
     * name of intervention that member has tapped in to see
     */
    intervention: string
    userID: string
}

export interface CareTypeBrowsedProperties {
    /**
     * Name of the care type, such as Primary Care, Mental Health, Gynecology, Acupuncture
     */
    careType?: string
}

export interface CashPayConfirmedProperties {
    /**
     * The unique identifier for the appointment.
     *
     *
     * https://github.com/AskTia/tmd-server/blob/19bd959d827eb8cfdf9b825b84c6c5f639256a75/services/appointment-booking/index.js#L658
     */
    appointmentId?: string
}

export interface ChangeMembershipClickedProperties {
    userID: string
}

export interface CheckInCardOnFileCompletedProperties {
    /**
     * The date and time of the appointment
     */
    appointmentDateAndTime?: string
    /**
     * The stated goal of the appointment.
     * https://github.com/AskTia/tmd-server/blob/19bd959d827eb8cfdf9b825b84c6c5f639256a75/services/appointment-booking/index.js#L638
     */
    appointmentGoal?: string
    /**
     * The unique identifier for the appointment.
     * https://github.com/AskTia/tmd-server/blob/19bd959d827eb8cfdf9b825b84c6c5f639256a75/services/appointment-booking/index.js#L658
     */
    appointmentId?: string
    /**
     * The clinic location of the appointment being booked.
     * https://github.com/AskTia/tmd-server/blob/19bd959d827eb8cfdf9b825b84c6c5f639256a75/services/appointment-booking/index.js#L639
     */
    appointmentLocation?: string
    /**
     * The appointment profile
     * https://github.com/AskTia/tmd-server/blob/19bd959d827eb8cfdf9b825b84c6c5f639256a75/services/appointment-booking/index.js#L577
     * Its uuid is saved on the appointment further down in the function
     *
     * ```
     * ehr.appointment.appointment_profile_uuid
     * ```
     */
    appointmentProfile?: string
    /**
     * Whether the user has a card on file
     */
    cardOnFile?: boolean
    /**
     * The type of care provided during the appointment.
     * https://github.com/AskTia/tmd-server/blob/19bd959d827eb8cfdf9b825b84c6c5f639256a75/services/appointment-booking/index.js#L651
     */
    careType?: string
}

export interface CheckInCompletedProperties {
    /**
     * The date and time of the appointment
     */
    appointmentDateAndTime?: string
    /**
     * The stated goal of the appointment.
     * https://github.com/AskTia/tmd-server/blob/19bd959d827eb8cfdf9b825b84c6c5f639256a75/services/appointment-booking/index.js#L638
     */
    appointmentGoal?: string
    /**
     * The unique identifier for the appointment.
     * https://github.com/AskTia/tmd-server/blob/19bd959d827eb8cfdf9b825b84c6c5f639256a75/services/appointment-booking/index.js#L658
     */
    appointmentId?: string
    /**
     * The clinic location of the appointment being booked.
     * https://github.com/AskTia/tmd-server/blob/19bd959d827eb8cfdf9b825b84c6c5f639256a75/services/appointment-booking/index.js#L639
     */
    appointmentLocation?: string
    /**
     * The appointment profile
     * https://github.com/AskTia/tmd-server/blob/19bd959d827eb8cfdf9b825b84c6c5f639256a75/services/appointment-booking/index.js#L577
     * Its uuid is saved on the appointment further down in the function
     *
     * ```
     * ehr.appointment.appointment_profile_uuid
     * ```
     */
    appointmentProfile?: string
    /**
     * The type of care provided during the appointment.
     * https://github.com/AskTia/tmd-server/blob/19bd959d827eb8cfdf9b825b84c6c5f639256a75/services/appointment-booking/index.js#L651
     */
    careType?: string
}

export interface CheckInConsentCompletedProperties {
    /**
     * The date and time of the appointment
     */
    appointmentDateAndTime?: string
    /**
     * The stated goal of the appointment.
     * https://github.com/AskTia/tmd-server/blob/19bd959d827eb8cfdf9b825b84c6c5f639256a75/services/appointment-booking/index.js#L638
     */
    appointmentGoal?: string
    /**
     * The unique identifier for the appointment.
     * https://github.com/AskTia/tmd-server/blob/19bd959d827eb8cfdf9b825b84c6c5f639256a75/services/appointment-booking/index.js#L658
     */
    appointmentId?: string
    /**
     * The clinic location of the appointment being booked.
     * https://github.com/AskTia/tmd-server/blob/19bd959d827eb8cfdf9b825b84c6c5f639256a75/services/appointment-booking/index.js#L639
     */
    appointmentLocation?: string
    /**
     * The appointment profile
     * https://github.com/AskTia/tmd-server/blob/19bd959d827eb8cfdf9b825b84c6c5f639256a75/services/appointment-booking/index.js#L577
     * Its uuid is saved on the appointment further down in the function
     *
     * ```
     * ehr.appointment.appointment_profile_uuid
     * ```
     */
    appointmentProfile?: string
    /**
     * The type of care provided during the appointment.
     * https://github.com/AskTia/tmd-server/blob/19bd959d827eb8cfdf9b825b84c6c5f639256a75/services/appointment-booking/index.js#L651
     */
    careType?: string
}

export interface CheckInInsuranceCompletedProperties {
    /**
     * The date and time of the appointment
     */
    appointmentDateAndTime?: string
    /**
     * The stated goal of the appointment.
     * https://github.com/AskTia/tmd-server/blob/19bd959d827eb8cfdf9b825b84c6c5f639256a75/services/appointment-booking/index.js#L638
     */
    appointmentGoal?: string
    /**
     * The unique identifier for the appointment.
     * https://github.com/AskTia/tmd-server/blob/19bd959d827eb8cfdf9b825b84c6c5f639256a75/services/appointment-booking/index.js#L658
     */
    appointmentId?: string
    /**
     * The clinic location of the appointment being booked.
     * https://github.com/AskTia/tmd-server/blob/19bd959d827eb8cfdf9b825b84c6c5f639256a75/services/appointment-booking/index.js#L639
     */
    appointmentLocation?: string
    /**
     * The appointment profile
     * https://github.com/AskTia/tmd-server/blob/19bd959d827eb8cfdf9b825b84c6c5f639256a75/services/appointment-booking/index.js#L577
     * Its uuid is saved on the appointment further down in the function
     *
     * ```
     * ehr.appointment.appointment_profile_uuid
     * ```
     */
    appointmentProfile?: string
    /**
     * The type of care provided during the appointment.
     * https://github.com/AskTia/tmd-server/blob/19bd959d827eb8cfdf9b825b84c6c5f639256a75/services/appointment-booking/index.js#L651
     */
    careType?: string
    /**
     * When the the user clicks on check my coverage after selecting existing insurance on file or adding new insurance details.
     */
    coverageResult?: string
    /**
     * If a user requests an HSA receipt
     */
    hsaReceipt?: boolean
    /**
     * If the user has selected to use insurance or not
     */
    insuranceSelected?: boolean
    /**
     * When the user selects to use the insurance on file
     */
    usingInsuranceOnFile?: boolean
}

export interface CheckInNotStartedProperties {
    /**
     * The date and time of the appointment
     */
    appointmentDateAndTime?: string
    /**
     * The stated goal of the appointment.
     * https://github.com/AskTia/tmd-server/blob/19bd959d827eb8cfdf9b825b84c6c5f639256a75/services/appointment-booking/index.js#L638
     */
    appointmentGoal?: string
    /**
     * The unique identifier for the appointment.
     * https://github.com/AskTia/tmd-server/blob/19bd959d827eb8cfdf9b825b84c6c5f639256a75/services/appointment-booking/index.js#L658
     */
    appointmentId?: string
    /**
     * The clinic location of the appointment being booked.
     * https://github.com/AskTia/tmd-server/blob/19bd959d827eb8cfdf9b825b84c6c5f639256a75/services/appointment-booking/index.js#L639
     */
    appointmentLocation?: string
    /**
     * The appointment profile
     * https://github.com/AskTia/tmd-server/blob/19bd959d827eb8cfdf9b825b84c6c5f639256a75/services/appointment-booking/index.js#L577
     * Its uuid is saved on the appointment further down in the function
     *
     * ```
     * ehr.appointment.appointment_profile_uuid
     * ```
     */
    appointmentProfile?: string
    /**
     * The type of care provided during the appointment.
     * https://github.com/AskTia/tmd-server/blob/19bd959d827eb8cfdf9b825b84c6c5f639256a75/services/appointment-booking/index.js#L651
     */
    careType?: string
}

export interface CheckInNowClickedProperties {
    /**
     * The date and time of the appointment
     */
    appointmentDateAndTime?: string
    /**
     * The stated goal of the appointment.
     * https://github.com/AskTia/tmd-server/blob/19bd959d827eb8cfdf9b825b84c6c5f639256a75/services/appointment-booking/index.js#L638
     */
    appointmentGoal?: string
    /**
     * The unique identifier for the appointment.
     * https://github.com/AskTia/tmd-server/blob/19bd959d827eb8cfdf9b825b84c6c5f639256a75/services/appointment-booking/index.js#L658
     */
    appointmentId?: string
    /**
     * The clinic location of the appointment being booked.
     * https://github.com/AskTia/tmd-server/blob/19bd959d827eb8cfdf9b825b84c6c5f639256a75/services/appointment-booking/index.js#L639
     */
    appointmentLocation?: string
    /**
     * The appointment profile
     * https://github.com/AskTia/tmd-server/blob/19bd959d827eb8cfdf9b825b84c6c5f639256a75/services/appointment-booking/index.js#L577
     * Its uuid is saved on the appointment further down in the function
     *
     * ```
     * ehr.appointment.appointment_profile_uuid
     * ```
     */
    appointmentProfile?: string
    /**
     * The type of care provided during the appointment.
     * https://github.com/AskTia/tmd-server/blob/19bd959d827eb8cfdf9b825b84c6c5f639256a75/services/appointment-booking/index.js#L651
     */
    careType?: string
}

export interface CheckInPart1CompletedProperties {
    /**
     * The date and time of the appointment
     */
    appointmentDateAndTime?: string
    /**
     * The stated goal of the appointment.
     * https://github.com/AskTia/tmd-server/blob/19bd959d827eb8cfdf9b825b84c6c5f639256a75/services/appointment-booking/index.js#L638
     */
    appointmentGoal?: string
    /**
     * The unique identifier for the appointment.
     * https://github.com/AskTia/tmd-server/blob/19bd959d827eb8cfdf9b825b84c6c5f639256a75/services/appointment-booking/index.js#L658
     */
    appointmentId?: string
    /**
     * The clinic location of the appointment being booked.
     * https://github.com/AskTia/tmd-server/blob/19bd959d827eb8cfdf9b825b84c6c5f639256a75/services/appointment-booking/index.js#L639
     */
    appointmentLocation?: string
    /**
     * The appointment profile
     * https://github.com/AskTia/tmd-server/blob/19bd959d827eb8cfdf9b825b84c6c5f639256a75/services/appointment-booking/index.js#L577
     * Its uuid is saved on the appointment further down in the function
     *
     * ```
     * ehr.appointment.appointment_profile_uuid
     * ```
     */
    appointmentProfile?: string
    /**
     * The type of care provided during the appointment.
     * https://github.com/AskTia/tmd-server/blob/19bd959d827eb8cfdf9b825b84c6c5f639256a75/services/appointment-booking/index.js#L651
     */
    careType?: string
}

export interface CheckInPart2CompletedProperties {
    /**
     * The date and time of the appointment
     */
    appointmentDateAndTime?: string
    /**
     * The stated goal of the appointment.
     * https://github.com/AskTia/tmd-server/blob/19bd959d827eb8cfdf9b825b84c6c5f639256a75/services/appointment-booking/index.js#L638
     */
    appointmentGoal?: string
    /**
     * The unique identifier for the appointment.
     * https://github.com/AskTia/tmd-server/blob/19bd959d827eb8cfdf9b825b84c6c5f639256a75/services/appointment-booking/index.js#L658
     */
    appointmentId?: string
    /**
     * The clinic location of the appointment being booked.
     * https://github.com/AskTia/tmd-server/blob/19bd959d827eb8cfdf9b825b84c6c5f639256a75/services/appointment-booking/index.js#L639
     */
    appointmentLocation?: string
    /**
     * The appointment profile
     * https://github.com/AskTia/tmd-server/blob/19bd959d827eb8cfdf9b825b84c6c5f639256a75/services/appointment-booking/index.js#L577
     * Its uuid is saved on the appointment further down in the function
     *
     * ```
     * ehr.appointment.appointment_profile_uuid
     * ```
     */
    appointmentProfile?: string
    /**
     * The type of care provided during the appointment.
     * https://github.com/AskTia/tmd-server/blob/19bd959d827eb8cfdf9b825b84c6c5f639256a75/services/appointment-booking/index.js#L651
     */
    careType?: string
}

export interface CheckInPatientInfoCompletedProperties {
    /**
     * The date and time of the appointment
     */
    appointmentDateAndTime?: string
    /**
     * The stated goal of the appointment.
     * https://github.com/AskTia/tmd-server/blob/19bd959d827eb8cfdf9b825b84c6c5f639256a75/services/appointment-booking/index.js#L638
     */
    appointmentGoal?: string
    /**
     * The unique identifier for the appointment.
     * https://github.com/AskTia/tmd-server/blob/19bd959d827eb8cfdf9b825b84c6c5f639256a75/services/appointment-booking/index.js#L658
     */
    appointmentId?: string
    /**
     * The clinic location of the appointment being booked.
     * https://github.com/AskTia/tmd-server/blob/19bd959d827eb8cfdf9b825b84c6c5f639256a75/services/appointment-booking/index.js#L639
     */
    appointmentLocation?: string
    /**
     * The appointment profile
     * https://github.com/AskTia/tmd-server/blob/19bd959d827eb8cfdf9b825b84c6c5f639256a75/services/appointment-booking/index.js#L577
     * Its uuid is saved on the appointment further down in the function
     *
     * ```
     * ehr.appointment.appointment_profile_uuid
     * ```
     */
    appointmentProfile?: string
    /**
     * The type of care provided during the appointment.
     * https://github.com/AskTia/tmd-server/blob/19bd959d827eb8cfdf9b825b84c6c5f639256a75/services/appointment-booking/index.js#L651
     */
    careType?: string
}

export interface CheckInPreAppointmentInfoCompletedProperties {
    /**
     * The date and time of the appointment
     */
    appointmentDateAndTime?: string
    /**
     * The stated goal of the appointment.
     * https://github.com/AskTia/tmd-server/blob/19bd959d827eb8cfdf9b825b84c6c5f639256a75/services/appointment-booking/index.js#L638
     */
    appointmentGoal?: string
    /**
     * The unique identifier for the appointment.
     * https://github.com/AskTia/tmd-server/blob/19bd959d827eb8cfdf9b825b84c6c5f639256a75/services/appointment-booking/index.js#L658
     */
    appointmentId?: string
    /**
     * The clinic location of the appointment being booked.
     * https://github.com/AskTia/tmd-server/blob/19bd959d827eb8cfdf9b825b84c6c5f639256a75/services/appointment-booking/index.js#L639
     */
    appointmentLocation?: string
    /**
     * The appointment profile
     * https://github.com/AskTia/tmd-server/blob/19bd959d827eb8cfdf9b825b84c6c5f639256a75/services/appointment-booking/index.js#L577
     * Its uuid is saved on the appointment further down in the function
     *
     * ```
     * ehr.appointment.appointment_profile_uuid
     * ```
     */
    appointmentProfile?: string
    /**
     * The type of care provided during the appointment.
     * https://github.com/AskTia/tmd-server/blob/19bd959d827eb8cfdf9b825b84c6c5f639256a75/services/appointment-booking/index.js#L651
     */
    careType?: string
}

export interface CheckInUhrCompletedProperties {
    /**
     * The date and time of the appointment
     */
    appointmentDateAndTime?: string
    /**
     * The stated goal of the appointment.
     * https://github.com/AskTia/tmd-server/blob/19bd959d827eb8cfdf9b825b84c6c5f639256a75/services/appointment-booking/index.js#L638
     */
    appointmentGoal?: string
    /**
     * The unique identifier for the appointment.
     * https://github.com/AskTia/tmd-server/blob/19bd959d827eb8cfdf9b825b84c6c5f639256a75/services/appointment-booking/index.js#L658
     */
    appointmentId?: string
    /**
     * The clinic location of the appointment being booked.
     * https://github.com/AskTia/tmd-server/blob/19bd959d827eb8cfdf9b825b84c6c5f639256a75/services/appointment-booking/index.js#L639
     */
    appointmentLocation?: string
    /**
     * The appointment profile
     * https://github.com/AskTia/tmd-server/blob/19bd959d827eb8cfdf9b825b84c6c5f639256a75/services/appointment-booking/index.js#L577
     * Its uuid is saved on the appointment further down in the function
     *
     * ```
     * ehr.appointment.appointment_profile_uuid
     * ```
     */
    appointmentProfile?: string
    /**
     * The type of care provided during the appointment.
     * https://github.com/AskTia/tmd-server/blob/19bd959d827eb8cfdf9b825b84c6c5f639256a75/services/appointment-booking/index.js#L651
     */
    careType?: string
    /**
     * Appointment has intake y/n
     * This can be resolved using the goal the appointment is booked with and the ehr.goal_intake_map
     * This data is not fetched for booking, and will need to be resolved separately.
     */
    intake?: boolean
}

export interface ClickLearnMoreOnCarePlanItemInDashboardProperties {
    careplanInterventionUuid?: string
}

export interface ClickQuestionAboutCarePlanProperties {
    userID: string
}

export interface ClickServiceLineModuleOnAppointmentsPageProperties {
    serviceLine: string
}

export interface ClickedOnOtherImmunizationProperties {
    Checked: string
}

export interface ClickedOnRecommendedImmunizationProperties {
    Checked: boolean
}

export interface EmailCapturedProperties {
    /**
     * Set the email as the user identifier, we will call an alias call when they verify on the website later and merge with the email.
     */
    email?: string
    /**
     * Where the email was captured, email newsletter, stress quiz, etc.
     *
     * Examples:
     * Profile, Sign Up
     */
    location?: string
}

export interface EmailLoginSubmittedProperties {
    /**
     * Pull in the current year for the message being sent
     */
    currentYear?: string
    /**
     * The unique URL for the user to sign in through
     */
    signInLink?: string
}

export interface ErrorReceivedProperties {
    /**
     * The type of error message.
     */
    errorMessage?: string
    /**
     * Name of the page the user is on
     *
     * Examples:
     * "Join, Homepage, etc."
     *
     */
    page?: string
}

export interface EventRsvpProperties {
    /**
     * The date and time of the event
     */
    eventDateAndTime?: string
    /**
     * The event URL that is passed. ex. eventbrite.com...
     */
    eventUrl?: string
}

export interface ExitNewMemberOnboardingProperties {
    exitPage: string
}

export interface FilterAppointmentsByKeywordProperties {
    filterKeyword: string
    filterType: string
}

export interface FilterAppointmentsByLocationProperties {
    /**
     * | Rule | Value |
     * |---|---|
     * | Unique Items | true |
     * | Item Type | string |
     */
    locations: string[]
}

export interface FilterAppointmentsByServiceLineProperties {
    /**
     * | Rule | Value |
     * |---|---|
     * | Item Type | string |
     */
    serviceLines: string[]
}

export interface InboxViewedProperties {
    /**
     * Number of closed conversations this user has.
     *
     * | Rule | Value |
     * |---|---|
     * | Type | number |
     */
    closedConversations?: number
    /**
     * Number of current conversations that are open for the user.
     *
     * | Rule | Value |
     * |---|---|
     * | Type | number |
     */
    currentConversations?: number
    /**
     * Number of total conversations, regardless of status.
     *
     * | Rule | Value |
     * |---|---|
     * | Type | number |
     */
    totalConversations?: number
    /**
     * Number of unread messages that the user has.
     *
     * | Rule | Value |
     * |---|---|
     * | Type | number |
     */
    unreadMessages?: number
}

export interface InsuranceImageSentProperties {
    /**
     * The location of where they uploaded this.
     *
     * Examples:
     * Profile, Sign Up
     */
    location?: string
}

export interface InsuranceSelectedProperties {
    /**
     * User selects insurance company.
     *
     *
     * Edit: This has been moved to a backend event in light of the discussion below.
     *
     *
     * All insurance changes are [captured here](https://github.com/AskTia/tmd-server/blob/53f80b7f8f3c4a23d39ee281892ff356e9544e0d/services/insurance-coverage/index.js#L383)
     */
    insuranceCompany?: string
}

export interface MembershipPlanSelectedProperties {
    /**
     * User selects membership type - do not set user trait or super property.
     */
    pricingModel?: string
    /**
     * Promo code user entered
     *
     * | Rule | Value |
     * |---|---|
     * | Type | number |
     */
    promo?: number
}

export interface NavBookAppointmentButtonClickedProperties {
    path: string
}

export interface NpsModalViewedProperties {
    appointmentDate?: string
    /**
     * The day of the week the appointment is scheduled.
     *
     * https://github.com/AskTia/tmd-server/blob/19bd959d827eb8cfdf9b825b84c6c5f639256a75/services/appointment-booking/index.js#L638
     */
    appointmentDayOfWeek?: string
    /**
     * The stated goal of the appointment.
     *
     * https://github.com/AskTia/tmd-server/blob/19bd959d827eb8cfdf9b825b84c6c5f639256a75/services/appointment-booking/index.js#L638
     */
    appointmentGoal?: string
    /**
     * The unique identifier for the appointment.
     *
     *
     * https://github.com/AskTia/tmd-server/blob/19bd959d827eb8cfdf9b825b84c6c5f639256a75/services/appointment-booking/index.js#L658
     */
    appointmentId?: string
    /**
     * The clinic location of the appointment being booked.
     *
     *
     *
     * https://github.com/AskTia/tmd-server/blob/19bd959d827eb8cfdf9b825b84c6c5f639256a75/services/appointment-booking/index.js#L639
     */
    appointmentLocation?: string
    /**
     * The appointment profile
     *
     * https://github.com/AskTia/tmd-server/blob/19bd959d827eb8cfdf9b825b84c6c5f639256a75/services/appointment-booking/index.js#L577
     *
     * Its uuid is saved on the appointment further down in the function
     *
     * ```
     * ehr.appointment.appointment_profile_uuid
     * ```
     */
    appointmentProfile?: string
    appointmentSuggestionUuid?: string
    /**
     * The time of the appointment
     */
    appointmentTime?: string
    /**
     * The type of appointment.
     *
     * https://github.com/AskTia/tmd-server/blob/19bd959d827eb8cfdf9b825b84c6c5f639256a75/services/appointment-booking/index.js#L577
     *
     * This is accessible throught the
     *
     * ```
     * ehr.appointment_profile.appointment_type_uuid
     * ```
     */
    appointmentType?: string
    /**
     * Did the user or the admin make this change to the booking?
     */
    booker?: string
    /**
     * The name of the pathway taken to book the appointment.
     *
     * Examples:
     * Care Type Card, Quick Book
     */
    bookingPath?: string
    careType?: string
    /**
     * The unique identifier of the exam room chosen for the appointment.
     *
     * https://github.com/AskTia/tmd-server/blob/19bd959d827eb8cfdf9b825b84c6c5f639256a75/services/appointment-booking/index.js#L624
     */
    examRoomId?: string
    /**
     * Appointment has intake y/n
     *
     * This can be resolved using the goal the appointment is booked with and the ehr.goal_intake_map
     *
     * This data is not fetched for booking, and will need to be resolved separately.
     */
    intake?: boolean
    /**
     * Whether the appoinment virtual or not
     */
    isVirtual?: boolean
    /**
     * The page the user is on when they view the NPS modal
     *
     * Examples:
     * Profile, Sign Up
     */
    location?: string
    /**
     * Name of product interacting with.
     *
     * Examples:
     * Chat, Booking, ACI
     */
    product?: string
}

export interface NpsSubmittedProperties {
    appointmentDate?: string
    /**
     * The day of the week the appointment is scheduled.
     *
     * https://github.com/AskTia/tmd-server/blob/19bd959d827eb8cfdf9b825b84c6c5f639256a75/services/appointment-booking/index.js#L638
     */
    appointmentDayOfWeek?: string
    /**
     * The stated goal of the appointment.
     *
     * https://github.com/AskTia/tmd-server/blob/19bd959d827eb8cfdf9b825b84c6c5f639256a75/services/appointment-booking/index.js#L638
     */
    appointmentGoal?: string
    /**
     * The unique identifier for the appointment.
     *
     *
     * https://github.com/AskTia/tmd-server/blob/19bd959d827eb8cfdf9b825b84c6c5f639256a75/services/appointment-booking/index.js#L658
     */
    appointmentId?: string
    /**
     * The clinic location of the appointment being booked.
     *
     *
     *
     * https://github.com/AskTia/tmd-server/blob/19bd959d827eb8cfdf9b825b84c6c5f639256a75/services/appointment-booking/index.js#L639
     */
    appointmentLocation?: string
    /**
     * The appointment profile
     *
     * https://github.com/AskTia/tmd-server/blob/19bd959d827eb8cfdf9b825b84c6c5f639256a75/services/appointment-booking/index.js#L577
     *
     * Its uuid is saved on the appointment further down in the function
     *
     * ```
     * ehr.appointment.appointment_profile_uuid
     * ```
     */
    appointmentProfile?: string
    appointmentSuggestionUuid?: string
    /**
     * The time of the appointment
     */
    appointmentTime?: string
    /**
     * The type of appointment.
     *
     * https://github.com/AskTia/tmd-server/blob/19bd959d827eb8cfdf9b825b84c6c5f639256a75/services/appointment-booking/index.js#L577
     *
     * This is accessible throught the
     *
     * ```
     * ehr.appointment_profile.appointment_type_uuid
     * ```
     */
    appointmentType?: string
    /**
     * Did the user or the admin make this change to the booking?
     */
    booker?: string
    /**
     * The name of the pathway taken to book the appointment.
     *
     * Examples:
     * Care Type Card, Quick Book
     */
    bookingPath?: string
    careType?: string
    /**
     * The unique identifier of the exam room chosen for the appointment.
     *
     * https://github.com/AskTia/tmd-server/blob/19bd959d827eb8cfdf9b825b84c6c5f639256a75/services/appointment-booking/index.js#L624
     */
    examRoomId?: string
    /**
     * Appointment has intake y/n
     *
     * This can be resolved using the goal the appointment is booked with and the ehr.goal_intake_map
     *
     * This data is not fetched for booking, and will need to be resolved separately.
     */
    intake?: boolean
    /**
     * Whether the appoinment virtual or not
     */
    isVirtual?: boolean
    /**
     * The page the user is on when submitting the NPS response
     *
     * Examples:
     * Profile, Sign Up
     */
    location?: string
    /**
     * The name of the product the user is interacting with.
     *
     * Examples:
     * Chat, Booking, aci
     */
    product?: string
    /**
     * The score that was submitted for the NPS survey.
     */
    score?: string
}

export interface OnDemandBookCtaClickedProperties {
    appointmentDate?: string
    /**
     * The day of the week the appointment is scheduled.
     *
     * https://github.com/AskTia/tmd-server/blob/19bd959d827eb8cfdf9b825b84c6c5f639256a75/services/appointment-booking/index.js#L638
     */
    appointmentDayOfWeek?: string
    /**
     * The stated goal of the appointment.
     *
     * https://github.com/AskTia/tmd-server/blob/19bd959d827eb8cfdf9b825b84c6c5f639256a75/services/appointment-booking/index.js#L638
     */
    appointmentGoal?: string
    /**
     * The unique identifier for the appointment.
     *
     *
     * https://github.com/AskTia/tmd-server/blob/19bd959d827eb8cfdf9b825b84c6c5f639256a75/services/appointment-booking/index.js#L658
     */
    appointmentId?: string
    /**
     * The clinic location of the appointment being booked.
     *
     *
     *
     * https://github.com/AskTia/tmd-server/blob/19bd959d827eb8cfdf9b825b84c6c5f639256a75/services/appointment-booking/index.js#L639
     */
    appointmentLocation?: string
    /**
     * The appointment profile
     *
     * https://github.com/AskTia/tmd-server/blob/19bd959d827eb8cfdf9b825b84c6c5f639256a75/services/appointment-booking/index.js#L577
     *
     * Its uuid is saved on the appointment further down in the function
     *
     * ```
     * ehr.appointment.appointment_profile_uuid
     * ```
     */
    appointmentProfile?: string
    appointmentSuggestionUuid?: string
    /**
     * The time of the appointment
     */
    appointmentTime?: string
    /**
     * The type of appointment.
     *
     * https://github.com/AskTia/tmd-server/blob/19bd959d827eb8cfdf9b825b84c6c5f639256a75/services/appointment-booking/index.js#L577
     *
     * This is accessible throught the
     *
     * ```
     * ehr.appointment_profile.appointment_type_uuid
     * ```
     */
    appointmentType?: string
    /**
     * Did the user or the admin make this change to the booking?
     */
    booker?: string
    /**
     * The name of the pathway taken to book the appointment.
     *
     * Examples:
     * Care Type Card, Quick Book
     */
    bookingPath?: string
    careType?: string
    /**
     * The unique identifier of the exam room chosen for the appointment.
     *
     * https://github.com/AskTia/tmd-server/blob/19bd959d827eb8cfdf9b825b84c6c5f639256a75/services/appointment-booking/index.js#L624
     */
    examRoomId?: string
    /**
     * Appointment has intake y/n
     *
     * This can be resolved using the goal the appointment is booked with and the ehr.goal_intake_map
     *
     * This data is not fetched for booking, and will need to be resolved separately.
     */
    intake?: boolean
    /**
     * Whether the appoinment virtual or not
     */
    isVirtual?: boolean
}

export interface OnDemandCarouselCardClickedProperties {
    appointmentDate?: string
    /**
     * The day of the week the appointment is scheduled.
     *
     * https://github.com/AskTia/tmd-server/blob/19bd959d827eb8cfdf9b825b84c6c5f639256a75/services/appointment-booking/index.js#L638
     */
    appointmentDayOfWeek?: string
    /**
     * The stated goal of the appointment.
     *
     * https://github.com/AskTia/tmd-server/blob/19bd959d827eb8cfdf9b825b84c6c5f639256a75/services/appointment-booking/index.js#L638
     */
    appointmentGoal?: string
    /**
     * The unique identifier for the appointment.
     *
     *
     * https://github.com/AskTia/tmd-server/blob/19bd959d827eb8cfdf9b825b84c6c5f639256a75/services/appointment-booking/index.js#L658
     */
    appointmentId?: string
    /**
     * The clinic location of the appointment being booked.
     *
     *
     *
     * https://github.com/AskTia/tmd-server/blob/19bd959d827eb8cfdf9b825b84c6c5f639256a75/services/appointment-booking/index.js#L639
     */
    appointmentLocation?: string
    /**
     * The appointment profile
     *
     * https://github.com/AskTia/tmd-server/blob/19bd959d827eb8cfdf9b825b84c6c5f639256a75/services/appointment-booking/index.js#L577
     *
     * Its uuid is saved on the appointment further down in the function
     *
     * ```
     * ehr.appointment.appointment_profile_uuid
     * ```
     */
    appointmentProfile?: string
    appointmentSuggestionUuid?: string
    /**
     * The time of the appointment
     */
    appointmentTime?: string
    /**
     * The type of appointment.
     *
     * https://github.com/AskTia/tmd-server/blob/19bd959d827eb8cfdf9b825b84c6c5f639256a75/services/appointment-booking/index.js#L577
     *
     * This is accessible throught the
     *
     * ```
     * ehr.appointment_profile.appointment_type_uuid
     * ```
     */
    appointmentType?: string
    /**
     * Did the user or the admin make this change to the booking?
     */
    booker?: string
    /**
     * The name of the pathway taken to book the appointment.
     *
     * Examples:
     * Care Type Card, Quick Book
     */
    bookingPath?: string
    careType?: string
    /**
     * The unique identifier of the exam room chosen for the appointment.
     *
     * https://github.com/AskTia/tmd-server/blob/19bd959d827eb8cfdf9b825b84c6c5f639256a75/services/appointment-booking/index.js#L624
     */
    examRoomId?: string
    /**
     * Appointment has intake y/n
     *
     * This can be resolved using the goal the appointment is booked with and the ehr.goal_intake_map
     *
     * This data is not fetched for booking, and will need to be resolved separately.
     */
    intake?: boolean
    /**
     * Whether the appoinment virtual or not
     */
    isVirtual?: boolean
}

export interface OnDemandConfirmLocationClickedProperties {
    /**
     * Update the currentClinicLocation user attribute if it is changed.
     *
     *
     * [Github Link](https://github.com/AskTia/tmd-server/blob/09df15d8d7804bd30274f4a455b38230ef6b4c0b/services/clinic-service.js#L112)
     */
    homeClinic?: string
    /**
     * for On Demand, we need to ask whether the patient is located in their home state for billing purposes
     */
    userInHomeState?: boolean
}

export interface OnDemandCtaClickedProperties {
    /**
     * Indicates the date that the member was verified (became a member). Set as a Super Property and a User Trait using the identify call.
     *
     * DB: `membership_payment.transaction_timestamp`
     *
     * WWW: [Github Link](https://github.com/AskTia/www/blob/3ab40266a187f009282bd20fef6c3a2181d2d8a2/controllers/account-controller.js#L872)
     *
     * Admin: [Github Link](https://github.com/AskTia/admin-console/blob/5cd52fd63474504599d38ade1e5c87b4bcde97ab/controllers/user-controller.js#L154)
     */
    memberSignUpDate?: string
}

export interface OnDemandOnAppointmentCardClickedProperties {
    appointmentDate?: string
    /**
     * The day of the week the appointment is scheduled.
     *
     * https://github.com/AskTia/tmd-server/blob/19bd959d827eb8cfdf9b825b84c6c5f639256a75/services/appointment-booking/index.js#L638
     */
    appointmentDayOfWeek?: string
    /**
     * The stated goal of the appointment.
     *
     * https://github.com/AskTia/tmd-server/blob/19bd959d827eb8cfdf9b825b84c6c5f639256a75/services/appointment-booking/index.js#L638
     */
    appointmentGoal?: string
    /**
     * The unique identifier for the appointment.
     *
     *
     * https://github.com/AskTia/tmd-server/blob/19bd959d827eb8cfdf9b825b84c6c5f639256a75/services/appointment-booking/index.js#L658
     */
    appointmentId?: string
    /**
     * The clinic location of the appointment being booked.
     *
     *
     *
     * https://github.com/AskTia/tmd-server/blob/19bd959d827eb8cfdf9b825b84c6c5f639256a75/services/appointment-booking/index.js#L639
     */
    appointmentLocation?: string
    /**
     * The appointment profile
     *
     * https://github.com/AskTia/tmd-server/blob/19bd959d827eb8cfdf9b825b84c6c5f639256a75/services/appointment-booking/index.js#L577
     *
     * Its uuid is saved on the appointment further down in the function
     *
     * ```
     * ehr.appointment.appointment_profile_uuid
     * ```
     */
    appointmentProfile?: string
    appointmentSuggestionUuid?: string
    /**
     * The time of the appointment
     */
    appointmentTime?: string
    /**
     * The type of appointment.
     *
     * https://github.com/AskTia/tmd-server/blob/19bd959d827eb8cfdf9b825b84c6c5f639256a75/services/appointment-booking/index.js#L577
     *
     * This is accessible throught the
     *
     * ```
     * ehr.appointment_profile.appointment_type_uuid
     * ```
     */
    appointmentType?: string
    /**
     * Did the user or the admin make this change to the booking?
     */
    booker?: string
    /**
     * The name of the pathway taken to book the appointment.
     *
     * Examples:
     * Care Type Card, Quick Book
     */
    bookingPath?: string
    careType?: string
    /**
     * For On Demand we are displaying On Demand appointments on appointment cards for Tia appointments. This is to track which Tia appointment the On Demand appointment is displayed on.
     */
    displayedOnAppointmentProfile?: string
    /**
     * The unique identifier of the exam room chosen for the appointment.
     *
     * https://github.com/AskTia/tmd-server/blob/19bd959d827eb8cfdf9b825b84c6c5f639256a75/services/appointment-booking/index.js#L624
     */
    examRoomId?: string
    /**
     * Appointment has intake y/n
     *
     * This can be resolved using the goal the appointment is booked with and the ehr.goal_intake_map
     *
     * This data is not fetched for booking, and will need to be resolved separately.
     */
    intake?: boolean
    /**
     * Whether the appoinment virtual or not
     */
    isVirtual?: boolean
}

export interface OnDemandPageAppointmentCardClickedProperties {
    appointmentDate?: string
    /**
     * The day of the week the appointment is scheduled.
     *
     * https://github.com/AskTia/tmd-server/blob/19bd959d827eb8cfdf9b825b84c6c5f639256a75/services/appointment-booking/index.js#L638
     */
    appointmentDayOfWeek?: string
    /**
     * The stated goal of the appointment.
     *
     * https://github.com/AskTia/tmd-server/blob/19bd959d827eb8cfdf9b825b84c6c5f639256a75/services/appointment-booking/index.js#L638
     */
    appointmentGoal?: string
    /**
     * The unique identifier for the appointment.
     *
     *
     * https://github.com/AskTia/tmd-server/blob/19bd959d827eb8cfdf9b825b84c6c5f639256a75/services/appointment-booking/index.js#L658
     */
    appointmentId?: string
    /**
     * The clinic location of the appointment being booked.
     *
     *
     *
     * https://github.com/AskTia/tmd-server/blob/19bd959d827eb8cfdf9b825b84c6c5f639256a75/services/appointment-booking/index.js#L639
     */
    appointmentLocation?: string
    /**
     * The appointment profile
     *
     * https://github.com/AskTia/tmd-server/blob/19bd959d827eb8cfdf9b825b84c6c5f639256a75/services/appointment-booking/index.js#L577
     *
     * Its uuid is saved on the appointment further down in the function
     *
     * ```
     * ehr.appointment.appointment_profile_uuid
     * ```
     */
    appointmentProfile?: string
    appointmentSuggestionUuid?: string
    /**
     * The time of the appointment
     */
    appointmentTime?: string
    /**
     * The type of appointment.
     *
     * https://github.com/AskTia/tmd-server/blob/19bd959d827eb8cfdf9b825b84c6c5f639256a75/services/appointment-booking/index.js#L577
     *
     * This is accessible throught the
     *
     * ```
     * ehr.appointment_profile.appointment_type_uuid
     * ```
     */
    appointmentType?: string
    /**
     * Did the user or the admin make this change to the booking?
     */
    booker?: string
    /**
     * The name of the pathway taken to book the appointment.
     *
     * Examples:
     * Care Type Card, Quick Book
     */
    bookingPath?: string
    careType?: string
    /**
     * The unique identifier of the exam room chosen for the appointment.
     *
     * https://github.com/AskTia/tmd-server/blob/19bd959d827eb8cfdf9b825b84c6c5f639256a75/services/appointment-booking/index.js#L624
     */
    examRoomId?: string
    /**
     * Appointment has intake y/n
     *
     * This can be resolved using the goal the appointment is booked with and the ehr.goal_intake_map
     *
     * This data is not fetched for booking, and will need to be resolved separately.
     */
    intake?: boolean
    /**
     * Whether the appoinment virtual or not
     */
    isVirtual?: boolean
}

export interface OnboardingQuizQuestionAnsweredProperties {
    answer: any
    name?: string
}

export interface OnboardingQuizViewedProperties {
    /**
     * Name of the page that the user is on.  Example, it should be sending /join-tia
     *
     * Set as super property and user trait.
     *
     * Examples:
     * "Join, Homepage, etc."
     *
     */
    page?: string
}

export interface OnboardingSuggestionPageViewedProperties {
    appointmentProfileName: string
}

export interface PageViewedProperties {
    initial_referrer?: any
    initial_referring_domain?: any
    /**
     * Name of the page the user is on.
     *
     * Examples:
     * Home, Home - Booked, Search, Service-MH, Service-GYN, Service-PC,Service-ACU, Pre Question, Time + Date Selection, Post Question, Review Appointment, Book Transition, Complete Intake, Review Intake, Complete Health Record, Review Health Record, Booked-Prepare, Booked - Check In Now, Checked In - Virtual Not Open Yet, Checked In - Virtual Appt Open now, Your Care, Settings, Your Appointments, Health Record, Inbox Home, Care Coordinator, CWCT - App Scroll, Tracking Daily, Cancel Apt 1.101, Reschedule Apt 1.42, Cancel 1.96
     */
    page?: string
    /**
     * | Rule | Value |
     * |---|---|
     * | Regex |  |
     */
    referrer?: string
    /**
     * | Rule | Value |
     * |---|---|
     * | Regex |  |
     */
    referring_domain?: string
    /**
     * Grab the URL that the user is on.
     *
     * Examples:
     * Join, Homepage, etc.
     */
    url?: string
    /**
     * | Rule | Value |
     * |---|---|
     * | Regex |  |
     */
    utm_campaign?: string
    /**
     * | Rule | Value |
     * |---|---|
     * | Regex |  |
     */
    utm_content?: string
    /**
     * | Rule | Value |
     * |---|---|
     * | Regex |  |
     */
    utm_medium?: string
    /**
     * | Rule | Value |
     * |---|---|
     * | Regex |  |
     */
    utm_source?: string
    /**
     * | Rule | Value |
     * |---|---|
     * | Regex |  |
     */
    utm_term?: string
    /**
     * Set as a Super Property via mixpanel.register
     */
    utmCampaign?: string
    /**
     * Set as a Super Property via mixpanel.register
     */
    utmContent?: string
    /**
     * Set as a Super Property via mixpanel.register
     */
    utmMedium?: string
    /**
     * Set as a Super Property via mixpanel.register
     */
    utmSource?: string
    /**
     * Set as a Super Property via mixpanel.register
     */
    utmTerm?: string
}

export interface PostQuestionSelectedProperties {
    /**
     * The answer the user selected for the screening question
     */
    answerSelected?: string
    /**
     * The screening question that the user answered
     */
    question?: string
}

export interface PostQuestionSubmittedProperties {
    /**
     * The answer the user selected for the screening question
     */
    answerSelected?: string
    /**
     * The screening question that the user answered
     */
    question?: string
}

export interface PreQuestionSelectedProperties {
    /**
     * The answer the user selected for the screening question
     */
    answerSelected?: string
    /**
     * The screening question that the user answered
     */
    question?: string
}

export interface PreQuestionSubmittedProperties {
    /**
     * The answer the user selected for the screening question
     */
    answerSelected?: string
    /**
     * The screening question that the user answered
     */
    question?: string
}

export interface PreSlotSearchLocationSelectedProperties {
    selectedLocation: string
}

export interface PrepareForAppointmentIntakeViewedProperties {
    /**
     * The date and time of the appointment
     */
    appointmentDateAndTime?: string
    /**
     * The stated goal of the appointment.
     * https://github.com/AskTia/tmd-server/blob/19bd959d827eb8cfdf9b825b84c6c5f639256a75/services/appointment-booking/index.js#L638
     */
    appointmentGoal?: string
    /**
     * The unique identifier for the appointment.
     * https://github.com/AskTia/tmd-server/blob/19bd959d827eb8cfdf9b825b84c6c5f639256a75/services/appointment-booking/index.js#L658
     */
    appointmentId?: string
    /**
     * The clinic location of the appointment being booked.
     * https://github.com/AskTia/tmd-server/blob/19bd959d827eb8cfdf9b825b84c6c5f639256a75/services/appointment-booking/index.js#L639
     */
    appointmentLocation?: string
    /**
     * The appointment profile
     * https://github.com/AskTia/tmd-server/blob/19bd959d827eb8cfdf9b825b84c6c5f639256a75/services/appointment-booking/index.js#L577
     * Its uuid is saved on the appointment further down in the function
     *
     * ```
     * ehr.appointment.appointment_profile_uuid
     * ```
     */
    appointmentProfile?: string
    /**
     * The type of care provided during the appointment.
     * https://github.com/AskTia/tmd-server/blob/19bd959d827eb8cfdf9b825b84c6c5f639256a75/services/appointment-booking/index.js#L651
     */
    careType?: string
    /**
     * Boolean that says Completed for Incompleted, to let us know if the user has completed Intake yet.
     */
    intakeCompleted?: boolean
}

export interface QuestionAboutCarePlanDetailsClickedProperties {
    /**
     * intervention that member is clicking in to ask question about
     */
    intervention: string
    userID: string
}

export interface RegistrationStartedProperties {
    /**
     * Name of page.
     *
     * Examples:
     * Join; Homepage; Login Screen
     */
    page?: string
}

export interface SearchImunizationProperties {
    /**
     * | Rule | Value |
     * |---|---|
     * | Type | integer |
     */
    AmountOfImmunizationsFound: number
}

export interface SeeMoreOptionsClickedProperties {
    /**
     * Appointment goal - The stated goal of the appointment.
     *
     * https://github.com/AskTia/tmd-server/blob/19bd959d827eb8cfdf9b825b84c6c5f639256a75/services/appointment-booking/index.js#L638
     */
    appointmentGoal?: string
    /**
     * The appointment profile
     *
     * <https://github.com/AskTia/tmd-server/blob/19bd959d827eb8cfdf9b825b84c6c5f639256a75/services/appointment-booking/index.js#L577>
     *
     * Its uuid is saved on the appointment further down in the function
     *
     * ```
     * ehr.appointment.appointment_profile_uuid
     * ```
     */
    appointmentProfile: string
    /**
     * The type of appointment the user selected
     */
    appointmentType?: string
    careType?: string
    /**
     * When there are no appointments and the user is prompted to message a care coordinator.
     */
    customerCareMessage?: boolean
}

export interface SelectAppointmentViewedProperties {
    /**
     * The appointment type.
     */
    appointmentProfile?: string
    appointmentSuggestionUuid?: string
    /**
     * True or false if any results are shown.
     */
    resultsShown?: boolean
}

export interface SelectPayCurrentInvoiceProperties {
    /**
     * | Rule | Value |
     * |---|---|
     * | Type | number |
     */
    invoiceAmount: number
}

export interface SkipNoInsuranceSelectedProperties {
    /**
     * Provide the value of the insurance selection that was made. The options should be as follows.
     *
     * * No Insurance Selected
     *
     * * Skip Insurance
     */
    insuranceSelection?: string
}

export interface StartTwheJourneyProperties {
    userID: string
}

export interface StressQuizCompletedProperties {
    /**
     * The arousal result that is received. Set this as a user attribute.
     */
    arousal?: string
    /**
     * The energy result that is received. Set this as a user attribute as well.
     */
    energy?: string
}

export interface ThreadViewedProperties {
    tag: string
    /**
     * The thread ID.
     */
    threadId?: string
}

export interface UhrViewedProperties {
    /**
     * What is the percentage of the health record that is complete out of the total amount of health records to be completed in the UHR view.
     *
     * | Rule | Value |
     * |---|---|
     * | Type | number |
     */
    healthRecordPercentageCompleted?: number
    /**
     * Profile Page or Intake
     *
     * Examples:
     * Profile, Sign Up
     */
    location?: string
}

export interface UpcomingAppointmentCardClickedProperties {
    /**
     * The date and time of the appointment
     */
    appointmentDateAndTime?: string
    /**
     * The stated goal of the appointment.
     * https://github.com/AskTia/tmd-server/blob/19bd959d827eb8cfdf9b825b84c6c5f639256a75/services/appointment-booking/index.js#L638
     */
    appointmentGoal?: string
    /**
     * The unique identifier for the appointment.
     * https://github.com/AskTia/tmd-server/blob/19bd959d827eb8cfdf9b825b84c6c5f639256a75/services/appointment-booking/index.js#L658
     */
    appointmentId?: string
    /**
     * The clinic location of the appointment being booked.
     * https://github.com/AskTia/tmd-server/blob/19bd959d827eb8cfdf9b825b84c6c5f639256a75/services/appointment-booking/index.js#L639
     */
    appointmentLocation?: string
    /**
     * The appointment profile
     * https://github.com/AskTia/tmd-server/blob/19bd959d827eb8cfdf9b825b84c6c5f639256a75/services/appointment-booking/index.js#L577
     * Its uuid is saved on the appointment further down in the function
     *
     * ```
     * ehr.appointment.appointment_profile_uuid
     * ```
     */
    appointmentProfile?: string
    /**
     * Pass the status of the appointment at the time the user clicks on the card. Possible options
     *
     * * Complete your next steps - After appointment is booked and user has not completed the next steps.
     *
     * * View appointment -
     *
     *   * When the next steps have been completed
     *
     *   * When the appointment is in person
     *
     *   * When the appointment is vitual and the link is not open yet
     *
     * * Check in now - When the appointment is within the next 24 hrs
     *
     * * Start appointment - When the appointment is virtual and the link is open
     *
     * * Reschedule appointment - Link provided when the user misses a virtual appointment or does not check in on time
     *
     * * Learn more & reschedule - Link provided when the status is set as a no show and the appointment is cancelled
     */
    appointmentStatus?: string
    /**
     * The type of care provided during the appointment.
     * https://github.com/AskTia/tmd-server/blob/19bd959d827eb8cfdf9b825b84c6c5f639256a75/services/appointment-booking/index.js#L651
     */
    careType?: string
}

export interface UpdateAppointmentViewedProperties {
    appointmentDate?: string
    /**
     * The day of the week the appointment is scheduled.
     *
     * https://github.com/AskTia/tmd-server/blob/19bd959d827eb8cfdf9b825b84c6c5f639256a75/services/appointment-booking/index.js#L638
     */
    appointmentDayOfWeek?: string
    /**
     * The stated goal of the appointment.
     *
     * https://github.com/AskTia/tmd-server/blob/19bd959d827eb8cfdf9b825b84c6c5f639256a75/services/appointment-booking/index.js#L638
     */
    appointmentGoal?: string
    /**
     * The unique identifier for the appointment.
     *
     *
     * https://github.com/AskTia/tmd-server/blob/19bd959d827eb8cfdf9b825b84c6c5f639256a75/services/appointment-booking/index.js#L658
     */
    appointmentId?: string
    /**
     * The clinic location of the appointment being booked.
     *
     *
     *
     * https://github.com/AskTia/tmd-server/blob/19bd959d827eb8cfdf9b825b84c6c5f639256a75/services/appointment-booking/index.js#L639
     */
    appointmentLocation?: string
    /**
     * The appointment profile
     *
     * https://github.com/AskTia/tmd-server/blob/19bd959d827eb8cfdf9b825b84c6c5f639256a75/services/appointment-booking/index.js#L577
     *
     * Its uuid is saved on the appointment further down in the function
     *
     * ```
     * ehr.appointment.appointment_profile_uuid
     * ```
     */
    appointmentProfile?: string
    appointmentSuggestionUuid?: string
    /**
     * The time of the appointment
     */
    appointmentTime?: string
    /**
     * The type of appointment.
     *
     * https://github.com/AskTia/tmd-server/blob/19bd959d827eb8cfdf9b825b84c6c5f639256a75/services/appointment-booking/index.js#L577
     *
     * This is accessible throught the
     *
     * ```
     * ehr.appointment_profile.appointment_type_uuid
     * ```
     */
    appointmentType?: string
    /**
     * Did the user or the admin make this change to the booking?
     */
    booker?: string
    /**
     * The name of the pathway taken to book the appointment.
     *
     * Examples:
     * Care Type Card, Quick Book
     */
    bookingPath?: string
    careType?: string
    /**
     * The unique identifier of the exam room chosen for the appointment.
     *
     * https://github.com/AskTia/tmd-server/blob/19bd959d827eb8cfdf9b825b84c6c5f639256a75/services/appointment-booking/index.js#L624
     */
    examRoomId?: string
    /**
     * Appointment has intake y/n
     *
     * This can be resolved using the goal the appointment is booked with and the ehr.goal_intake_map
     *
     * This data is not fetched for booking, and will need to be resolved separately.
     */
    intake?: boolean
    /**
     * Whether the appoinment virtual or not
     */
    isVirtual?: boolean
}

export interface ViewAppointmentBillingProperties {
    /**
     * User current invoice amount
     *
     * | Rule | Value |
     * |---|---|
     * | Type | number |
     */
    invoiceAmount?: number
    /**
     * Total balance of the user
     *
     * | Rule | Value |
     * |---|---|
     * | Type | number |
     */
    totalBalance?: number
}

export interface ViewAppointmentBillingDetailsProperties {
    collectable?: boolean
    encounterId: string
    /**
     * | Rule | Value |
     * |---|---|
     * | Type | number |
     */
    insuranceCoveredInCents?: number
    /**
     * | Rule | Value |
     * |---|---|
     * | Type | number |
     */
    patientAdjustmentsInCents?: number
    /**
     * | Rule | Value |
     * |---|---|
     * | Type | number |
     */
    patientPaidInCents?: number
    /**
     * | Rule | Value |
     * |---|---|
     * | Type | number |
     */
    patientResponsibilityInCents?: number
    processedAs?: string
    /**
     * | Rule | Value |
     * |---|---|
     * | Type | number |
     */
    remainingBalanceInCents?: number
    /**
     * | Rule | Value |
     * |---|---|
     * | Type | number |
     */
    totalChargesInCents?: number
}

export interface ViewedLastProviderSlotsProperties {
    /**
     * | Rule | Value |
     * |---|---|
     * | Type | integer |
     */
    amountOfProviders: number
}

export interface WidgetInsuranceTypeSelectedProperties {
    previousSelectedType?: string
    selectedType: string
}

export interface AutomatedCheckInProperties {
    /**
     * The date and time of the appointment
     */
    appointmentDateAndTime?: string
    /**
     * The stated goal of the appointment.
     * https://github.com/AskTia/tmd-server/blob/19bd959d827eb8cfdf9b825b84c6c5f639256a75/services/appointment-booking/index.js#L638
     */
    appointmentGoal?: string
    /**
     * The unique identifier for the appointment.
     * https://github.com/AskTia/tmd-server/blob/19bd959d827eb8cfdf9b825b84c6c5f639256a75/services/appointment-booking/index.js#L658
     */
    appointmentId?: string
    /**
     * The clinic location of the appointment being booked.
     * https://github.com/AskTia/tmd-server/blob/19bd959d827eb8cfdf9b825b84c6c5f639256a75/services/appointment-booking/index.js#L639
     */
    appointmentLocation?: string
    /**
     * The appointment profile
     * https://github.com/AskTia/tmd-server/blob/19bd959d827eb8cfdf9b825b84c6c5f639256a75/services/appointment-booking/index.js#L577
     * Its uuid is saved on the appointment further down in the function
     *
     * ```
     * ehr.appointment.appointment_profile_uuid
     * ```
     */
    appointmentProfile?: string
    /**
     * The type of care provided during the appointment.
     * https://github.com/AskTia/tmd-server/blob/19bd959d827eb8cfdf9b825b84c6c5f639256a75/services/appointment-booking/index.js#L651
     */
    careType?: string
}

export interface BookingProperties {
    appointmentDate?: string
    /**
     * The day of the week the appointment is scheduled.
     *
     * https://github.com/AskTia/tmd-server/blob/19bd959d827eb8cfdf9b825b84c6c5f639256a75/services/appointment-booking/index.js#L638
     */
    appointmentDayOfWeek?: string
    /**
     * The stated goal of the appointment.
     *
     * https://github.com/AskTia/tmd-server/blob/19bd959d827eb8cfdf9b825b84c6c5f639256a75/services/appointment-booking/index.js#L638
     */
    appointmentGoal?: string
    /**
     * The unique identifier for the appointment.
     *
     *
     * https://github.com/AskTia/tmd-server/blob/19bd959d827eb8cfdf9b825b84c6c5f639256a75/services/appointment-booking/index.js#L658
     */
    appointmentId?: string
    /**
     * The clinic location of the appointment being booked.
     *
     *
     *
     * https://github.com/AskTia/tmd-server/blob/19bd959d827eb8cfdf9b825b84c6c5f639256a75/services/appointment-booking/index.js#L639
     */
    appointmentLocation?: string
    /**
     * The appointment profile
     *
     * https://github.com/AskTia/tmd-server/blob/19bd959d827eb8cfdf9b825b84c6c5f639256a75/services/appointment-booking/index.js#L577
     *
     * Its uuid is saved on the appointment further down in the function
     *
     * ```
     * ehr.appointment.appointment_profile_uuid
     * ```
     */
    appointmentProfile?: string
    appointmentSuggestionUuid?: string
    /**
     * The time of the appointment
     */
    appointmentTime?: string
    /**
     * The type of appointment.
     *
     * https://github.com/AskTia/tmd-server/blob/19bd959d827eb8cfdf9b825b84c6c5f639256a75/services/appointment-booking/index.js#L577
     *
     * This is accessible throught the
     *
     * ```
     * ehr.appointment_profile.appointment_type_uuid
     * ```
     */
    appointmentType?: string
    /**
     * Did the user or the admin make this change to the booking?
     */
    booker?: string
    /**
     * The name of the pathway taken to book the appointment.
     *
     * Examples:
     * Care Type Card, Quick Book
     */
    bookingPath?: string
    careType?: string
    /**
     * The unique identifier of the exam room chosen for the appointment.
     *
     * https://github.com/AskTia/tmd-server/blob/19bd959d827eb8cfdf9b825b84c6c5f639256a75/services/appointment-booking/index.js#L624
     */
    examRoomId?: string
    /**
     * Appointment has intake y/n
     *
     * This can be resolved using the goal the appointment is booked with and the ehr.goal_intake_map
     *
     * This data is not fetched for booking, and will need to be resolved separately.
     */
    intake?: boolean
    /**
     * Whether the appoinment virtual or not
     */
    isVirtual?: boolean
}

export interface ScreenQuestionsProperties {
    /**
     * The answer the user selected for the screening question
     */
    answerSelected?: string
    /**
     * The screening question that the user answered
     */
    question?: string
}

export class Identify implements BaseEvent {
    event_type = amplitude.Types.SpecialEventType.IDENTIFY

    constructor(public event_properties?: IdentifyProperties) {
        this.event_properties = event_properties
    }
}

export class AddToCalendarClicked implements BaseEvent {
    event_type = 'Add To Calendar Clicked'

    constructor(public event_properties?: AddToCalendarClickedProperties) {
        this.event_properties = event_properties
    }
}

export class AddToWaitlistNoTimeSelected implements BaseEvent {
    event_type = 'Add to Waitlist (No Time Selected)'

    constructor(
        public event_properties?: AddToWaitlistNoTimeSelectedProperties
    ) {
        this.event_properties = event_properties
    }
}

export class AddToWaitlistTimeSelectedClicked implements BaseEvent {
    event_type = 'Add to Waitlist (Time Selected) Clicked'

    constructor(
        public event_properties?: AddToWaitlistTimeSelectedClickedProperties
    ) {
        this.event_properties = event_properties
    }
}

export class AddedImmunization implements BaseEvent {
    event_type = 'Added Immunization'
}

export class AnnouncementCta implements BaseEvent {
    event_type = 'Announcement CTA'

    constructor(public event_properties: AnnouncementCtaProperties) {
        this.event_properties = event_properties
    }
}

export class AnnualExamMoreInfoCtaClicked implements BaseEvent {
    event_type = 'Annual Exam More Info CTA Clicked'
}

export class AppointmentCardCtaClicked implements BaseEvent {
    event_type = 'Appointment Card Cta Clicked'

    constructor(public event_properties?: AppointmentCardCtaClickedProperties) {
        this.event_properties = event_properties
    }
}

export class AppointmentFilteredByCadence implements BaseEvent {
    event_type = 'Appointment Filtered by Cadence'

    constructor(
        public event_properties: AppointmentFilteredByCadenceProperties
    ) {
        this.event_properties = event_properties
    }
}

export class AppointmentFilteredByCommunicationStyle implements BaseEvent {
    event_type = 'Appointment Filtered by Communication Style'

    constructor(
        public event_properties: AppointmentFilteredByCommunicationStyleProperties
    ) {
        this.event_properties = event_properties
    }
}

export class AppointmentFilteredByDate implements BaseEvent {
    event_type = 'Appointment Filtered By Date'

    constructor(public event_properties: AppointmentFilteredByDateProperties) {
        this.event_properties = event_properties
    }
}

export class AppointmentFilteredByEthnicity implements BaseEvent {
    event_type = 'Appointment Filtered by Ethnicity'

    constructor(
        public event_properties: AppointmentFilteredByEthnicityProperties
    ) {
        this.event_properties = event_properties
    }
}

export class AppointmentFilteredByLocation implements BaseEvent {
    event_type = 'Appointment Filtered By Location'

    constructor(
        public event_properties: AppointmentFilteredByLocationProperties
    ) {
        this.event_properties = event_properties
    }
}

export class AppointmentFilteredByProvider implements BaseEvent {
    event_type = 'Appointment Filtered by Provider'

    constructor(
        public event_properties: AppointmentFilteredByProviderProperties
    ) {
        this.event_properties = event_properties
    }
}

export class AppointmentFilteredByProviderGender implements BaseEvent {
    event_type = 'Appointment Filtered By Provider Gender'

    constructor(
        public event_properties: AppointmentFilteredByProviderGenderProperties
    ) {
        this.event_properties = event_properties
    }
}

export class AppointmentHistoryViewed implements BaseEvent {
    event_type = 'Appointment History Viewed'
}

export class AppointmentReviewViewed implements BaseEvent {
    event_type = 'Appointment Review Viewed'

    constructor(public event_properties?: AppointmentReviewViewedProperties) {
        this.event_properties = event_properties
    }
}

export class AppointmentStarted implements BaseEvent {
    event_type = 'Appointment Started'

    constructor(public event_properties?: AppointmentStartedProperties) {
        this.event_properties = event_properties
    }
}

export class AppointmentSuggestionBannerClicked implements BaseEvent {
    event_type = 'Appointment Suggestion Banner Clicked'

    constructor(
        public event_properties: AppointmentSuggestionBannerClickedProperties
    ) {
        this.event_properties = event_properties
    }
}

export class AppointmentSuggestionBookCta implements BaseEvent {
    event_type = 'Appointment Suggestion Book CTA'

    constructor(
        public event_properties: AppointmentSuggestionBookCtaProperties
    ) {
        this.event_properties = event_properties
    }
}

export class AppointmentSuggestionDismissCta implements BaseEvent {
    event_type = 'Appointment Suggestion Dismiss CTA'

    constructor(
        public event_properties: AppointmentSuggestionDismissCtaProperties
    ) {
        this.event_properties = event_properties
    }
}

export class AppointmentUpdateConfirmed implements BaseEvent {
    event_type = 'Appointment Update Confirmed'

    constructor(
        public event_properties?: AppointmentUpdateConfirmedProperties
    ) {
        this.event_properties = event_properties
    }
}

export class BackButtonClicked implements BaseEvent {
    event_type = 'Back Button Clicked'

    constructor(public event_properties?: BackButtonClickedProperties) {
        this.event_properties = event_properties
    }
}

export class BookATwheCtaClickedFromAnnualExplanation implements BaseEvent {
    event_type = 'Book A Twhe Cta Clicked From Annual Explanation'
}

export class BookAnAppointmentCtaFromEmptyRecommendedAppointmentsTabClicked
    implements BaseEvent
{
    event_type =
        'Book an Appointment CTA from empty Recommended Appointments tab Clicked'
}

export class BookAppointmentCtaPostSignupClicked implements BaseEvent {
    event_type = 'Book Appointment CTA Post Signup Clicked'
}

export class BookNowOnGroupsBannerClicked implements BaseEvent {
    event_type = 'Book now on Groups banner Clicked'
}

export class BookingConfirmationCtaClicked implements BaseEvent {
    event_type = 'Booking Confirmation Cta Clicked'

    constructor(
        public event_properties?: BookingConfirmationCtaClickedProperties
    ) {
        this.event_properties = event_properties
    }
}

export class BookingGoalSelectionViewed implements BaseEvent {
    event_type = 'Booking Goal Selection Viewed'

    constructor(public event_properties: BookingGoalSelectionViewedProperties) {
        this.event_properties = event_properties
    }
}

export class CancelConfirmed implements BaseEvent {
    event_type = 'Cancel Confirmed'

    constructor(public event_properties?: CancelConfirmedProperties) {
        this.event_properties = event_properties
    }
}

export class CancelMembershipClicked implements BaseEvent {
    event_type = 'Cancel Membership Clicked'

    constructor(public event_properties: CancelMembershipClickedProperties) {
        this.event_properties = event_properties
    }
}

export class CancelMembershipReasonsClicked implements BaseEvent {
    event_type = 'Cancel Membership Reasons Clicked'

    constructor(
        public event_properties: CancelMembershipReasonsClickedProperties
    ) {
        this.event_properties = event_properties
    }
}

export class CancelMembershipReasonsViewed implements BaseEvent {
    event_type = 'Cancel Membership Reasons Viewed'

    constructor(
        public event_properties: CancelMembershipReasonsViewedProperties
    ) {
        this.event_properties = event_properties
    }
}

export class CancelOrChangeAppointmentClicked implements BaseEvent {
    event_type = 'Cancel Or Change Appointment Clicked'

    constructor(
        public event_properties?: CancelOrChangeAppointmentClickedProperties
    ) {
        this.event_properties = event_properties
    }
}

export class CancelOrChangeAppointmentResponseClicked implements BaseEvent {
    event_type = 'Cancel Or Change Appointment Response Clicked'

    constructor(
        public event_properties?: CancelOrChangeAppointmentResponseClickedProperties
    ) {
        this.event_properties = event_properties
    }
}

export class CancelOrChangeMembershipViewed implements BaseEvent {
    event_type = 'Cancel or Change Membership Viewed'

    constructor(
        public event_properties: CancelOrChangeMembershipViewedProperties
    ) {
        this.event_properties = event_properties
    }
}

export class CancelReasonSelected implements BaseEvent {
    event_type = 'Cancel Reason Selected'

    constructor(public event_properties?: CancelReasonSelectedProperties) {
        this.event_properties = event_properties
    }
}

export class CarePlanDetailsViewed implements BaseEvent {
    event_type = 'Care Plan Details Viewed'

    constructor(public event_properties: CarePlanDetailsViewedProperties) {
        this.event_properties = event_properties
    }
}

export class CarePlanViewed implements BaseEvent {
    event_type = 'Care Plan Viewed'
}

export class CareTypeBrowsed implements BaseEvent {
    event_type = 'Care Type Browsed'

    constructor(public event_properties?: CareTypeBrowsedProperties) {
        this.event_properties = event_properties
    }
}

export class CashPayConfirmed implements BaseEvent {
    event_type = 'Cash pay confirmed'

    constructor(public event_properties?: CashPayConfirmedProperties) {
        this.event_properties = event_properties
    }
}

export class ChangeMembershipClicked implements BaseEvent {
    event_type = 'Change Membership Clicked'

    constructor(public event_properties: ChangeMembershipClickedProperties) {
        this.event_properties = event_properties
    }
}

export class CheckInCardOnFileCompleted implements BaseEvent {
    event_type = 'Check In Card On File Completed'

    constructor(
        public event_properties?: CheckInCardOnFileCompletedProperties
    ) {
        this.event_properties = event_properties
    }
}

export class CheckInCompleted implements BaseEvent {
    event_type = 'Check In Completed'

    constructor(public event_properties?: CheckInCompletedProperties) {
        this.event_properties = event_properties
    }
}

export class CheckInConsentCompleted implements BaseEvent {
    event_type = 'Check In Consent Completed'

    constructor(public event_properties?: CheckInConsentCompletedProperties) {
        this.event_properties = event_properties
    }
}

export class CheckInInsuranceCompleted implements BaseEvent {
    event_type = 'Check In Insurance Completed'

    constructor(public event_properties?: CheckInInsuranceCompletedProperties) {
        this.event_properties = event_properties
    }
}

export class CheckInNotStarted implements BaseEvent {
    event_type = 'Check In Not Started'

    constructor(public event_properties?: CheckInNotStartedProperties) {
        this.event_properties = event_properties
    }
}

export class CheckInNowClicked implements BaseEvent {
    event_type = 'Check In Now Clicked'

    constructor(public event_properties?: CheckInNowClickedProperties) {
        this.event_properties = event_properties
    }
}

export class CheckInPart1Completed implements BaseEvent {
    event_type = 'Check In Part 1 Completed'

    constructor(public event_properties?: CheckInPart1CompletedProperties) {
        this.event_properties = event_properties
    }
}

export class CheckInPart2Completed implements BaseEvent {
    event_type = 'Check In Part 2 Completed'

    constructor(public event_properties?: CheckInPart2CompletedProperties) {
        this.event_properties = event_properties
    }
}

export class CheckInPatientInfoCompleted implements BaseEvent {
    event_type = 'Check In Patient Info Completed'

    constructor(
        public event_properties?: CheckInPatientInfoCompletedProperties
    ) {
        this.event_properties = event_properties
    }
}

export class CheckInPreAppointmentInfoCompleted implements BaseEvent {
    event_type = 'Check In Pre Appointment Info Completed'

    constructor(
        public event_properties?: CheckInPreAppointmentInfoCompletedProperties
    ) {
        this.event_properties = event_properties
    }
}

export class CheckInUhrCompleted implements BaseEvent {
    event_type = 'Check In Uhr Completed'

    constructor(public event_properties?: CheckInUhrCompletedProperties) {
        this.event_properties = event_properties
    }
}

export class ClickAnnouncementsTabInDashboard implements BaseEvent {
    event_type = 'Click Announcements tab in Dashboard'
}

export class ClickBookAnAppointmentCtaFromEmptyYourCarePlanTab
    implements BaseEvent
{
    event_type = 'Click Book an Appointment CTA from empty Your Care Plan tab'
}

export class ClickBookNowInNewMemberOnboarding implements BaseEvent {
    event_type = 'Click Book Now in New Member Onboarding'
}

export class ClickGetStartedInNewMemberOnboarding implements BaseEvent {
    event_type = 'Click Get Started in New Member Onboarding'
}

export class ClickLearnMoreOnCarePlanItemInDashboard implements BaseEvent {
    event_type = 'Click Learn More on Care Plan item in Dashboard'

    constructor(
        public event_properties?: ClickLearnMoreOnCarePlanItemInDashboardProperties
    ) {
        this.event_properties = event_properties
    }
}

export class ClickMhGroupsNotificationBanner implements BaseEvent {
    event_type = 'Click MH Groups Notification Banner'
}

export class ClickQuestionAboutCarePlan implements BaseEvent {
    event_type = 'Click Question about Care Plan'

    constructor(public event_properties: ClickQuestionAboutCarePlanProperties) {
        this.event_properties = event_properties
    }
}

export class ClickRecommendedAppointmentsTabInDashboard implements BaseEvent {
    event_type = 'Click Recommended Appointments tab in Dashboard'
}

export class ClickServiceLineModuleOnAppointmentsPage implements BaseEvent {
    event_type = 'Click Service Line Module on Appointments Page'

    constructor(
        public event_properties: ClickServiceLineModuleOnAppointmentsPageProperties
    ) {
        this.event_properties = event_properties
    }
}

export class ClickYourCarePlanTabInDashboard implements BaseEvent {
    event_type = 'Click Your Care Plan tab in Dashboard'
}

export class ClickedAnnualExamInRecommendedActions implements BaseEvent {
    event_type = 'Clicked annual exam in recommended actions'
}

export class ClickedHealthConcernInRecommendedActions implements BaseEvent {
    event_type = 'Clicked health concern in recommended actions'
}

export class ClickedHealthConcernOnHomepage implements BaseEvent {
    event_type = 'Clicked Health Concern on Homepage'
}

export class ClickedMentalHealthSupportInRecommendedActions
    implements BaseEvent
{
    event_type = 'Clicked mental health support in recommended actions'
}

export class ClickedMentalHealthSupportOnHomepage implements BaseEvent {
    event_type = 'Clicked Mental Health Support on Homepage'
}

export class ClickedOnOtherImmunization implements BaseEvent {
    event_type = 'Clicked on Other Immunization'

    constructor(public event_properties: ClickedOnOtherImmunizationProperties) {
        this.event_properties = event_properties
    }
}

export class ClickedOnRecommendedImmunization implements BaseEvent {
    event_type = 'Clicked on Recommended Immunization'

    constructor(
        public event_properties: ClickedOnRecommendedImmunizationProperties
    ) {
        this.event_properties = event_properties
    }
}

export class ClickedRecommendedAppointmentsInRecommendedActions
    implements BaseEvent
{
    event_type = 'Clicked recommended appointments in recommended actions'
}

export class ClickedSomethingElseInRecommendedActions implements BaseEvent {
    event_type = 'Clicked Something Else in Recommended Actions'
}

export class ClickedSomethingElseOnHomepage implements BaseEvent {
    event_type = 'Clicked Something Else on Homepage'
}

export class ClickedStartCareInRecommendedActions implements BaseEvent {
    event_type = 'Clicked start care in recommended actions'
}

export class ClickedStartCareOnHomepage implements BaseEvent {
    event_type = 'Clicked Start Care on Homepage'
}

export class CloseMhGroupsNotificationBanner implements BaseEvent {
    event_type = 'Close MH Groups Notification Banner'
}

export class EmailCaptured implements BaseEvent {
    event_type = 'Email Captured'

    constructor(public event_properties?: EmailCapturedProperties) {
        this.event_properties = event_properties
    }
}

export class EmailLoginSubmitted implements BaseEvent {
    event_type = 'Email Login Submitted'

    constructor(public event_properties?: EmailLoginSubmittedProperties) {
        this.event_properties = event_properties
    }
}

export class ErrorReceived implements BaseEvent {
    event_type = 'Error Received'

    constructor(public event_properties?: ErrorReceivedProperties) {
        this.event_properties = event_properties
    }
}

export class EventRsvp implements BaseEvent {
    event_type = 'Event Rsvp'

    constructor(public event_properties?: EventRsvpProperties) {
        this.event_properties = event_properties
    }
}

export class EventsCtaClicked implements BaseEvent {
    event_type = 'Events CTA Clicked '
}

export class ExitNewMemberOnboarding implements BaseEvent {
    event_type = 'Exit New Member Onboarding'

    constructor(public event_properties: ExitNewMemberOnboardingProperties) {
        this.event_properties = event_properties
    }
}

export class FilterAppointmentsByKeyword implements BaseEvent {
    event_type = 'Filter Appointments by Keyword'

    constructor(
        public event_properties: FilterAppointmentsByKeywordProperties
    ) {
        this.event_properties = event_properties
    }
}

export class FilterAppointmentsByLocation implements BaseEvent {
    event_type = 'Filter Appointments by Location'

    constructor(
        public event_properties: FilterAppointmentsByLocationProperties
    ) {
        this.event_properties = event_properties
    }
}

export class FilterAppointmentsByServiceLine implements BaseEvent {
    event_type = 'Filter Appointments by Service Line'

    constructor(
        public event_properties: FilterAppointmentsByServiceLineProperties
    ) {
        this.event_properties = event_properties
    }
}

export class HomeViewed implements BaseEvent {
    event_type = 'Home Viewed'
}

export class InboxViewed implements BaseEvent {
    event_type = 'Inbox Viewed'

    constructor(public event_properties?: InboxViewedProperties) {
        this.event_properties = event_properties
    }
}

export class InsuranceImageSent implements BaseEvent {
    event_type = 'Insurance Image Sent'

    constructor(public event_properties?: InsuranceImageSentProperties) {
        this.event_properties = event_properties
    }
}

export class InsuranceSelected implements BaseEvent {
    event_type = 'Insurance Selected'

    constructor(public event_properties?: InsuranceSelectedProperties) {
        this.event_properties = event_properties
    }
}

export class JoinNowButtonClicked implements BaseEvent {
    event_type = 'Join Now Button Clicked'
}

export class LearnMoreOnGroupsBannerClicked implements BaseEvent {
    event_type = 'Learn more on Groups banner Clicked'
}

export class LogoutCompleted implements BaseEvent {
    event_type = 'Logout Completed'
}

export class MemberLoginViewed implements BaseEvent {
    event_type = 'Member Login Viewed'
}

export class MembershipPlanSelected implements BaseEvent {
    event_type = 'Membership Plan Selected'

    constructor(public event_properties?: MembershipPlanSelectedProperties) {
        this.event_properties = event_properties
    }
}

export class NavBookAppointmentButtonClicked implements BaseEvent {
    event_type = 'Nav Book Appointment Button Clicked'

    constructor(
        public event_properties: NavBookAppointmentButtonClickedProperties
    ) {
        this.event_properties = event_properties
    }
}

export class NavigatedToAppointmentsPage implements BaseEvent {
    event_type = 'Navigated to Appointments Page'
}

export class NotATiaMemberClicked implements BaseEvent {
    event_type = 'Not A Tia Member Clicked'
}

export class NpsModalViewed implements BaseEvent {
    event_type = 'Nps Modal Viewed'

    constructor(public event_properties?: NpsModalViewedProperties) {
        this.event_properties = event_properties
    }
}

export class NpsSubmitted implements BaseEvent {
    event_type = 'Nps Submitted'

    constructor(public event_properties?: NpsSubmittedProperties) {
        this.event_properties = event_properties
    }
}

export class OnDemandBookCtaClicked implements BaseEvent {
    event_type = 'On Demand Book CTA Clicked'

    constructor(public event_properties?: OnDemandBookCtaClickedProperties) {
        this.event_properties = event_properties
    }
}

export class OnDemandCarouselCardClicked implements BaseEvent {
    event_type = 'On Demand Carousel Card Clicked'

    constructor(
        public event_properties?: OnDemandCarouselCardClickedProperties
    ) {
        this.event_properties = event_properties
    }
}

export class OnDemandConfirmLocationClicked implements BaseEvent {
    event_type = 'On Demand Confirm Location Clicked'

    constructor(
        public event_properties?: OnDemandConfirmLocationClickedProperties
    ) {
        this.event_properties = event_properties
    }
}

export class OnDemandCtaClicked implements BaseEvent {
    event_type = 'On Demand CTA Clicked'

    constructor(public event_properties?: OnDemandCtaClickedProperties) {
        this.event_properties = event_properties
    }
}

export class OnDemandDisplayed implements BaseEvent {
    event_type = 'On Demand Displayed'
}

export class OnDemandEnterWaitingRoomCtaClicked implements BaseEvent {
    event_type = 'On Demand Enter Waiting Room CTA Clicked'
}

export class OnDemandJoinZoomCtaClicked implements BaseEvent {
    event_type = 'On Demand Join Zoom CTA Clicked'
}

export class OnDemandOnAppointmentCardClicked implements BaseEvent {
    event_type = 'On Demand on Appointment Card Clicked'

    constructor(
        public event_properties?: OnDemandOnAppointmentCardClickedProperties
    ) {
        this.event_properties = event_properties
    }
}

export class OnDemandPageAppointmentCardClicked implements BaseEvent {
    event_type = 'On Demand Page Appointment Card Clicked'

    constructor(
        public event_properties?: OnDemandPageAppointmentCardClickedProperties
    ) {
        this.event_properties = event_properties
    }
}

export class OnboardingExploreAllOtherAppointmentsClicked implements BaseEvent {
    event_type = 'Onboarding Explore All Other Appointments Clicked'
}

export class OnboardingQuizQuestionAnswered implements BaseEvent {
    event_type = 'Onboarding Quiz Question Answered'

    constructor(
        public event_properties: OnboardingQuizQuestionAnsweredProperties
    ) {
        this.event_properties = event_properties
    }
}

export class OnboardingQuizViewed implements BaseEvent {
    event_type = 'Onboarding Quiz Viewed'

    constructor(public event_properties?: OnboardingQuizViewedProperties) {
        this.event_properties = event_properties
    }
}

export class OnboardingSuggestionClicked implements BaseEvent {
    event_type = 'Onboarding Suggestion Clicked'
}

export class OnboardingSuggestionNoThanksClicked implements BaseEvent {
    event_type = 'Onboarding Suggestion No Thanks Clicked'
}

export class OnboardingSuggestionPageViewed implements BaseEvent {
    event_type = 'Onboarding Suggestion Page Viewed'

    constructor(
        public event_properties: OnboardingSuggestionPageViewedProperties
    ) {
        this.event_properties = event_properties
    }
}

export class PageViewed implements BaseEvent {
    event_type = 'Page Viewed'

    constructor(public event_properties?: PageViewedProperties) {
        this.event_properties = event_properties
    }
}

export class PaymentInformationSaved implements BaseEvent {
    event_type = 'Payment Information Saved'
}

export class PaymentSubmitted implements BaseEvent {
    event_type = 'Payment Submitted'
}

export class PhoneNumberMemberLoginEntered implements BaseEvent {
    event_type = 'Phone Number Member Login Entered'
}

export class PostQuestionSelected implements BaseEvent {
    event_type = 'Post Question Selected'

    constructor(public event_properties?: PostQuestionSelectedProperties) {
        this.event_properties = event_properties
    }
}

export class PostQuestionSubmitted implements BaseEvent {
    event_type = 'Post Question Submitted'

    constructor(public event_properties?: PostQuestionSubmittedProperties) {
        this.event_properties = event_properties
    }
}

export class PreQuestionSelected implements BaseEvent {
    event_type = 'Pre Question Selected'

    constructor(public event_properties?: PreQuestionSelectedProperties) {
        this.event_properties = event_properties
    }
}

export class PreQuestionSubmitted implements BaseEvent {
    event_type = 'Pre Question Submitted'

    constructor(public event_properties?: PreQuestionSubmittedProperties) {
        this.event_properties = event_properties
    }
}

export class PreSlotSearchLocationSelected implements BaseEvent {
    event_type = 'Pre Slot Search Location Selected'

    constructor(
        public event_properties: PreSlotSearchLocationSelectedProperties
    ) {
        this.event_properties = event_properties
    }
}

export class PrepareForAppointmentIntakeViewed implements BaseEvent {
    event_type = 'Prepare For Appointment Intake Viewed'

    constructor(
        public event_properties?: PrepareForAppointmentIntakeViewedProperties
    ) {
        this.event_properties = event_properties
    }
}

export class ProfileClicked implements BaseEvent {
    event_type = 'Profile Clicked'
}

export class QuestionAboutCarePlanDetailsClicked implements BaseEvent {
    event_type = 'Question about Care Plan Details Clicked'

    constructor(
        public event_properties: QuestionAboutCarePlanDetailsClickedProperties
    ) {
        this.event_properties = event_properties
    }
}

export class RegistrationStarted implements BaseEvent {
    event_type = 'Registration Started'

    constructor(public event_properties?: RegistrationStartedProperties) {
        this.event_properties = event_properties
    }
}

export class RemovedImmunization implements BaseEvent {
    event_type = 'Removed Immunization'
}

export class ResumeSignupBannerCtaClicked implements BaseEvent {
    event_type = 'resume signup banner cta clicked'
}

export class ResumeSignupBannerCtaRendered implements BaseEvent {
    event_type = 'resume signup banner cta rendered'
}

export class ResumeSignupBookAppointmentModalRendered implements BaseEvent {
    event_type = 'resume signup book appointment modal rendered'
}

export class ResumeSignupModalCtaClicked implements BaseEvent {
    event_type = 'resume signup modal cta clicked'
}

export class ResumeSignupModalCtaRendered implements BaseEvent {
    event_type = 'resume signup modal cta rendered'
}

export class SearchImunization implements BaseEvent {
    event_type = 'Search Imunization'

    constructor(public event_properties: SearchImunizationProperties) {
        this.event_properties = event_properties
    }
}

export class SeeMoreOptionsClicked implements BaseEvent {
    event_type = 'See More Options Clicked'

    constructor(public event_properties: SeeMoreOptionsClickedProperties) {
        this.event_properties = event_properties
    }
}

export class SelectANewAppointmentTimeClicked implements BaseEvent {
    event_type = 'Select A New Appointment Time Clicked'
}

export class SelectAppointmentViewed implements BaseEvent {
    event_type = 'Select Appointment Viewed'

    constructor(public event_properties?: SelectAppointmentViewedProperties) {
        this.event_properties = event_properties
    }
}

export class SelectPayCurrentInvoice implements BaseEvent {
    event_type = 'Select Pay Current Invoice'

    constructor(public event_properties: SelectPayCurrentInvoiceProperties) {
        this.event_properties = event_properties
    }
}

export class SelectedLastProviderSlots implements BaseEvent {
    event_type = 'Selected Last Provider Slots'
}

export class SettingsViewed implements BaseEvent {
    event_type = 'Settings Viewed'
}

export class SignInWithEmailClicked implements BaseEvent {
    event_type = 'Sign In With Email Clicked'
}

export class SkipNoInsuranceSelected implements BaseEvent {
    event_type = 'Skip No Insurance Selected'

    constructor(public event_properties?: SkipNoInsuranceSelectedProperties) {
        this.event_properties = event_properties
    }
}

export class StartTwheJourney implements BaseEvent {
    event_type = 'Start TWHE Journey'

    constructor(public event_properties: StartTwheJourneyProperties) {
        this.event_properties = event_properties
    }
}

export class StressQuizCompleted implements BaseEvent {
    event_type = 'Stress Quiz Completed'

    constructor(public event_properties?: StressQuizCompletedProperties) {
        this.event_properties = event_properties
    }
}

export class StressQuizStarted implements BaseEvent {
    event_type = 'Stress Quiz Started'
}

export class ThreadViewed implements BaseEvent {
    event_type = 'Thread Viewed'

    constructor(public event_properties: ThreadViewedProperties) {
        this.event_properties = event_properties
    }
}

export class TwoFactorCodeEntered implements BaseEvent {
    event_type = 'Two Factor Code Entered'
}

export class UhrViewed implements BaseEvent {
    event_type = 'Uhr Viewed'

    constructor(public event_properties?: UhrViewedProperties) {
        this.event_properties = event_properties
    }
}

export class UpcomingAppointmentCardClicked implements BaseEvent {
    event_type = 'Upcoming Appointment Card Clicked'

    constructor(
        public event_properties?: UpcomingAppointmentCardClickedProperties
    ) {
        this.event_properties = event_properties
    }
}

export class UpdateAppointmentViewed implements BaseEvent {
    event_type = 'Update Appointment Viewed'

    constructor(public event_properties?: UpdateAppointmentViewedProperties) {
        this.event_properties = event_properties
    }
}

export class UpdateInsuranceBannerClicked implements BaseEvent {
    event_type = 'Update Insurance Banner Clicked'
}

export class UpdatePaymentInformationClicked implements BaseEvent {
    event_type = 'Update Payment Information Clicked'
}

export class ViewAppointmentBilling implements BaseEvent {
    event_type = 'View Appointment Billing'

    constructor(public event_properties?: ViewAppointmentBillingProperties) {
        this.event_properties = event_properties
    }
}

export class ViewAppointmentBillingDetails implements BaseEvent {
    event_type = 'View Appointment Billing Details'

    constructor(
        public event_properties: ViewAppointmentBillingDetailsProperties
    ) {
        this.event_properties = event_properties
    }
}

export class ViewNewMemberOnboarding implements BaseEvent {
    event_type = 'View New Member Onboarding'
}

export class ViewYourCarePlanClickedOnHome implements BaseEvent {
    event_type = 'View Your Care Plan Clicked on Home'
}

export class ViewedLastProviderSlots implements BaseEvent {
    event_type = 'Viewed Last Provider Slots'

    constructor(public event_properties: ViewedLastProviderSlotsProperties) {
        this.event_properties = event_properties
    }
}

export class WholeHealthServicePageViewed implements BaseEvent {
    event_type = 'Whole Health Service Page Viewed'
}

export class WidgetInsuranceTypeSelected implements BaseEvent {
    event_type = 'Widget Insurance Type Selected'

    constructor(
        public event_properties: WidgetInsuranceTypeSelectedProperties
    ) {
        this.event_properties = event_properties
    }
}

export class WidgetPricingStepViewed implements BaseEvent {
    event_type = 'Widget Pricing Step Viewed'
}

export class YourCareViewed implements BaseEvent {
    event_type = 'Your Care Viewed'
}

export type PromiseResult<T> = { promise: Promise<T | void> }

const getVoidPromiseResult = () => ({ promise: Promise.resolve() })

// prettier-ignore
export class Ampli {
  private disabled: boolean = false;
  private amplitude?: BrowserClient;

  get client(): BrowserClient {
    this.isInitializedAndEnabled();
    return this.amplitude!;
  }

  get isLoaded(): boolean {
    return this.amplitude != null;
  }

  private isInitializedAndEnabled(): boolean {
    if (!this.amplitude) {
      console.error('ERROR: Ampli is not yet initialized. Have you called ampli.load() on app start?');
      return false;
    }
    return !this.disabled;
  }

  /**
   * Initialize the Ampli SDK. Call once when your application starts.
   *
   * @param options Configuration options to initialize the Ampli SDK with.
   */
  load(options: LoadOptions): PromiseResult<void> {
    this.disabled = options.disabled ?? false;

    if (this.amplitude) {
      console.warn('WARNING: Ampli is already intialized. Ampli.load() should be called once at application startup.');
      return getVoidPromiseResult();
    }

    let apiKey: string | null = null;
    if (options.client && 'apiKey' in options.client) {
      apiKey = options.client.apiKey;
    } else if ('environment' in options) {
      apiKey = ApiKey[options.environment];
    }

    if (options.client && 'instance' in options.client) {
      this.amplitude = options.client.instance;
    } else if (apiKey) {
      this.amplitude = amplitude.createInstance();
      const configuration = (options.client && 'configuration' in options.client) ? options.client.configuration : {};
      return this.amplitude.init(apiKey, undefined, { ...DefaultConfiguration, ...configuration });
    } else {
      console.error("ERROR: ampli.load() requires 'environment', 'client.apiKey', or 'client.instance'");
    }

    return getVoidPromiseResult();
  }

  /**
   * Identify a user and set user properties.
   *
   * @param userId The user's id.
   * @param properties The user properties.
   * @param options Optional event options.
   */
  identify(
    userId: string | undefined,
    properties?: IdentifyProperties,
    options?: EventOptions,
  ): PromiseResult<Result> {
    if (!this.isInitializedAndEnabled()) {
      return getVoidPromiseResult();
    }

    if (userId) {
      options = {...options,  user_id: userId};
    }

    const amplitudeIdentify = new amplitude.Identify();
    const eventProperties = properties;
    if (eventProperties != null) {
      for (const [key, value] of Object.entries(eventProperties)) {
        amplitudeIdentify.set(key, value);
      }
    }
    return this.amplitude!.identify(
      amplitudeIdentify,
      options,
    );
  }

 /**
  * Flush the event.
  */
  flush() : PromiseResult<Result> {
    if (!this.isInitializedAndEnabled()) {
      return getVoidPromiseResult();
    }

    return this.amplitude!.flush();
  }

  /**
   * Track event
   *
   * @param event The event to track.
   * @param options Optional event options.
   */
  track(event: Event, options?: EventOptions): PromiseResult<Result> {
    if (!this.isInitializedAndEnabled()) {
      return getVoidPromiseResult();
    }

    return this.amplitude!.track(event, undefined, options);
  }

  /**
   * Add To Calendar Clicked
   *
   * [View in Tracking Plan](https://data.amplitude.com/asktia/Asktia/events/main/latest/Add%20To%20Calendar%20Clicked)
   *
   * When a user clicks to add upcoming appointment to their calendar. This can viewed:
   *
   * * Upcoming appointment card on the homeview when the user clicks on the date/time
   *
   * * When the user is on the first page of check in
   *
   * * After checking in and the user clicks on the "Add to calendar" button on the chek in confirmation page.
   *
   *
   *
   *
   * Owner: joelle+tia@notablegrowth.com
   *
   * @param properties The event's properties (e.g. calendarSelected)
   * @param options Amplitude event options.
   */
  addToCalendarClicked(
    properties?: AddToCalendarClickedProperties,
    options?: EventOptions,
  ) {
    return this.track(new AddToCalendarClicked(properties), options);
  }

  /**
   * Add to Waitlist (No Time Selected)
   *
   * [View in Tracking Plan](https://data.amplitude.com/asktia/Asktia/events/main/latest/Add%20to%20Waitlist%20(No%20Time%20Selected))
   *
   * when member wants to join waitlist and no time has been selected yet
   *
   * Owner: Ari Saft
   *
   * @param properties The event's properties (e.g. appointmentDate)
   * @param options Amplitude event options.
   */
  addToWaitlistNoTimeSelected(
    properties?: AddToWaitlistNoTimeSelectedProperties,
    options?: EventOptions,
  ) {
    return this.track(new AddToWaitlistNoTimeSelected(properties), options);
  }

  /**
   * Add to Waitlist (Time Selected) Clicked
   *
   * [View in Tracking Plan](https://data.amplitude.com/asktia/Asktia/events/main/latest/Add%20to%20Waitlist%20(Time%20Selected)%20Clicked)
   *
   * when someone wants to add to waitlist and time has been selected
   *
   * Owner: Ari Saft
   *
   * @param properties The event's properties (e.g. appointmentDate)
   * @param options Amplitude event options.
   */
  addToWaitlistTimeSelectedClicked(
    properties?: AddToWaitlistTimeSelectedClickedProperties,
    options?: EventOptions,
  ) {
    return this.track(new AddToWaitlistTimeSelectedClicked(properties), options);
  }

  /**
   * Added Immunization
   *
   * [View in Tracking Plan](https://data.amplitude.com/asktia/Asktia/events/main/latest/Added%20Immunization)
   *
   * Event has no description in tracking plan.
   *
   * @param options Amplitude event options.
   */
  addedImmunization(
    options?: EventOptions,
  ) {
    return this.track(new AddedImmunization(), options);
  }

  /**
   * Announcement CTA
   *
   * [View in Tracking Plan](https://data.amplitude.com/asktia/Asktia/events/main/latest/Announcement%20CTA)
   *
   * This event fires when a member taps on one ofr the announcement CTAs in the member portal
   *
   *
   *
   *
   * Owner: ari.weiss@asktia.com
   *
   * @param properties The event's properties (e.g. announcement_name)
   * @param options Amplitude event options.
   */
  announcementCta(
    properties: AnnouncementCtaProperties,
    options?: EventOptions,
  ) {
    return this.track(new AnnouncementCta(properties), options);
  }

  /**
   * Annual Exam More Info CTA Clicked
   *
   * [View in Tracking Plan](https://data.amplitude.com/asktia/Asktia/events/main/latest/Annual%20Exam%20More%20Info%20CTA%20Clicked)
   *
   * When a user clicks on "Evaluate annual exam options" which triggers a modal with a description of the options and what is included
   *
   * Owner: joelle+tia@notablegrowth.com
   *
   * @param options Amplitude event options.
   */
  annualExamMoreInfoCtaClicked(
    options?: EventOptions,
  ) {
    return this.track(new AnnualExamMoreInfoCtaClicked(), options);
  }

  /**
   * Appointment Card Cta Clicked
   *
   * [View in Tracking Plan](https://data.amplitude.com/asktia/Asktia/events/main/latest/Appointment%20Card%20Cta%20Clicked)
   *
   * This event fires when a user clicks the "Book this appointment" CTA on any appointment card from the home screen OR directly on an appointment card from the /appointments screen.
   *
   *
   *
   *
   *
   *
   *
   * Owner: david+tia@notablegrowth.com
   *
   * @param properties The event's properties (e.g. appointmentDate)
   * @param options Amplitude event options.
   */
  appointmentCardCtaClicked(
    properties?: AppointmentCardCtaClickedProperties,
    options?: EventOptions,
  ) {
    return this.track(new AppointmentCardCtaClicked(properties), options);
  }

  /**
   * Appointment Filtered by Cadence
   *
   * [View in Tracking Plan](https://data.amplitude.com/asktia/Asktia/events/main/latest/Appointment%20Filtered%20by%20Cadence)
   *
   * when a member filters a MH appointment by cadence
   *
   * Owner: Ari Saft
   *
   * @param properties The event's properties (e.g. AppointmentCadence)
   * @param options Amplitude event options.
   */
  appointmentFilteredByCadence(
    properties: AppointmentFilteredByCadenceProperties,
    options?: EventOptions,
  ) {
    return this.track(new AppointmentFilteredByCadence(properties), options);
  }

  /**
   * Appointment Filtered by Communication Style
   *
   * [View in Tracking Plan](https://data.amplitude.com/asktia/Asktia/events/main/latest/Appointment%20Filtered%20by%20Communication%20Style)
   *
   * when member filters by communication style
   *
   * Owner: Ari Saft
   *
   * @param properties The event's properties (e.g. appointmentProfile)
   * @param options Amplitude event options.
   */
  appointmentFilteredByCommunicationStyle(
    properties: AppointmentFilteredByCommunicationStyleProperties,
    options?: EventOptions,
  ) {
    return this.track(new AppointmentFilteredByCommunicationStyle(properties), options);
  }

  /**
   * Appointment Filtered By Date
   *
   * [View in Tracking Plan](https://data.amplitude.com/asktia/Asktia/events/main/latest/Appointment%20Filtered%20By%20Date)
   *
   * When the user clicks "Jump to {date}" to filter the selected appointment by date
   *
   *
   *
   *
   * Owner: joelle+tia@notablegrowth.com
   *
   * @param properties The event's properties (e.g. appointmentProfile)
   * @param options Amplitude event options.
   */
  appointmentFilteredByDate(
    properties: AppointmentFilteredByDateProperties,
    options?: EventOptions,
  ) {
    return this.track(new AppointmentFilteredByDate(properties), options);
  }

  /**
   * Appointment Filtered by Ethnicity
   *
   * [View in Tracking Plan](https://data.amplitude.com/asktia/Asktia/events/main/latest/Appointment%20Filtered%20by%20Ethnicity)
   *
   * when member filters for appointments by race and ethnicity
   *
   * Owner: Ari Saft
   *
   * @param properties The event's properties (e.g. appointmentProfile)
   * @param options Amplitude event options.
   */
  appointmentFilteredByEthnicity(
    properties: AppointmentFilteredByEthnicityProperties,
    options?: EventOptions,
  ) {
    return this.track(new AppointmentFilteredByEthnicity(properties), options);
  }

  /**
   * Appointment Filtered By Location
   *
   * [View in Tracking Plan](https://data.amplitude.com/asktia/Asktia/events/main/latest/Appointment%20Filtered%20By%20Location)
   *
   * When the user selects to filter appointment options by location. The event fires when the member clicks "Apply Filter".
   *
   *
   *
   *
   * Owner: joelle+tia@notablegrowth.com
   *
   * @param properties The event's properties (e.g. appointmentLocation)
   * @param options Amplitude event options.
   */
  appointmentFilteredByLocation(
    properties: AppointmentFilteredByLocationProperties,
    options?: EventOptions,
  ) {
    return this.track(new AppointmentFilteredByLocation(properties), options);
  }

  /**
   * Appointment Filtered by Provider
   *
   * [View in Tracking Plan](https://data.amplitude.com/asktia/Asktia/events/main/latest/Appointment%20Filtered%20by%20Provider)
   *
   * When the user selects to filter appointment options by provider. The event fires when the member clicks "Apply Filter".
   *
   *
   *
   *
   * Owner: Kassio Khaleb
   *
   * @param properties The event's properties (e.g. appointmentProfile)
   * @param options Amplitude event options.
   */
  appointmentFilteredByProvider(
    properties: AppointmentFilteredByProviderProperties,
    options?: EventOptions,
  ) {
    return this.track(new AppointmentFilteredByProvider(properties), options);
  }

  /**
   * Appointment Filtered By Provider Gender
   *
   * [View in Tracking Plan](https://data.amplitude.com/asktia/Asktia/events/main/latest/Appointment%20Filtered%20By%20Provider%20Gender)
   *
   * When the user selects to filter appointment options by provider gender. The event fires when the member clicks "Apply Filter"
   *
   *
   *
   *
   * Owner: joelle+tia@notablegrowth.com
   *
   * @param properties The event's properties (e.g. appointmentProfile)
   * @param options Amplitude event options.
   */
  appointmentFilteredByProviderGender(
    properties: AppointmentFilteredByProviderGenderProperties,
    options?: EventOptions,
  ) {
    return this.track(new AppointmentFilteredByProviderGender(properties), options);
  }

  /**
   * Appointment History Viewed
   *
   * [View in Tracking Plan](https://data.amplitude.com/asktia/Asktia/events/main/latest/Appointment%20History%20Viewed)
   *
   * This event fires when a member views the appointment history page in the member portal
   *
   *
   *
   *
   * Owner: ari.weiss@asktia.com
   *
   * @param options Amplitude event options.
   */
  appointmentHistoryViewed(
    options?: EventOptions,
  ) {
    return this.track(new AppointmentHistoryViewed(), options);
  }

  /**
   * Appointment Review Viewed
   *
   * [View in Tracking Plan](https://data.amplitude.com/asktia/Asktia/events/main/latest/Appointment%20Review%20Viewed)
   *
   * When the appointment review is viewed after selecting an appointment time.
   *
   *
   *
   *
   * Owner: david+tia@notablegrowth.com
   *
   * @param properties The event's properties (e.g. appointmentDate)
   * @param options Amplitude event options.
   */
  appointmentReviewViewed(
    properties?: AppointmentReviewViewedProperties,
    options?: EventOptions,
  ) {
    return this.track(new AppointmentReviewViewed(properties), options);
  }

  /**
   * Appointment Started
   *
   * [View in Tracking Plan](https://data.amplitude.com/asktia/Asktia/events/main/latest/Appointment%20Started)
   *
   * When the user taps the CTA in waiting room for virtual appointment
   *
   * Owner: joelle+tia@notablegrowth.com
   *
   * @param properties The event's properties (e.g. appointmentDateAndTime)
   * @param options Amplitude event options.
   */
  appointmentStarted(
    properties?: AppointmentStartedProperties,
    options?: EventOptions,
  ) {
    return this.track(new AppointmentStarted(properties), options);
  }

  /**
   * Appointment Suggestion Banner Clicked
   *
   * [View in Tracking Plan](https://data.amplitude.com/asktia/Asktia/events/main/latest/Appointment%20Suggestion%20Banner%20Clicked)
   *
   * When the appointment suggestion banner is tapped
   *
   * Owner: ari.weiss@asktia.com
   *
   * @param properties The event's properties (e.g. appointmentProfile)
   * @param options Amplitude event options.
   */
  appointmentSuggestionBannerClicked(
    properties: AppointmentSuggestionBannerClickedProperties,
    options?: EventOptions,
  ) {
    return this.track(new AppointmentSuggestionBannerClicked(properties), options);
  }

  /**
   * Appointment Suggestion Book CTA
   *
   * [View in Tracking Plan](https://data.amplitude.com/asktia/Asktia/events/main/latest/Appointment%20Suggestion%20Book%20CTA)
   *
   * when a member taps on the primary CTA of the appointment suggestion card to book the appointment
   *
   *
   *
   *
   * Owner: ari.weiss@asktia.com
   *
   * @param properties The event's properties (e.g. appointmentProfile)
   * @param options Amplitude event options.
   */
  appointmentSuggestionBookCta(
    properties: AppointmentSuggestionBookCtaProperties,
    options?: EventOptions,
  ) {
    return this.track(new AppointmentSuggestionBookCta(properties), options);
  }

  /**
   * Appointment Suggestion Dismiss CTA
   *
   * [View in Tracking Plan](https://data.amplitude.com/asktia/Asktia/events/main/latest/Appointment%20Suggestion%20Dismiss%20CTA)
   *
   * This event fires when a member clicks on the secondary CTA to dismiss an appointment suggestion
   *
   *
   *
   *
   * Owner: ari.weiss@asktia.com
   *
   * @param properties The event's properties (e.g. appointmentProfile)
   * @param options Amplitude event options.
   */
  appointmentSuggestionDismissCta(
    properties: AppointmentSuggestionDismissCtaProperties,
    options?: EventOptions,
  ) {
    return this.track(new AppointmentSuggestionDismissCta(properties), options);
  }

  /**
   * Appointment Update Confirmed
   *
   * [View in Tracking Plan](https://data.amplitude.com/asktia/Asktia/events/main/latest/Appointment%20Update%20Confirmed)
   *
   * When a user selects to pay the fee for cancellation or keep the appointment
   *
   *
   *
   *
   * Owner: joelle+tia@notablegrowth.com
   *
   * @param properties The event's properties (e.g. appointmentDate)
   * @param options Amplitude event options.
   */
  appointmentUpdateConfirmed(
    properties?: AppointmentUpdateConfirmedProperties,
    options?: EventOptions,
  ) {
    return this.track(new AppointmentUpdateConfirmed(properties), options);
  }

  /**
   * Back Button Clicked
   *
   * [View in Tracking Plan](https://data.amplitude.com/asktia/Asktia/events/main/latest/Back%20Button%20Clicked)
   *
   * When a user clicks on the back button in the navigation within the member portal
   *
   *
   *
   *
   * Owner: joelle+tia@notablegrowth.com
   *
   * @param properties The event's properties (e.g. pageName)
   * @param options Amplitude event options.
   */
  backButtonClicked(
    properties?: BackButtonClickedProperties,
    options?: EventOptions,
  ) {
    return this.track(new BackButtonClicked(properties), options);
  }

  /**
   * Book A Twhe Cta Clicked From Annual Explanation
   *
   * [View in Tracking Plan](https://data.amplitude.com/asktia/Asktia/events/main/latest/Book%20A%20Twhe%20Cta%20Clicked%20From%20Annual%20Explanation)
   *
   * Owner: Swizec Teller
   *
   * @param options Amplitude event options.
   */
  bookATwheCtaClickedFromAnnualExplanation(
    options?: EventOptions,
  ) {
    return this.track(new BookATwheCtaClickedFromAnnualExplanation(), options);
  }

  /**
   * Book an Appointment CTA from empty Recommended Appointments tab Clicked
   *
   * [View in Tracking Plan](https://data.amplitude.com/asktia/Asktia/events/main/latest/Book%20an%20Appointment%20CTA%20from%20empty%20Recommended%20Appointments%20tab%20Clicked)
   *
   * This event will soon be deprecated. This event tracks when a user clicks on the "Book an Appointment" CTA from the empty state of the Recommended Appointments tab on dash of the portal.
   *
   *
   *
   *
   * Owner: suzy.roy@asktia.com
   *
   * @param options Amplitude event options.
   */
  bookAnAppointmentCtaFromEmptyRecommendedAppointmentsTabClicked(
    options?: EventOptions,
  ) {
    return this.track(new BookAnAppointmentCtaFromEmptyRecommendedAppointmentsTabClicked(), options);
  }

  /**
   * Book Appointment CTA Post Signup Clicked
   *
   * [View in Tracking Plan](https://data.amplitude.com/asktia/Asktia/events/main/latest/Book%20Appointment%20CTA%20Post%20Signup%20Clicked)
   *
   * When a user clicks on "Book Appointment" button right after completing payment and sign up
   *
   * Owner: joelle+tia@notablegrowth.com
   *
   * @param options Amplitude event options.
   */
  bookAppointmentCtaPostSignupClicked(
    options?: EventOptions,
  ) {
    return this.track(new BookAppointmentCtaPostSignupClicked(), options);
  }

  /**
   * Book now on Groups banner Clicked
   *
   * [View in Tracking Plan](https://data.amplitude.com/asktia/Asktia/events/main/latest/Book%20now%20on%20Groups%20banner%20Clicked)
   *
   * This event tracks when a user clicks on the "Book now" button on the MH Groups banner on member portal
   *
   * Owner: Kassio Khaleb
   *
   * @param options Amplitude event options.
   */
  bookNowOnGroupsBannerClicked(
    options?: EventOptions,
  ) {
    return this.track(new BookNowOnGroupsBannerClicked(), options);
  }

  /**
   * Booking Confirmation Cta Clicked
   *
   * [View in Tracking Plan](https://data.amplitude.com/asktia/Asktia/events/main/latest/Booking%20Confirmation%20Cta%20Clicked)
   *
   * This event tracks when a user clicks on the CTA to confirm their appointment on the last step of the booking flow in the portal
   *
   *
   *
   *
   * Owner: joelle+tia@notablegrowth.com
   *
   * @param properties The event's properties (e.g. appointmentDate)
   * @param options Amplitude event options.
   */
  bookingConfirmationCtaClicked(
    properties?: BookingConfirmationCtaClickedProperties,
    options?: EventOptions,
  ) {
    return this.track(new BookingConfirmationCtaClicked(properties), options);
  }

  /**
   * Booking Goal Selection Viewed
   *
   * [View in Tracking Plan](https://data.amplitude.com/asktia/Asktia/events/main/latest/Booking%20Goal%20Selection%20Viewed)
   *
   * This event tracks when a user views the booking goal selection page on the member portal
   *
   *
   *
   *
   * Owner: Swizec Teller
   *
   * @param properties The event's properties (e.g. appointmentProfile)
   * @param options Amplitude event options.
   */
  bookingGoalSelectionViewed(
    properties: BookingGoalSelectionViewedProperties,
    options?: EventOptions,
  ) {
    return this.track(new BookingGoalSelectionViewed(properties), options);
  }

  /**
   * Cancel Confirmed
   *
   * [View in Tracking Plan](https://data.amplitude.com/asktia/Asktia/events/main/latest/Cancel%20Confirmed)
   *
   * When a user clicks the CTA on the cancel confirmation screen in the member portal
   *
   *
   *
   *
   * Owner: joelle+tia@notablegrowth.com
   *
   * @param properties The event's properties (e.g. appointmentDate)
   * @param options Amplitude event options.
   */
  cancelConfirmed(
    properties?: CancelConfirmedProperties,
    options?: EventOptions,
  ) {
    return this.track(new CancelConfirmed(properties), options);
  }

  /**
   * Cancel Membership Clicked
   *
   * [View in Tracking Plan](https://data.amplitude.com/asktia/Asktia/events/main/latest/Cancel%20Membership%20Clicked)
   *
   * When member selects that they want to cancel and taps next to proceed to the cancellation reason screen in the portal
   *
   *
   *
   *
   * Owner: Ari Saft
   *
   * @param properties The event's properties (e.g. userID)
   * @param options Amplitude event options.
   */
  cancelMembershipClicked(
    properties: CancelMembershipClickedProperties,
    options?: EventOptions,
  ) {
    return this.track(new CancelMembershipClicked(properties), options);
  }

  /**
   * Cancel Membership Reasons Clicked
   *
   * [View in Tracking Plan](https://data.amplitude.com/asktia/Asktia/events/main/latest/Cancel%20Membership%20Reasons%20Clicked)
   *
   * When member selects a reason and then taps to message care coordinator
   *
   *
   *
   *
   * Owner: Ari Saft
   *
   * @param properties The event's properties (e.g. cancel_membership_reason)
   * @param options Amplitude event options.
   */
  cancelMembershipReasonsClicked(
    properties: CancelMembershipReasonsClickedProperties,
    options?: EventOptions,
  ) {
    return this.track(new CancelMembershipReasonsClicked(properties), options);
  }

  /**
   * Cancel Membership Reasons Viewed
   *
   * [View in Tracking Plan](https://data.amplitude.com/asktia/Asktia/events/main/latest/Cancel%20Membership%20Reasons%20Viewed)
   *
   * This event fires when a member views the Cancel Membership Reasons screen in the member portal.
   *
   *
   *
   *
   * Owner: Ari Saft
   *
   * @param properties The event's properties (e.g. userID)
   * @param options Amplitude event options.
   */
  cancelMembershipReasonsViewed(
    properties: CancelMembershipReasonsViewedProperties,
    options?: EventOptions,
  ) {
    return this.track(new CancelMembershipReasonsViewed(properties), options);
  }

  /**
   * Cancel Or Change Appointment Clicked
   *
   * [View in Tracking Plan](https://data.amplitude.com/asktia/Asktia/events/main/latest/Cancel%20Or%20Change%20Appointment%20Clicked)
   *
   * When cancel or change appointment on the appointment card is clicked.
   *
   * Owner: david+tia@notablegrowth.com
   *
   * @param properties The event's properties (e.g. appointmentDate)
   * @param options Amplitude event options.
   */
  cancelOrChangeAppointmentClicked(
    properties?: CancelOrChangeAppointmentClickedProperties,
    options?: EventOptions,
  ) {
    return this.track(new CancelOrChangeAppointmentClicked(properties), options);
  }

  /**
   * Cancel Or Change Appointment Response Clicked
   *
   * [View in Tracking Plan](https://data.amplitude.com/asktia/Asktia/events/main/latest/Cancel%20Or%20Change%20Appointment%20Response%20Clicked)
   *
   * The response the user clicks on the frontend to either
   *
   * * confirm their cancellation
   *
   * * reschedule
   *
   * * keep the appointment
   *
   * Owner: david+tia@notablegrowth.com
   *
   * @param properties The event's properties (e.g. appointmentDate)
   * @param options Amplitude event options.
   */
  cancelOrChangeAppointmentResponseClicked(
    properties?: CancelOrChangeAppointmentResponseClickedProperties,
    options?: EventOptions,
  ) {
    return this.track(new CancelOrChangeAppointmentResponseClicked(properties), options);
  }

  /**
   * Cancel or Change Membership Viewed
   *
   * [View in Tracking Plan](https://data.amplitude.com/asktia/Asktia/events/main/latest/Cancel%20or%20Change%20Membership%20Viewed)
   *
   * This event fires when a member views the page Cancel or Change Membership page in the portal
   *
   *
   *
   *
   * Owner: Ari Saft
   *
   * @param properties The event's properties (e.g. userID)
   * @param options Amplitude event options.
   */
  cancelOrChangeMembershipViewed(
    properties: CancelOrChangeMembershipViewedProperties,
    options?: EventOptions,
  ) {
    return this.track(new CancelOrChangeMembershipViewed(properties), options);
  }

  /**
   * Cancel Reason Selected
   *
   * [View in Tracking Plan](https://data.amplitude.com/asktia/Asktia/events/main/latest/Cancel%20Reason%20Selected)
   *
   * When a user selects reason why they are cancelling their scheduled appointment and clicks the CTA
   *
   *
   *
   *
   * Owner: joelle+tia@notablegrowth.com
   *
   * @param properties The event's properties (e.g. appointmentDate)
   * @param options Amplitude event options.
   */
  cancelReasonSelected(
    properties?: CancelReasonSelectedProperties,
    options?: EventOptions,
  ) {
    return this.track(new CancelReasonSelected(properties), options);
  }

  /**
   * Care Plan Details Viewed
   *
   * [View in Tracking Plan](https://data.amplitude.com/asktia/Asktia/events/main/latest/Care%20Plan%20Details%20Viewed)
   *
   * When member taps into a Care Plan item (aka intervention) to see the details
   *
   *
   *
   *
   * Owner: Ari Saft
   *
   * @param properties The event's properties (e.g. intervention)
   * @param options Amplitude event options.
   */
  carePlanDetailsViewed(
    properties: CarePlanDetailsViewedProperties,
    options?: EventOptions,
  ) {
    return this.track(new CarePlanDetailsViewed(properties), options);
  }

  /**
   * Care Plan Viewed
   *
   * [View in Tracking Plan](https://data.amplitude.com/asktia/Asktia/events/main/latest/Care%20Plan%20Viewed)
   *
   * This event fires when care plan overview page is viewed in the portal at https://member.asktia.com/r/your-care/plan
   *
   *
   *
   *
   * Owner: Ari Saft
   *
   * @param options Amplitude event options.
   */
  carePlanViewed(
    options?: EventOptions,
  ) {
    return this.track(new CarePlanViewed(), options);
  }

  /**
   * Care Type Browsed
   *
   * [View in Tracking Plan](https://data.amplitude.com/asktia/Asktia/events/main/latest/Care%20Type%20Browsed)
   *
   * When a user browses appointments by Care Type.
   *
   * Owner: david+tia@notablegrowth.com
   *
   * @param properties The event's properties (e.g. careType)
   * @param options Amplitude event options.
   */
  careTypeBrowsed(
    properties?: CareTypeBrowsedProperties,
    options?: EventOptions,
  ) {
    return this.track(new CareTypeBrowsed(properties), options);
  }

  /**
   * Cash pay confirmed
   *
   * [View in Tracking Plan](https://data.amplitude.com/asktia/Asktia/events/main/latest/Cash%20pay%20confirmed)
   *
   * This event is triggered when the user confirms they agree to pay the cash price if my insurance is not validated before their appointment at the insurance step of check-in.
   *
   *
   *
   *
   * Owner: Logan Tribull
   *
   * @param properties The event's properties (e.g. appointmentId)
   * @param options Amplitude event options.
   */
  cashPayConfirmed(
    properties?: CashPayConfirmedProperties,
    options?: EventOptions,
  ) {
    return this.track(new CashPayConfirmed(properties), options);
  }

  /**
   * Change Membership Clicked
   *
   * [View in Tracking Plan](https://data.amplitude.com/asktia/Asktia/events/main/latest/Change%20Membership%20Clicked)
   *
   * When member selects that they'd like to change their membership and taps to proceed to the message care coordinator screen
   *
   *
   *
   *
   * Owner: Ari Saft
   *
   * @param properties The event's properties (e.g. userID)
   * @param options Amplitude event options.
   */
  changeMembershipClicked(
    properties: ChangeMembershipClickedProperties,
    options?: EventOptions,
  ) {
    return this.track(new ChangeMembershipClicked(properties), options);
  }

  /**
   * Check In Card On File Completed
   *
   * [View in Tracking Plan](https://data.amplitude.com/asktia/Asktia/events/main/latest/Check%20In%20Card%20On%20File%20Completed)
   *
   * When a user completes the card on file step of check in by clicking the Next button
   *
   *
   *
   *
   * Owner: joelle+tia@notablegrowth.com
   *
   * @param properties The event's properties (e.g. appointmentDateAndTime)
   * @param options Amplitude event options.
   */
  checkInCardOnFileCompleted(
    properties?: CheckInCardOnFileCompletedProperties,
    options?: EventOptions,
  ) {
    return this.track(new CheckInCardOnFileCompleted(properties), options);
  }

  /**
   * Check In Completed
   *
   * [View in Tracking Plan](https://data.amplitude.com/asktia/Asktia/events/main/latest/Check%20In%20Completed)
   *
   * When a user is dropped into the waiting room after completing check-in in the member portal
   *
   *
   *
   *
   * Owner: joelle+tia@notablegrowth.com
   *
   * @param properties The event's properties (e.g. appointmentDateAndTime)
   * @param options Amplitude event options.
   */
  checkInCompleted(
    properties?: CheckInCompletedProperties,
    options?: EventOptions,
  ) {
    return this.track(new CheckInCompleted(properties), options);
  }

  /**
   * Check In Consent Completed
   *
   * [View in Tracking Plan](https://data.amplitude.com/asktia/Asktia/events/main/latest/Check%20In%20Consent%20Completed)
   *
   * This event fires when a user completes the consent step of check in by clicking the "I'm ready for my appointment" button
   *
   *
   *
   *
   * Owner: joelle+tia@notablegrowth.com
   *
   * @param properties The event's properties (e.g. appointmentDateAndTime)
   * @param options Amplitude event options.
   */
  checkInConsentCompleted(
    properties?: CheckInConsentCompletedProperties,
    options?: EventOptions,
  ) {
    return this.track(new CheckInConsentCompleted(properties), options);
  }

  /**
   * Check In Insurance Completed
   *
   * [View in Tracking Plan](https://data.amplitude.com/asktia/Asktia/events/main/latest/Check%20In%20Insurance%20Completed)
   *
   * This event tracks when a users completes the insurance step of ACI by clicking the Next button after answering the necessary questions
   *
   *
   *
   *
   * Owner: joelle+tia@notablegrowth.com
   *
   * @param properties The event's properties (e.g. appointmentDateAndTime)
   * @param options Amplitude event options.
   */
  checkInInsuranceCompleted(
    properties?: CheckInInsuranceCompletedProperties,
    options?: EventOptions,
  ) {
    return this.track(new CheckInInsuranceCompleted(properties), options);
  }

  /**
   * Check In Not Started
   *
   * [View in Tracking Plan](https://data.amplitude.com/asktia/Asktia/events/main/latest/Check%20In%20Not%20Started)
   *
   * When a user lands on the first step of check-in immediately after the animated transition screen
   *
   *
   *
   *
   * Owner: joelle+tia@notablegrowth.com
   *
   * @param properties The event's properties (e.g. appointmentDateAndTime)
   * @param options Amplitude event options.
   */
  checkInNotStarted(
    properties?: CheckInNotStartedProperties,
    options?: EventOptions,
  ) {
    return this.track(new CheckInNotStarted(properties), options);
  }

  /**
   * Check In Now Clicked
   *
   * [View in Tracking Plan](https://data.amplitude.com/asktia/Asktia/events/main/latest/Check%20In%20Now%20Clicked)
   *
   * When the user clicks check in now on an appointment they have booked.
   *
   * Owner: david+tia@notablegrowth.com
   *
   * @param properties The event's properties (e.g. appointmentDateAndTime)
   * @param options Amplitude event options.
   */
  checkInNowClicked(
    properties?: CheckInNowClickedProperties,
    options?: EventOptions,
  ) {
    return this.track(new CheckInNowClicked(properties), options);
  }

  /**
   * Check In Part 1 Completed
   *
   * [View in Tracking Plan](https://data.amplitude.com/asktia/Asktia/events/main/latest/Check%20In%20Part%201%20Completed)
   *
   * This event fires if a member books an appt greater than 24 hours out and finishes the first part of check in by clicking the "My payment info is correct" button
   *
   *
   *
   *
   * Owner: Ari Saft
   *
   * @param properties The event's properties (e.g. appointmentDateAndTime)
   * @param options Amplitude event options.
   */
  checkInPart1Completed(
    properties?: CheckInPart1CompletedProperties,
    options?: EventOptions,
  ) {
    return this.track(new CheckInPart1Completed(properties), options);
  }

  /**
   * Check In Part 2 Completed
   *
   * [View in Tracking Plan](https://data.amplitude.com/asktia/Asktia/events/main/latest/Check%20In%20Part%202%20Completed)
   *
   * This event fires when member lands on the raspberry screen before heading to waiting room after completing check in
   *
   *
   *
   *
   * Owner: Ari Saft
   *
   * @param properties The event's properties (e.g. appointmentDateAndTime)
   * @param options Amplitude event options.
   */
  checkInPart2Completed(
    properties?: CheckInPart2CompletedProperties,
    options?: EventOptions,
  ) {
    return this.track(new CheckInPart2Completed(properties), options);
  }

  /**
   * Check In Patient Info Completed
   *
   * [View in Tracking Plan](https://data.amplitude.com/asktia/Asktia/events/main/latest/Check%20In%20Patient%20Info%20Completed)
   *
   * This event tracks when a user completes the patient info step of ACI by clicking the Next button (My essential info is correct)
   *
   *
   *
   *
   * Owner: joelle+tia@notablegrowth.com
   *
   * @param properties The event's properties (e.g. appointmentDateAndTime)
   * @param options Amplitude event options.
   */
  checkInPatientInfoCompleted(
    properties?: CheckInPatientInfoCompletedProperties,
    options?: EventOptions,
  ) {
    return this.track(new CheckInPatientInfoCompleted(properties), options);
  }

  /**
   * Check In Pre Appointment Info Completed
   *
   * [View in Tracking Plan](https://data.amplitude.com/asktia/Asktia/events/main/latest/Check%20In%20Pre%20Appointment%20Info%20Completed)
   *
   * This event tracks when a user reaches the pre-appointment step of ACI by clicking Complete Checkin button
   *
   *
   *
   *
   * Owner: Ari Saft
   *
   * @param properties The event's properties (e.g. appointmentDateAndTime)
   * @param options Amplitude event options.
   */
  checkInPreAppointmentInfoCompleted(
    properties?: CheckInPreAppointmentInfoCompletedProperties,
    options?: EventOptions,
  ) {
    return this.track(new CheckInPreAppointmentInfoCompleted(properties), options);
  }

  /**
   * Check In Uhr Completed
   *
   * [View in Tracking Plan](https://data.amplitude.com/asktia/Asktia/events/main/latest/Check%20In%20Uhr%20Completed)
   *
   * This event fires when a user completes their UHR by clicking the "My health info is correct" button after all UHR sections are done
   *
   *
   *
   *
   * Owner: joelle+tia@notablegrowth.com
   *
   * @param properties The event's properties (e.g. appointmentDateAndTime)
   * @param options Amplitude event options.
   */
  checkInUhrCompleted(
    properties?: CheckInUhrCompletedProperties,
    options?: EventOptions,
  ) {
    return this.track(new CheckInUhrCompleted(properties), options);
  }

  /**
   * Click Announcements tab in Dashboard
   *
   * [View in Tracking Plan](https://data.amplitude.com/asktia/Asktia/events/main/latest/Click%20Announcements%20tab%20in%20Dashboard)
   *
   * This event tracks when a user clicks on the "Announcements" tab in the From Your Care team component on homepage of the portal
   *
   *
   *
   *
   * Owner: Swizec Teller
   *
   * @param options Amplitude event options.
   */
  clickAnnouncementsTabInDashboard(
    options?: EventOptions,
  ) {
    return this.track(new ClickAnnouncementsTabInDashboard(), options);
  }

  /**
   * Click Book an Appointment CTA from empty Your Care Plan tab
   *
   * [View in Tracking Plan](https://data.amplitude.com/asktia/Asktia/events/main/latest/Click%20Book%20an%20Appointment%20CTA%20from%20empty%20Your%20Care%20Plan%20tab)
   *
   * This event tracks when a user clicks on the "Book an appointment" button in the From Your Care team component when its in the empty state on homepage of the portal
   *
   *
   *
   *
   * Owner: suzy.roy@asktia.com
   *
   * @param options Amplitude event options.
   */
  clickBookAnAppointmentCtaFromEmptyYourCarePlanTab(
    options?: EventOptions,
  ) {
    return this.track(new ClickBookAnAppointmentCtaFromEmptyYourCarePlanTab(), options);
  }

  /**
   * Click Book Now in New Member Onboarding
   *
   * [View in Tracking Plan](https://data.amplitude.com/asktia/Asktia/events/main/latest/Click%20Book%20Now%20in%20New%20Member%20Onboarding)
   *
   * This event tracks when a user clicks on the Next button on the second step of the new member onboarding process after signing up
   *
   *
   *
   *
   * Owner: Logan Tribull
   *
   * @param options Amplitude event options.
   */
  clickBookNowInNewMemberOnboarding(
    options?: EventOptions,
  ) {
    return this.track(new ClickBookNowInNewMemberOnboarding(), options);
  }

  /**
   * Click Get Started in New Member Onboarding
   *
   * [View in Tracking Plan](https://data.amplitude.com/asktia/Asktia/events/main/latest/Click%20Get%20Started%20in%20New%20Member%20Onboarding)
   *
   * This event tracks when a user clicks on the Next button on the first page in the new member onboarding process after signing up
   *
   *
   *
   *
   * Owner: Logan Tribull
   *
   * @param options Amplitude event options.
   */
  clickGetStartedInNewMemberOnboarding(
    options?: EventOptions,
  ) {
    return this.track(new ClickGetStartedInNewMemberOnboarding(), options);
  }

  /**
   * Click Learn More on Care Plan item in Dashboard
   *
   * [View in Tracking Plan](https://data.amplitude.com/asktia/Asktia/events/main/latest/Click%20Learn%20More%20on%20Care%20Plan%20item%20in%20Dashboard)
   *
   * This event tracks when a user clicks on the "Learn More" link on a Care Plan item in the From Your Care team component on homepage of the portal
   *
   * \-
   *
   *
   *
   *
   * Owner: suzy.roy@asktia.com
   *
   * @param properties The event's properties (e.g. careplanInterventionUuid)
   * @param options Amplitude event options.
   */
  clickLearnMoreOnCarePlanItemInDashboard(
    properties?: ClickLearnMoreOnCarePlanItemInDashboardProperties,
    options?: EventOptions,
  ) {
    return this.track(new ClickLearnMoreOnCarePlanItemInDashboard(properties), options);
  }

  /**
   * Click MH Groups Notification Banner
   *
   * [View in Tracking Plan](https://data.amplitude.com/asktia/Asktia/events/main/latest/Click%20MH%20Groups%20Notification%20Banner)
   *
   * Owner: Logan Tribull
   *
   * @param options Amplitude event options.
   */
  clickMhGroupsNotificationBanner(
    options?: EventOptions,
  ) {
    return this.track(new ClickMhGroupsNotificationBanner(), options);
  }

  /**
   * Click Question about Care Plan
   *
   * [View in Tracking Plan](https://data.amplitude.com/asktia/Asktia/events/main/latest/Click%20Question%20about%20Care%20Plan)
   *
   * When member taps to ask a question on the main care plan page
   *
   * Owner: Ari Saft
   *
   * @param properties The event's properties (e.g. userID)
   * @param options Amplitude event options.
   */
  clickQuestionAboutCarePlan(
    properties: ClickQuestionAboutCarePlanProperties,
    options?: EventOptions,
  ) {
    return this.track(new ClickQuestionAboutCarePlan(properties), options);
  }

  /**
   * Click Recommended Appointments tab in Dashboard
   *
   * [View in Tracking Plan](https://data.amplitude.com/asktia/Asktia/events/main/latest/Click%20Recommended%20Appointments%20tab%20in%20Dashboard)
   *
   * This event tracks when a user clicks on the "Your Recommended Appointments" tab in the From Your Care Team component on the homepage
   *
   *
   *
   *
   * Owner: Swizec Teller
   *
   * @param options Amplitude event options.
   */
  clickRecommendedAppointmentsTabInDashboard(
    options?: EventOptions,
  ) {
    return this.track(new ClickRecommendedAppointmentsTabInDashboard(), options);
  }

  /**
   * Click Service Line Module on Appointments Page
   *
   * [View in Tracking Plan](https://data.amplitude.com/asktia/Asktia/events/main/latest/Click%20Service%20Line%20Module%20on%20Appointments%20Page)
   *
   * This event tracks when a user clicks on the service line module on the appointments page of the member portal
   *
   *
   *
   *
   *
   * Owner: Logan Tribull
   *
   * @param properties The event's properties (e.g. serviceLine)
   * @param options Amplitude event options.
   */
  clickServiceLineModuleOnAppointmentsPage(
    properties: ClickServiceLineModuleOnAppointmentsPageProperties,
    options?: EventOptions,
  ) {
    return this.track(new ClickServiceLineModuleOnAppointmentsPage(properties), options);
  }

  /**
   * Click Your Care Plan tab in Dashboard
   *
   * [View in Tracking Plan](https://data.amplitude.com/asktia/Asktia/events/main/latest/Click%20Your%20Care%20Plan%20tab%20in%20Dashboard)
   *
   * This event tracks when a user clicks on the "Your Care Plan" tab in the From Your Care Team component on the homepage
   *
   *
   *
   *
   * Owner: suzy.roy@asktia.com
   *
   * @param options Amplitude event options.
   */
  clickYourCarePlanTabInDashboard(
    options?: EventOptions,
  ) {
    return this.track(new ClickYourCarePlanTabInDashboard(), options);
  }

  /**
   * Clicked annual exam in recommended actions
   *
   * [View in Tracking Plan](https://data.amplitude.com/asktia/Asktia/events/main/latest/Clicked%20annual%20exam%20in%20recommended%20actions)
   *
   * This event is no longer firing. However, it used to track when a user clicked on the annual exam option on the initial visit reason screen in onboarding
   *
   * Owner: Logan Tribull
   *
   * @param options Amplitude event options.
   */
  clickedAnnualExamInRecommendedActions(
    options?: EventOptions,
  ) {
    return this.track(new ClickedAnnualExamInRecommendedActions(), options);
  }

  /**
   * Clicked health concern in recommended actions
   *
   * [View in Tracking Plan](https://data.amplitude.com/asktia/Asktia/events/main/latest/Clicked%20health%20concern%20in%20recommended%20actions)
   *
   * This event tracks when a user clicks on the health concern option within on the initial visit reason screen in onboarding
   *
   *
   *
   *
   * Owner: Logan Tribull
   *
   * @param options Amplitude event options.
   */
  clickedHealthConcernInRecommendedActions(
    options?: EventOptions,
  ) {
    return this.track(new ClickedHealthConcernInRecommendedActions(), options);
  }

  /**
   * Clicked Health Concern on Homepage
   *
   * [View in Tracking Plan](https://data.amplitude.com/asktia/Asktia/events/main/latest/Clicked%20Health%20Concern%20on%20Homepage)
   *
   * Event has no description in tracking plan.
   *
   * @param options Amplitude event options.
   */
  clickedHealthConcernOnHomepage(
    options?: EventOptions,
  ) {
    return this.track(new ClickedHealthConcernOnHomepage(), options);
  }

  /**
   * Clicked mental health support in recommended actions
   *
   * [View in Tracking Plan](https://data.amplitude.com/asktia/Asktia/events/main/latest/Clicked%20mental%20health%20support%20in%20recommended%20actions)
   *
   * This event tracks when a user clicks on the mental health option within on the initial visit reason screen in onboarding
   *
   *
   *
   *
   * Owner: Logan Tribull
   *
   * @param options Amplitude event options.
   */
  clickedMentalHealthSupportInRecommendedActions(
    options?: EventOptions,
  ) {
    return this.track(new ClickedMentalHealthSupportInRecommendedActions(), options);
  }

  /**
   * Clicked Mental Health Support on Homepage
   *
   * [View in Tracking Plan](https://data.amplitude.com/asktia/Asktia/events/main/latest/Clicked%20Mental%20Health%20Support%20on%20Homepage)
   *
   * Event has no description in tracking plan.
   *
   * @param options Amplitude event options.
   */
  clickedMentalHealthSupportOnHomepage(
    options?: EventOptions,
  ) {
    return this.track(new ClickedMentalHealthSupportOnHomepage(), options);
  }

  /**
   * Clicked on Other Immunization
   *
   * [View in Tracking Plan](https://data.amplitude.com/asktia/Asktia/events/main/latest/Clicked%20on%20Other%20Immunization)
   *
   * Event has no description in tracking plan.
   *
   * @param properties The event's properties (e.g. Checked)
   * @param options Amplitude event options.
   */
  clickedOnOtherImmunization(
    properties: ClickedOnOtherImmunizationProperties,
    options?: EventOptions,
  ) {
    return this.track(new ClickedOnOtherImmunization(properties), options);
  }

  /**
   * Clicked on Recommended Immunization
   *
   * [View in Tracking Plan](https://data.amplitude.com/asktia/Asktia/events/main/latest/Clicked%20on%20Recommended%20Immunization)
   *
   * Event has no description in tracking plan.
   *
   * @param properties The event's properties (e.g. Checked)
   * @param options Amplitude event options.
   */
  clickedOnRecommendedImmunization(
    properties: ClickedOnRecommendedImmunizationProperties,
    options?: EventOptions,
  ) {
    return this.track(new ClickedOnRecommendedImmunization(properties), options);
  }

  /**
   * Clicked recommended appointments in recommended actions
   *
   * [View in Tracking Plan](https://data.amplitude.com/asktia/Asktia/events/main/latest/Clicked%20recommended%20appointments%20in%20recommended%20actions)
   *
   * This event is no longer firing. However, it used to track when a user clicks on the Recommended Appts option on the initial visit reason screen in onboarding
   *
   * Owner: Logan Tribull
   *
   * @param options Amplitude event options.
   */
  clickedRecommendedAppointmentsInRecommendedActions(
    options?: EventOptions,
  ) {
    return this.track(new ClickedRecommendedAppointmentsInRecommendedActions(), options);
  }

  /**
   * Clicked Something Else in Recommended Actions
   *
   * [View in Tracking Plan](https://data.amplitude.com/asktia/Asktia/events/main/latest/Clicked%20Something%20Else%20in%20Recommended%20Actions)
   *
   * This event tracks when a user clicks on the "Something Else" button on the IVR screen of onboarding in the portal
   *
   *
   *
   *
   * @param options Amplitude event options.
   */
  clickedSomethingElseInRecommendedActions(
    options?: EventOptions,
  ) {
    return this.track(new ClickedSomethingElseInRecommendedActions(), options);
  }

  /**
   * Clicked Something Else on Homepage
   *
   * [View in Tracking Plan](https://data.amplitude.com/asktia/Asktia/events/main/latest/Clicked%20Something%20Else%20on%20Homepage)
   *
   * Event has no description in tracking plan.
   *
   * @param options Amplitude event options.
   */
  clickedSomethingElseOnHomepage(
    options?: EventOptions,
  ) {
    return this.track(new ClickedSomethingElseOnHomepage(), options);
  }

  /**
   * Clicked start care in recommended actions
   *
   * [View in Tracking Plan](https://data.amplitude.com/asktia/Asktia/events/main/latest/Clicked%20start%20care%20in%20recommended%20actions)
   *
   * This event tracks when a user clicks on the start care option on the initial visit reason screen in onboarding
   *
   *
   *
   *
   * Owner: Logan Tribull
   *
   * @param options Amplitude event options.
   */
  clickedStartCareInRecommendedActions(
    options?: EventOptions,
  ) {
    return this.track(new ClickedStartCareInRecommendedActions(), options);
  }

  /**
   * Clicked Start Care on Homepage
   *
   * [View in Tracking Plan](https://data.amplitude.com/asktia/Asktia/events/main/latest/Clicked%20Start%20Care%20on%20Homepage)
   *
   * Event has no description in tracking plan.
   *
   * @param options Amplitude event options.
   */
  clickedStartCareOnHomepage(
    options?: EventOptions,
  ) {
    return this.track(new ClickedStartCareOnHomepage(), options);
  }

  /**
   * Close MH Groups Notification Banner
   *
   * [View in Tracking Plan](https://data.amplitude.com/asktia/Asktia/events/main/latest/Close%20MH%20Groups%20Notification%20Banner)
   *
   * Owner: Logan Tribull
   *
   * @param options Amplitude event options.
   */
  closeMhGroupsNotificationBanner(
    options?: EventOptions,
  ) {
    return this.track(new CloseMhGroupsNotificationBanner(), options);
  }

  /**
   * Email Captured
   *
   * [View in Tracking Plan](https://data.amplitude.com/asktia/Asktia/events/main/latest/Email%20Captured)
   *
   * When email is captured, but a user is not signed up. This event can be used for landing pages like the Stress Quiz to set the user email with a UUID being set.
   *
   *
   * In the Membership Signup: [Github Link](https://github.com/AskTia/www/blob/0cb2be0c32e5b3a70fe6412c4449815080b9636a/controllers/account-controller.js#L137)
   *
   * In Stress Quiz: (Eric doesn't know where this lives)
   *
   * Owner: david+tia@notablegrowth.com
   *
   * @param properties The event's properties (e.g. email)
   * @param options Amplitude event options.
   */
  emailCaptured(
    properties?: EmailCapturedProperties,
    options?: EventOptions,
  ) {
    return this.track(new EmailCaptured(properties), options);
  }

  /**
   * Email Login Submitted
   *
   * [View in Tracking Plan](https://data.amplitude.com/asktia/Asktia/events/main/latest/Email%20Login%20Submitted)
   *
   * When the user submits their email via login to receive an emailed sign in link.
   *
   * Owner: david+tia@notablegrowth.com
   *
   * @param properties The event's properties (e.g. currentYear)
   * @param options Amplitude event options.
   */
  emailLoginSubmitted(
    properties?: EmailLoginSubmittedProperties,
    options?: EventOptions,
  ) {
    return this.track(new EmailLoginSubmitted(properties), options);
  }

  /**
   * Error Received
   *
   * [View in Tracking Plan](https://data.amplitude.com/asktia/Asktia/events/main/latest/Error%20Received)
   *
   * When a user receives an error.
   *
   * * During ACI - to connect with Care Team if something goes wrong
   *
   * * When picking an appointment time slot that has already been chosen
   *
   * * Health Record updated with unaccepted value
   *
   * Owner: david+tia@notablegrowth.com
   *
   * @param properties The event's properties (e.g. errorMessage)
   * @param options Amplitude event options.
   */
  errorReceived(
    properties?: ErrorReceivedProperties,
    options?: EventOptions,
  ) {
    return this.track(new ErrorReceived(properties), options);
  }

  /**
   * Event Rsvp
   *
   * [View in Tracking Plan](https://data.amplitude.com/asktia/Asktia/events/main/latest/Event%20Rsvp)
   *
   * When a user RSVP for an event on the events page
   *
   * Owner: joelle+tia@notablegrowth.com
   *
   * @param properties The event's properties (e.g. eventDateAndTime)
   * @param options Amplitude event options.
   */
  eventRsvp(
    properties?: EventRsvpProperties,
    options?: EventOptions,
  ) {
    return this.track(new EventRsvp(properties), options);
  }

  /**
   * Events CTA Clicked 
   *
   * [View in Tracking Plan](https://data.amplitude.com/asktia/Asktia/events/main/latest/Events%20CTA%20Clicked%20)
   *
   * When a user clicks on the "See all events" button at the bottom of the home page 
   *
   * Owner: joelle+tia@notablegrowth.com
   *
   * @param options Amplitude event options.
   */
  eventsCtaClicked(
    options?: EventOptions,
  ) {
    return this.track(new EventsCtaClicked(), options);
  }

  /**
   * Exit New Member Onboarding
   *
   * [View in Tracking Plan](https://data.amplitude.com/asktia/Asktia/events/main/latest/Exit%20New%20Member%20Onboarding)
   *
   * This event tracks when a new member clicks the X button to exit onboarding at any point during the process
   *
   *
   *
   *
   *
   * Owner: Logan Tribull
   *
   * @param properties The event's properties (e.g. exitPage)
   * @param options Amplitude event options.
   */
  exitNewMemberOnboarding(
    properties: ExitNewMemberOnboardingProperties,
    options?: EventOptions,
  ) {
    return this.track(new ExitNewMemberOnboarding(properties), options);
  }

  /**
   * Filter Appointments by Keyword
   *
   * [View in Tracking Plan](https://data.amplitude.com/asktia/Asktia/events/main/latest/Filter%20Appointments%20by%20Keyword)
   *
   * This event tracks when users filter the appointments page in the member portal selecting a symptom, feeling, or appointment in the search bar
   *
   *
   *
   *
   * Owner: Logan Tribull
   *
   * @param properties The event's properties (e.g. filterKeyword)
   * @param options Amplitude event options.
   */
  filterAppointmentsByKeyword(
    properties: FilterAppointmentsByKeywordProperties,
    options?: EventOptions,
  ) {
    return this.track(new FilterAppointmentsByKeyword(properties), options);
  }

  /**
   * Filter Appointments by Location
   *
   * [View in Tracking Plan](https://data.amplitude.com/asktia/Asktia/events/main/latest/Filter%20Appointments%20by%20Location)
   *
   * This event tracks when users filter the appointments page in the member portal by selecting a clinic from the dropdown filter
   *
   *
   *
   *
   * Owner: Swizec Teller
   *
   * @param properties The event's properties (e.g. locations)
   * @param options Amplitude event options.
   */
  filterAppointmentsByLocation(
    properties: FilterAppointmentsByLocationProperties,
    options?: EventOptions,
  ) {
    return this.track(new FilterAppointmentsByLocation(properties), options);
  }

  /**
   * Filter Appointments by Service Line
   *
   * [View in Tracking Plan](https://data.amplitude.com/asktia/Asktia/events/main/latest/Filter%20Appointments%20by%20Service%20Line)
   *
   * This event tracks when users filter the appointments page in the member portal by selecting a service line in the filter
   *
   *
   *
   *
   * Owner: Swizec Teller
   *
   * @param properties The event's properties (e.g. serviceLines)
   * @param options Amplitude event options.
   */
  filterAppointmentsByServiceLine(
    properties: FilterAppointmentsByServiceLineProperties,
    options?: EventOptions,
  ) {
    return this.track(new FilterAppointmentsByServiceLine(properties), options);
  }

  /**
   * Home Viewed
   *
   * [View in Tracking Plan](https://data.amplitude.com/asktia/Asktia/events/main/latest/Home%20Viewed)
   *
   * When a user views the homepage of the member portal at https://member.asktia.com/r/ 
   *
   * \-
   *
   *
   *
   *
   * Owner: joelle+tia@notablegrowth.com
   *
   * @param options Amplitude event options.
   */
  homeViewed(
    options?: EventOptions,
  ) {
    return this.track(new HomeViewed(), options);
  }

  /**
   * Inbox Viewed
   *
   * [View in Tracking Plan](https://data.amplitude.com/asktia/Asktia/events/main/latest/Inbox%20Viewed)
   *
   * When a user views their inbox. 
   *
   * Owner: david+tia@notablegrowth.com
   *
   * @param properties The event's properties (e.g. closedConversations)
   * @param options Amplitude event options.
   */
  inboxViewed(
    properties?: InboxViewedProperties,
    options?: EventOptions,
  ) {
    return this.track(new InboxViewed(properties), options);
  }

  /**
   * Insurance Image Sent
   *
   * [View in Tracking Plan](https://data.amplitude.com/asktia/Asktia/events/main/latest/Insurance%20Image%20Sent)
   *
   * This event fires when a user sends the picture of their insurance card in the member portal by clicking "Upload"
   *
   * https://github.com/AskTia/www/blob/de62680ef2b2539ab42e9d1ff0d53d28af867f85/controllers/account-controller.js#L248
   *
   *
   *
   *
   * Owner: david+tia@notablegrowth.com
   *
   * @param properties The event's properties (e.g. location)
   * @param options Amplitude event options.
   */
  insuranceImageSent(
    properties?: InsuranceImageSentProperties,
    options?: EventOptions,
  ) {
    return this.track(new InsuranceImageSent(properties), options);
  }

  /**
   * Insurance Selected
   *
   * [View in Tracking Plan](https://data.amplitude.com/asktia/Asktia/events/main/latest/Insurance%20Selected)
   *
   * User selects their insurance company
   *
   * Owner: david+tia@notablegrowth.com
   *
   * @param properties The event's properties (e.g. insuranceCompany)
   * @param options Amplitude event options.
   */
  insuranceSelected(
    properties?: InsuranceSelectedProperties,
    options?: EventOptions,
  ) {
    return this.track(new InsuranceSelected(properties), options);
  }

  /**
   * Join Now Button Clicked
   *
   * [View in Tracking Plan](https://data.amplitude.com/asktia/Asktia/events/main/latest/Join%20Now%20Button%20Clicked)
   *
   * When the user clicks the Join Now button *in the global nav bar* of asktia.com begin the sign up process. This event does not fire for other Join Now buttons on the marketing site
   *
   *
   *
   *
   * Owner: joelle+tia@notablegrowth.com
   *
   * @param options Amplitude event options.
   */
  joinNowButtonClicked(
    options?: EventOptions,
  ) {
    return this.track(new JoinNowButtonClicked(), options);
  }

  /**
   * Learn more on Groups banner Clicked
   *
   * [View in Tracking Plan](https://data.amplitude.com/asktia/Asktia/events/main/latest/Learn%20more%20on%20Groups%20banner%20Clicked)
   *
   * This event tracks when a user clicks on the "Learn more" link on the MH Groups banner on member portal
   *
   * Owner: Kassio Khaleb
   *
   * @param options Amplitude event options.
   */
  learnMoreOnGroupsBannerClicked(
    options?: EventOptions,
  ) {
    return this.track(new LearnMoreOnGroupsBannerClicked(), options);
  }

  /**
   * Logout Completed
   *
   * [View in Tracking Plan](https://data.amplitude.com/asktia/Asktia/events/main/latest/Logout%20Completed)
   *
   * When user click on the sign out button
   *
   * Owner: joelle+tia@notablegrowth.com
   *
   * @param options Amplitude event options.
   */
  logoutCompleted(
    options?: EventOptions,
  ) {
    return this.track(new LogoutCompleted(), options);
  }

  /**
   * Member Login Viewed
   *
   * [View in Tracking Plan](https://data.amplitude.com/asktia/Asktia/events/main/latest/Member%20Login%20Viewed)
   *
   * When a user visits the login page
   *
   * Owner: joelle+tia@notablegrowth.com
   *
   * @param options Amplitude event options.
   */
  memberLoginViewed(
    options?: EventOptions,
  ) {
    return this.track(new MemberLoginViewed(), options);
  }

  /**
   * Membership Plan Selected
   *
   * [View in Tracking Plan](https://data.amplitude.com/asktia/Asktia/events/main/latest/Membership%20Plan%20Selected)
   *
   * User selects the membership type
   *
   * Owner: david+tia@notablegrowth.com
   *
   * @param properties The event's properties (e.g. pricingModel)
   * @param options Amplitude event options.
   */
  membershipPlanSelected(
    properties?: MembershipPlanSelectedProperties,
    options?: EventOptions,
  ) {
    return this.track(new MembershipPlanSelected(properties), options);
  }

  /**
   * Nav Book Appointment Button Clicked
   *
   * [View in Tracking Plan](https://data.amplitude.com/asktia/Asktia/events/main/latest/Nav%20Book%20Appointment%20Button%20Clicked)
   *
   * This event tracks when a user clicks on the "Book Appointment" button in the navigation menu on the member portal
   *
   * \-
   *
   *
   *
   *
   * Owner: Logan Tribull
   *
   * @param properties The event's properties (e.g. path)
   * @param options Amplitude event options.
   */
  navBookAppointmentButtonClicked(
    properties: NavBookAppointmentButtonClickedProperties,
    options?: EventOptions,
  ) {
    return this.track(new NavBookAppointmentButtonClicked(properties), options);
  }

  /**
   * Navigated to Appointments Page
   *
   * [View in Tracking Plan](https://data.amplitude.com/asktia/Asktia/events/main/latest/Navigated%20to%20Appointments%20Page)
   *
   * This event fires when a user clicks on any CTA that navigates them to the Appointments page on the member portal.
   *
   *
   * Owner: Logan Tribull
   *
   * @param options Amplitude event options.
   */
  navigatedToAppointmentsPage(
    options?: EventOptions,
  ) {
    return this.track(new NavigatedToAppointmentsPage(), options);
  }

  /**
   * Not A Tia Member Clicked
   *
   * [View in Tracking Plan](https://data.amplitude.com/asktia/Asktia/events/main/latest/Not%20A%20Tia%20Member%20Clicked)
   *
   * When the user clicks the not a tia member link to sign up
   *
   * Owner: joelle+tia@notablegrowth.com
   *
   * @param options Amplitude event options.
   */
  notATiaMemberClicked(
    options?: EventOptions,
  ) {
    return this.track(new NotATiaMemberClicked(), options);
  }

  /**
   * Nps Modal Viewed
   *
   * [View in Tracking Plan](https://data.amplitude.com/asktia/Asktia/events/main/latest/Nps%20Modal%20Viewed)
   *
   * When the user views the NPS modal. If this is during a booking, please pass any information available regarding the appointment. Added Booking property group as needed.
   *
   * Owner: david+tia@notablegrowth.com
   *
   * @param properties The event's properties (e.g. appointmentDate)
   * @param options Amplitude event options.
   */
  npsModalViewed(
    properties?: NpsModalViewedProperties,
    options?: EventOptions,
  ) {
    return this.track(new NpsModalViewed(properties), options);
  }

  /**
   * Nps Submitted
   *
   * [View in Tracking Plan](https://data.amplitude.com/asktia/Asktia/events/main/latest/Nps%20Submitted)
   *
   * When a user submits an NPS response via the NPS Modal. If this is during a booking, please pass any information available regarding the appointment. Added Booking property group as needed.
   *
   * Owner: david+tia@notablegrowth.com
   *
   * @param properties The event's properties (e.g. appointmentDate)
   * @param options Amplitude event options.
   */
  npsSubmitted(
    properties?: NpsSubmittedProperties,
    options?: EventOptions,
  ) {
    return this.track(new NpsSubmitted(properties), options);
  }

  /**
   * On Demand Book CTA Clicked
   *
   * [View in Tracking Plan](https://data.amplitude.com/asktia/Asktia/events/main/latest/On%20Demand%20Book%20CTA%20Clicked)
   *
   * Owner: ophra.werde@asktia.com
   *
   * @param properties The event's properties (e.g. appointmentDate)
   * @param options Amplitude event options.
   */
  onDemandBookCtaClicked(
    properties?: OnDemandBookCtaClickedProperties,
    options?: EventOptions,
  ) {
    return this.track(new OnDemandBookCtaClicked(properties), options);
  }

  /**
   * On Demand Carousel Card Clicked
   *
   * [View in Tracking Plan](https://data.amplitude.com/asktia/Asktia/events/main/latest/On%20Demand%20Carousel%20Card%20Clicked)
   *
   * When an On Demand user clicks on the On Demand carousel cards
   *
   * Owner: ophra.werde@asktia.com
   *
   * @param properties The event's properties (e.g. appointmentDate)
   * @param options Amplitude event options.
   */
  onDemandCarouselCardClicked(
    properties?: OnDemandCarouselCardClickedProperties,
    options?: EventOptions,
  ) {
    return this.track(new OnDemandCarouselCardClicked(properties), options);
  }

  /**
   * On Demand Confirm Location Clicked
   *
   * [View in Tracking Plan](https://data.amplitude.com/asktia/Asktia/events/main/latest/On%20Demand%20Confirm%20Location%20Clicked)
   *
   * Owner: ophra.werde@asktia.com
   *
   * @param properties The event's properties (e.g. homeClinic)
   * @param options Amplitude event options.
   */
  onDemandConfirmLocationClicked(
    properties?: OnDemandConfirmLocationClickedProperties,
    options?: EventOptions,
  ) {
    return this.track(new OnDemandConfirmLocationClicked(properties), options);
  }

  /**
   * On Demand CTA Clicked
   *
   * [View in Tracking Plan](https://data.amplitude.com/asktia/Asktia/events/main/latest/On%20Demand%20CTA%20Clicked)
   *
   * When an On Demand user clicks the On Demand Care button
   *
   * Owner: ophra.werde@asktia.com
   *
   * @param properties The event's properties (e.g. memberSignUpDate)
   * @param options Amplitude event options.
   */
  onDemandCtaClicked(
    properties?: OnDemandCtaClickedProperties,
    options?: EventOptions,
  ) {
    return this.track(new OnDemandCtaClicked(properties), options);
  }

  /**
   * On Demand Displayed
   *
   * [View in Tracking Plan](https://data.amplitude.com/asktia/Asktia/events/main/latest/On%20Demand%20Displayed)
   *
   * Evaluate whether a user sees On Demand Care based on the Launch Darkly flag
   *
   * Owner: ophra.werde@asktia.com
   *
   * @param options Amplitude event options.
   */
  onDemandDisplayed(
    options?: EventOptions,
  ) {
    return this.track(new OnDemandDisplayed(), options);
  }

  /**
   * On Demand Enter Waiting Room CTA Clicked
   *
   * [View in Tracking Plan](https://data.amplitude.com/asktia/Asktia/events/main/latest/On%20Demand%20Enter%20Waiting%20Room%20CTA%20Clicked)
   *
   * Owner: ophra.werde@asktia.com
   *
   * @param options Amplitude event options.
   */
  onDemandEnterWaitingRoomCtaClicked(
    options?: EventOptions,
  ) {
    return this.track(new OnDemandEnterWaitingRoomCtaClicked(), options);
  }

  /**
   * On Demand Join Zoom CTA Clicked
   *
   * [View in Tracking Plan](https://data.amplitude.com/asktia/Asktia/events/main/latest/On%20Demand%20Join%20Zoom%20CTA%20Clicked)
   *
   * When an On Demand user clicks to enter the zoom room with a steadyMD provider
   *
   * Owner: ophra.werde@asktia.com
   *
   * @param options Amplitude event options.
   */
  onDemandJoinZoomCtaClicked(
    options?: EventOptions,
  ) {
    return this.track(new OnDemandJoinZoomCtaClicked(), options);
  }

  /**
   * On Demand on Appointment Card Clicked
   *
   * [View in Tracking Plan](https://data.amplitude.com/asktia/Asktia/events/main/latest/On%20Demand%20on%20Appointment%20Card%20Clicked)
   *
   * When an On Demand user clicks on an On Demand appointment from another Tia appointment booking card
   *
   * Owner: ophra.werde@asktia.com
   *
   * @param properties The event's properties (e.g. appointmentDate)
   * @param options Amplitude event options.
   */
  onDemandOnAppointmentCardClicked(
    properties?: OnDemandOnAppointmentCardClickedProperties,
    options?: EventOptions,
  ) {
    return this.track(new OnDemandOnAppointmentCardClicked(properties), options);
  }

  /**
   * On Demand Page Appointment Card Clicked
   *
   * [View in Tracking Plan](https://data.amplitude.com/asktia/Asktia/events/main/latest/On%20Demand%20Page%20Appointment%20Card%20Clicked)
   *
   * Owner: ophra.werde@asktia.com
   *
   * @param properties The event's properties (e.g. appointmentDate)
   * @param options Amplitude event options.
   */
  onDemandPageAppointmentCardClicked(
    properties?: OnDemandPageAppointmentCardClickedProperties,
    options?: EventOptions,
  ) {
    return this.track(new OnDemandPageAppointmentCardClicked(properties), options);
  }

  /**
   * Onboarding Explore All Other Appointments Clicked
   *
   * [View in Tracking Plan](https://data.amplitude.com/asktia/Asktia/events/main/latest/Onboarding%20Explore%20All%20Other%20Appointments%20Clicked)
   *
   * Event has no description in tracking plan.
   *
   * @param options Amplitude event options.
   */
  onboardingExploreAllOtherAppointmentsClicked(
    options?: EventOptions,
  ) {
    return this.track(new OnboardingExploreAllOtherAppointmentsClicked(), options);
  }

  /**
   * Onboarding Quiz Question Answered
   *
   * [View in Tracking Plan](https://data.amplitude.com/asktia/Asktia/events/main/latest/Onboarding%20Quiz%20Question%20Answered)
   *
   * Event has no description in tracking plan.
   *
   * @param properties The event's properties (e.g. answer)
   * @param options Amplitude event options.
   */
  onboardingQuizQuestionAnswered(
    properties: OnboardingQuizQuestionAnsweredProperties,
    options?: EventOptions,
  ) {
    return this.track(new OnboardingQuizQuestionAnswered(properties), options);
  }

  /**
   * Onboarding Quiz Viewed
   *
   * [View in Tracking Plan](https://data.amplitude.com/asktia/Asktia/events/main/latest/Onboarding%20Quiz%20Viewed)
   *
   * Event has no description in tracking plan.
   *
   * @param properties The event's properties (e.g. page)
   * @param options Amplitude event options.
   */
  onboardingQuizViewed(
    properties?: OnboardingQuizViewedProperties,
    options?: EventOptions,
  ) {
    return this.track(new OnboardingQuizViewed(properties), options);
  }

  /**
   * Onboarding Suggestion Clicked
   *
   * [View in Tracking Plan](https://data.amplitude.com/asktia/Asktia/events/main/latest/Onboarding%20Suggestion%20Clicked)
   *
   * Event has no description in tracking plan.
   *
   * @param options Amplitude event options.
   */
  onboardingSuggestionClicked(
    options?: EventOptions,
  ) {
    return this.track(new OnboardingSuggestionClicked(), options);
  }

  /**
   * Onboarding Suggestion No Thanks Clicked
   *
   * [View in Tracking Plan](https://data.amplitude.com/asktia/Asktia/events/main/latest/Onboarding%20Suggestion%20No%20Thanks%20Clicked)
   *
   * Event has no description in tracking plan.
   *
   * @param options Amplitude event options.
   */
  onboardingSuggestionNoThanksClicked(
    options?: EventOptions,
  ) {
    return this.track(new OnboardingSuggestionNoThanksClicked(), options);
  }

  /**
   * Onboarding Suggestion Page Viewed
   *
   * [View in Tracking Plan](https://data.amplitude.com/asktia/Asktia/events/main/latest/Onboarding%20Suggestion%20Page%20Viewed)
   *
   * Event has no description in tracking plan.
   *
   * @param properties The event's properties (e.g. appointmentProfileName)
   * @param options Amplitude event options.
   */
  onboardingSuggestionPageViewed(
    properties: OnboardingSuggestionPageViewedProperties,
    options?: EventOptions,
  ) {
    return this.track(new OnboardingSuggestionPageViewed(properties), options);
  }

  /**
   * Page Viewed
   *
   * [View in Tracking Plan](https://data.amplitude.com/asktia/Asktia/events/main/latest/Page%20Viewed)
   *
   * This event fires when a user views any webpage on the marketing site. We plan to deprecate this event in favor of the default Amplitude page event. This custom event appears to have been instrumented during Tia's transition from Mixpanel to Amplitude.
   *
   * Owner: david+tia@notablegrowth.com
   *
   * @param properties The event's properties (e.g. initial_referrer)
   * @param options Amplitude event options.
   */
  pageViewed(
    properties?: PageViewedProperties,
    options?: EventOptions,
  ) {
    return this.track(new PageViewed(properties), options);
  }

  /**
   * Payment Information Saved
   *
   * [View in Tracking Plan](https://data.amplitude.com/asktia/Asktia/events/main/latest/Payment%20Information%20Saved)
   *
   * When the user successfully updates their payment information in the Account Details screen by clicking My card information is correct.
   *
   *
   *
   *
   * Owner: david+tia@notablegrowth.com
   *
   * @param options Amplitude event options.
   */
  paymentInformationSaved(
    options?: EventOptions,
  ) {
    return this.track(new PaymentInformationSaved(), options);
  }

  /**
   * Payment Submitted
   *
   * [View in Tracking Plan](https://data.amplitude.com/asktia/Asktia/events/main/latest/Payment%20Submitted)
   *
   * The user submits payment after entering payment information.
   *
   * Owner: david+tia@notablegrowth.com
   *
   * @param options Amplitude event options.
   */
  paymentSubmitted(
    options?: EventOptions,
  ) {
    return this.track(new PaymentSubmitted(), options);
  }

  /**
   * Phone Number Member Login Entered
   *
   * [View in Tracking Plan](https://data.amplitude.com/asktia/Asktia/events/main/latest/Phone%20Number%20Member%20Login%20Entered)
   *
   * When a user enters their phone number to sign in
   *
   * Owner: joelle+tia@notablegrowth.com
   *
   * @param options Amplitude event options.
   */
  phoneNumberMemberLoginEntered(
    options?: EventOptions,
  ) {
    return this.track(new PhoneNumberMemberLoginEntered(), options);
  }

  /**
   * Post Question Selected
   *
   * [View in Tracking Plan](https://data.amplitude.com/asktia/Asktia/events/main/latest/Post%20Question%20Selected)
   *
   * When a user selects an option on the booking goal selection page by clicking one of the checkboxes
   *
   *
   *
   *
   * Owner: joelle+tia@notablegrowth.com
   *
   * @param properties The event's properties (e.g. answerSelected)
   * @param options Amplitude event options.
   */
  postQuestionSelected(
    properties?: PostQuestionSelectedProperties,
    options?: EventOptions,
  ) {
    return this.track(new PostQuestionSelected(properties), options);
  }

  /**
   * Post Question Submitted
   *
   * [View in Tracking Plan](https://data.amplitude.com/asktia/Asktia/events/main/latest/Post%20Question%20Submitted)
   *
   * When a user clicks next at the bottom of the booking goal selection page and submits their answer
   *
   *
   *
   *
   * Owner: joelle+tia@notablegrowth.com
   *
   * @param properties The event's properties (e.g. answerSelected)
   * @param options Amplitude event options.
   */
  postQuestionSubmitted(
    properties?: PostQuestionSubmittedProperties,
    options?: EventOptions,
  ) {
    return this.track(new PostQuestionSubmitted(properties), options);
  }

  /**
   * Pre Question Selected
   *
   * [View in Tracking Plan](https://data.amplitude.com/asktia/Asktia/events/main/latest/Pre%20Question%20Selected)
   *
   * When a user selects an option in the pre question page
   *
   * Owner: joelle+tia@notablegrowth.com
   *
   * @param properties The event's properties (e.g. answerSelected)
   * @param options Amplitude event options.
   */
  preQuestionSelected(
    properties?: PreQuestionSelectedProperties,
    options?: EventOptions,
  ) {
    return this.track(new PreQuestionSelected(properties), options);
  }

  /**
   * Pre Question Submitted
   *
   * [View in Tracking Plan](https://data.amplitude.com/asktia/Asktia/events/main/latest/Pre%20Question%20Submitted)
   *
   * When a user clicks next and submits their answer in the pre question page
   *
   * Owner: joelle+tia@notablegrowth.com
   *
   * @param properties The event's properties (e.g. answerSelected)
   * @param options Amplitude event options.
   */
  preQuestionSubmitted(
    properties?: PreQuestionSubmittedProperties,
    options?: EventOptions,
  ) {
    return this.track(new PreQuestionSubmitted(properties), options);
  }

  /**
   * Pre Slot Search Location Selected
   *
   * [View in Tracking Plan](https://data.amplitude.com/asktia/Asktia/events/main/latest/Pre%20Slot%20Search%20Location%20Selected)
   *
   * Event indicating that a user has selected a location while searching for available time slots
   *
   * @param properties The event's properties (e.g. selectedLocation)
   * @param options Amplitude event options.
   */
  preSlotSearchLocationSelected(
    properties: PreSlotSearchLocationSelectedProperties,
    options?: EventOptions,
  ) {
    return this.track(new PreSlotSearchLocationSelected(properties), options);
  }

  /**
   * Prepare For Appointment Intake Viewed
   *
   * [View in Tracking Plan](https://data.amplitude.com/asktia/Asktia/events/main/latest/Prepare%20For%20Appointment%20Intake%20Viewed)
   *
   * When a user views the prepare for appointment intake 
   *
   * Owner: joelle+tia@notablegrowth.com
   *
   * @param properties The event's properties (e.g. appointmentDateAndTime)
   * @param options Amplitude event options.
   */
  prepareForAppointmentIntakeViewed(
    properties?: PrepareForAppointmentIntakeViewedProperties,
    options?: EventOptions,
  ) {
    return this.track(new PrepareForAppointmentIntakeViewed(properties), options);
  }

  /**
   * Profile Clicked
   *
   * [View in Tracking Plan](https://data.amplitude.com/asktia/Asktia/events/main/latest/Profile%20Clicked)
   *
   * When the user clicks their Profile in the top navigation. 
   *
   * Owner: david+tia@notablegrowth.com
   *
   * @param options Amplitude event options.
   */
  profileClicked(
    options?: EventOptions,
  ) {
    return this.track(new ProfileClicked(), options);
  }

  /**
   * Question about Care Plan Details Clicked
   *
   * [View in Tracking Plan](https://data.amplitude.com/asktia/Asktia/events/main/latest/Question%20about%20Care%20Plan%20Details%20Clicked)
   *
   * when member taps to ask question on the details page
   *
   * Owner: Ari Saft
   *
   * @param properties The event's properties (e.g. intervention)
   * @param options Amplitude event options.
   */
  questionAboutCarePlanDetailsClicked(
    properties: QuestionAboutCarePlanDetailsClickedProperties,
    options?: EventOptions,
  ) {
    return this.track(new QuestionAboutCarePlanDetailsClicked(properties), options);
  }

  /**
   * Registration Started
   *
   * [View in Tracking Plan](https://data.amplitude.com/asktia/Asktia/events/main/latest/Registration%20Started)
   *
   * User starts the registration process by clicking into any of the forms on the registration flow.
   *
   *
   * User also starts process from the login page
   *
   * Owner: david+tia@notablegrowth.com
   *
   * @param properties The event's properties (e.g. page)
   * @param options Amplitude event options.
   */
  registrationStarted(
    properties?: RegistrationStartedProperties,
    options?: EventOptions,
  ) {
    return this.track(new RegistrationStarted(properties), options);
  }

  /**
   * Removed Immunization
   *
   * [View in Tracking Plan](https://data.amplitude.com/asktia/Asktia/events/main/latest/Removed%20Immunization)
   *
   * Event has no description in tracking plan.
   *
   * @param options Amplitude event options.
   */
  removedImmunization(
    options?: EventOptions,
  ) {
    return this.track(new RemovedImmunization(), options);
  }

  /**
   * resume signup banner cta clicked
   *
   * [View in Tracking Plan](https://data.amplitude.com/asktia/Asktia/events/main/latest/resume%20signup%20banner%20cta%20clicked)
   *
   * Event has no description in tracking plan.
   *
   * @param options Amplitude event options.
   */
  resumeSignupBannerCtaClicked(
    options?: EventOptions,
  ) {
    return this.track(new ResumeSignupBannerCtaClicked(), options);
  }

  /**
   * resume signup banner cta rendered
   *
   * [View in Tracking Plan](https://data.amplitude.com/asktia/Asktia/events/main/latest/resume%20signup%20banner%20cta%20rendered)
   *
   * Event has no description in tracking plan.
   *
   * @param options Amplitude event options.
   */
  resumeSignupBannerCtaRendered(
    options?: EventOptions,
  ) {
    return this.track(new ResumeSignupBannerCtaRendered(), options);
  }

  /**
   * resume signup book appointment modal rendered
   *
   * [View in Tracking Plan](https://data.amplitude.com/asktia/Asktia/events/main/latest/resume%20signup%20book%20appointment%20modal%20rendered)
   *
   * Event has no description in tracking plan.
   *
   * @param options Amplitude event options.
   */
  resumeSignupBookAppointmentModalRendered(
    options?: EventOptions,
  ) {
    return this.track(new ResumeSignupBookAppointmentModalRendered(), options);
  }

  /**
   * resume signup modal cta clicked
   *
   * [View in Tracking Plan](https://data.amplitude.com/asktia/Asktia/events/main/latest/resume%20signup%20modal%20cta%20clicked)
   *
   * Event has no description in tracking plan.
   *
   * @param options Amplitude event options.
   */
  resumeSignupModalCtaClicked(
    options?: EventOptions,
  ) {
    return this.track(new ResumeSignupModalCtaClicked(), options);
  }

  /**
   * resume signup modal cta rendered
   *
   * [View in Tracking Plan](https://data.amplitude.com/asktia/Asktia/events/main/latest/resume%20signup%20modal%20cta%20rendered)
   *
   * Event has no description in tracking plan.
   *
   * @param options Amplitude event options.
   */
  resumeSignupModalCtaRendered(
    options?: EventOptions,
  ) {
    return this.track(new ResumeSignupModalCtaRendered(), options);
  }

  /**
   * Search Imunization
   *
   * [View in Tracking Plan](https://data.amplitude.com/asktia/Asktia/events/main/latest/Search%20Imunization)
   *
   * Event has no description in tracking plan.
   *
   * @param properties The event's properties (e.g. AmountOfImmunizationsFound)
   * @param options Amplitude event options.
   */
  searchImunization(
    properties: SearchImunizationProperties,
    options?: EventOptions,
  ) {
    return this.track(new SearchImunization(properties), options);
  }

  /**
   * See More Options Clicked
   *
   * [View in Tracking Plan](https://data.amplitude.com/asktia/Asktia/events/main/latest/See%20More%20Options%20Clicked)
   *
   * When the user clicks "See more options" to reveal additional dates & times when viewing the appointment card
   *
   *
   *
   *
   * Owner: joelle+tia@notablegrowth.com
   *
   * @param properties The event's properties (e.g. appointmentGoal)
   * @param options Amplitude event options.
   */
  seeMoreOptionsClicked(
    properties: SeeMoreOptionsClickedProperties,
    options?: EventOptions,
  ) {
    return this.track(new SeeMoreOptionsClicked(properties), options);
  }

  /**
   * Select A New Appointment Time Clicked
   *
   * [View in Tracking Plan](https://data.amplitude.com/asktia/Asktia/events/main/latest/Select%20A%20New%20Appointment%20Time%20Clicked)
   *
   * When the user selects an appointment time that was no longer available, sees the rejection modal, and then clicks "Select A New Appointment Time".
   *
   * Owner: david+tia@notablegrowth.com
   *
   * @param options Amplitude event options.
   */
  selectANewAppointmentTimeClicked(
    options?: EventOptions,
  ) {
    return this.track(new SelectANewAppointmentTimeClicked(), options);
  }

  /**
   * Select Appointment Viewed
   *
   * [View in Tracking Plan](https://data.amplitude.com/asktia/Asktia/events/main/latest/Select%20Appointment%20Viewed)
   *
   * When the user views the appointment filter page after clicking "View More Options" on any given appointment.
   *
   *
   *
   *
   * Owner: david+tia@notablegrowth.com
   *
   * @param properties The event's properties (e.g. appointmentProfile)
   * @param options Amplitude event options.
   */
  selectAppointmentViewed(
    properties?: SelectAppointmentViewedProperties,
    options?: EventOptions,
  ) {
    return this.track(new SelectAppointmentViewed(properties), options);
  }

  /**
   * Select Pay Current Invoice
   *
   * [View in Tracking Plan](https://data.amplitude.com/asktia/Asktia/events/main/latest/Select%20Pay%20Current%20Invoice)
   *
   * Event has no description in tracking plan.
   *
   * @param properties The event's properties (e.g. invoiceAmount)
   * @param options Amplitude event options.
   */
  selectPayCurrentInvoice(
    properties: SelectPayCurrentInvoiceProperties,
    options?: EventOptions,
  ) {
    return this.track(new SelectPayCurrentInvoice(properties), options);
  }

  /**
   * Selected Last Provider Slots
   *
   * [View in Tracking Plan](https://data.amplitude.com/asktia/Asktia/events/main/latest/Selected%20Last%20Provider%20Slots)
   *
   * Event has no description in tracking plan.
   *
   * @param options Amplitude event options.
   */
  selectedLastProviderSlots(
    options?: EventOptions,
  ) {
    return this.track(new SelectedLastProviderSlots(), options);
  }

  /**
   * Settings Viewed
   *
   * [View in Tracking Plan](https://data.amplitude.com/asktia/Asktia/events/main/latest/Settings%20Viewed)
   *
   * When a user views settings
   *
   * Owner: joelle+tia@notablegrowth.com
   *
   * @param options Amplitude event options.
   */
  settingsViewed(
    options?: EventOptions,
  ) {
    return this.track(new SettingsViewed(), options);
  }

  /**
   * Sign In With Email Clicked
   *
   * [View in Tracking Plan](https://data.amplitude.com/asktia/Asktia/events/main/latest/Sign%20In%20With%20Email%20Clicked)
   *
   * When someone clicks signin with email during login
   *
   * Owner: david+tia@notablegrowth.com
   *
   * @param options Amplitude event options.
   */
  signInWithEmailClicked(
    options?: EventOptions,
  ) {
    return this.track(new SignInWithEmailClicked(), options);
  }

  /**
   * Skip No Insurance Selected
   *
   * [View in Tracking Plan](https://data.amplitude.com/asktia/Asktia/events/main/latest/Skip%20No%20Insurance%20Selected)
   *
   * When the user has completed their initial registration and selects the "Next" button after either skipping insurance, or selecting no insurance.
   *
   * Owner: joelle+tia@notablegrowth.com
   *
   * @param properties The event's properties (e.g. insuranceSelection)
   * @param options Amplitude event options.
   */
  skipNoInsuranceSelected(
    properties?: SkipNoInsuranceSelectedProperties,
    options?: EventOptions,
  ) {
    return this.track(new SkipNoInsuranceSelected(properties), options);
  }

  /**
   * Start TWHE Journey
   *
   * [View in Tracking Plan](https://data.amplitude.com/asktia/Asktia/events/main/latest/Start%20TWHE%20Journey)
   *
   * This event fires when new member taps the primary CTA to start the TWHE journey on the TWHE banner in the homepage
   *
   *
   *
   *
   * Owner: Ari Saft
   *
   * @param properties The event's properties (e.g. userID)
   * @param options Amplitude event options.
   */
  startTwheJourney(
    properties: StartTwheJourneyProperties,
    options?: EventOptions,
  ) {
    return this.track(new StartTwheJourney(properties), options);
  }

  /**
   * Stress Quiz Completed
   *
   * [View in Tracking Plan](https://data.amplitude.com/asktia/Asktia/events/main/latest/Stress%20Quiz%20Completed)
   *
   * When the stress quiz is completed. 
   *
   * Owner: david+tia@notablegrowth.com
   *
   * @param properties The event's properties (e.g. arousal)
   * @param options Amplitude event options.
   */
  stressQuizCompleted(
    properties?: StressQuizCompletedProperties,
    options?: EventOptions,
  ) {
    return this.track(new StressQuizCompleted(properties), options);
  }

  /**
   * Stress Quiz Started
   *
   * [View in Tracking Plan](https://data.amplitude.com/asktia/Asktia/events/main/latest/Stress%20Quiz%20Started)
   *
   * Trigger when stress quiz is started, after the email is captured.
   *
   * Owner: david+tia@notablegrowth.com
   *
   * @param options Amplitude event options.
   */
  stressQuizStarted(
    options?: EventOptions,
  ) {
    return this.track(new StressQuizStarted(), options);
  }

  /**
   * Thread Viewed
   *
   * [View in Tracking Plan](https://data.amplitude.com/asktia/Asktia/events/main/latest/Thread%20Viewed)
   *
   * When a user views a message thread.
   *
   * Owner: david+tia@notablegrowth.com
   *
   * @param properties The event's properties (e.g. tag)
   * @param options Amplitude event options.
   */
  threadViewed(
    properties: ThreadViewedProperties,
    options?: EventOptions,
  ) {
    return this.track(new ThreadViewed(properties), options);
  }

  /**
   * Two Factor Code Entered
   *
   * [View in Tracking Plan](https://data.amplitude.com/asktia/Asktia/events/main/latest/Two%20Factor%20Code%20Entered)
   *
   * When the user enters and submits the two-factor authentication code
   *
   * * At registration
   *
   * * At login
   *
   * Owner: david+tia@notablegrowth.com
   *
   * @param options Amplitude event options.
   */
  twoFactorCodeEntered(
    options?: EventOptions,
  ) {
    return this.track(new TwoFactorCodeEntered(), options);
  }

  /**
   * Uhr Viewed
   *
   * [View in Tracking Plan](https://data.amplitude.com/asktia/Asktia/events/main/latest/Uhr%20Viewed)
   *
   * When the user views their Universal Health Record page in the portal at https://member.asktia.com/r/your-care/health-record
   *
   *
   *
   *
   * Owner: david+tia@notablegrowth.com
   *
   * @param properties The event's properties (e.g. healthRecordPercentageCompleted)
   * @param options Amplitude event options.
   */
  uhrViewed(
    properties?: UhrViewedProperties,
    options?: EventOptions,
  ) {
    return this.track(new UhrViewed(properties), options);
  }

  /**
   * Upcoming Appointment Card Clicked
   *
   * [View in Tracking Plan](https://data.amplitude.com/asktia/Asktia/events/main/latest/Upcoming%20Appointment%20Card%20Clicked)
   *
   * When a user clicks on the button on an upcoming card at the top of the page in any state.
   *
   *
   *
   *
   * Owner: joelle+tia@notablegrowth.com
   *
   * @param properties The event's properties (e.g. appointmentDateAndTime)
   * @param options Amplitude event options.
   */
  upcomingAppointmentCardClicked(
    properties?: UpcomingAppointmentCardClickedProperties,
    options?: EventOptions,
  ) {
    return this.track(new UpcomingAppointmentCardClicked(properties), options);
  }

  /**
   * Update Appointment Viewed
   *
   * [View in Tracking Plan](https://data.amplitude.com/asktia/Asktia/events/main/latest/Update%20Appointment%20Viewed)
   *
   * When a user views the cancel or change appointment page
   *
   *
   *
   *
   * Owner: joelle+tia@notablegrowth.com
   *
   * @param properties The event's properties (e.g. appointmentDate)
   * @param options Amplitude event options.
   */
  updateAppointmentViewed(
    properties?: UpdateAppointmentViewedProperties,
    options?: EventOptions,
  ) {
    return this.track(new UpdateAppointmentViewed(properties), options);
  }

  /**
   * Update Insurance Banner Clicked
   *
   * [View in Tracking Plan](https://data.amplitude.com/asktia/Asktia/events/main/latest/Update%20Insurance%20Banner%20Clicked)
   *
   * This event tracks when a user clicks on the "Update Insurance" banner that appears when they don't have verified insurance
   *
   * @param options Amplitude event options.
   */
  updateInsuranceBannerClicked(
    options?: EventOptions,
  ) {
    return this.track(new UpdateInsuranceBannerClicked(), options);
  }

  /**
   * Update Payment Information Clicked
   *
   * [View in Tracking Plan](https://data.amplitude.com/asktia/Asktia/events/main/latest/Update%20Payment%20Information%20Clicked)
   *
   * When a user clicks to update their payment information in their profile settings.
   *
   * Owner: david+tia@notablegrowth.com
   *
   * @param options Amplitude event options.
   */
  updatePaymentInformationClicked(
    options?: EventOptions,
  ) {
    return this.track(new UpdatePaymentInformationClicked(), options);
  }

  /**
   * View Appointment Billing
   *
   * [View in Tracking Plan](https://data.amplitude.com/asktia/Asktia/events/main/latest/View%20Appointment%20Billing)
   *
   * Event has no description in tracking plan.
   *
   * @param properties The event's properties (e.g. invoiceAmount)
   * @param options Amplitude event options.
   */
  viewAppointmentBilling(
    properties?: ViewAppointmentBillingProperties,
    options?: EventOptions,
  ) {
    return this.track(new ViewAppointmentBilling(properties), options);
  }

  /**
   * View Appointment Billing Details
   *
   * [View in Tracking Plan](https://data.amplitude.com/asktia/Asktia/events/main/latest/View%20Appointment%20Billing%20Details)
   *
   * Event has no description in tracking plan.
   *
   * @param properties The event's properties (e.g. collectable)
   * @param options Amplitude event options.
   */
  viewAppointmentBillingDetails(
    properties: ViewAppointmentBillingDetailsProperties,
    options?: EventOptions,
  ) {
    return this.track(new ViewAppointmentBillingDetails(properties), options);
  }

  /**
   * View New Member Onboarding
   *
   * [View in Tracking Plan](https://data.amplitude.com/asktia/Asktia/events/main/latest/View%20New%20Member%20Onboarding)
   *
   * This event tracks when a new member lands on the first screen of onboarding after joining as a new member
   *
   *
   *
   *
   * Owner: Monica Chin
   *
   * @param options Amplitude event options.
   */
  viewNewMemberOnboarding(
    options?: EventOptions,
  ) {
    return this.track(new ViewNewMemberOnboarding(), options);
  }

  /**
   * View Your Care Plan Clicked on Home
   *
   * [View in Tracking Plan](https://data.amplitude.com/asktia/Asktia/events/main/latest/View%20Your%20Care%20Plan%20Clicked%20on%20Home)
   *
   * Event has no description in tracking plan.
   *
   * @param options Amplitude event options.
   */
  viewYourCarePlanClickedOnHome(
    options?: EventOptions,
  ) {
    return this.track(new ViewYourCarePlanClickedOnHome(), options);
  }

  /**
   * Viewed Last Provider Slots
   *
   * [View in Tracking Plan](https://data.amplitude.com/asktia/Asktia/events/main/latest/Viewed%20Last%20Provider%20Slots)
   *
   * Event has no description in tracking plan.
   *
   * @param properties The event's properties (e.g. amountOfProviders)
   * @param options Amplitude event options.
   */
  viewedLastProviderSlots(
    properties: ViewedLastProviderSlotsProperties,
    options?: EventOptions,
  ) {
    return this.track(new ViewedLastProviderSlots(properties), options);
  }

  /**
   * Whole Health Service Page Viewed
   *
   * [View in Tracking Plan](https://data.amplitude.com/asktia/Asktia/events/main/latest/Whole%20Health%20Service%20Page%20Viewed)
   *
   * when a member views the whole health service page
   *
   * Owner: ari.weiss@asktia.com
   *
   * @param options Amplitude event options.
   */
  wholeHealthServicePageViewed(
    options?: EventOptions,
  ) {
    return this.track(new WholeHealthServicePageViewed(), options);
  }

  /**
   * Widget Insurance Type Selected
   *
   * [View in Tracking Plan](https://data.amplitude.com/asktia/Asktia/events/main/latest/Widget%20Insurance%20Type%20Selected)
   *
   * **Event to track when a user selects a type of insurance for a widget on the websiteOn the first lead form step, what option is selected (I have insurance, Medicaid, I dont have, I am not sure)**
   *
   * @param properties The event's properties (e.g. previousSelectedType)
   * @param options Amplitude event options.
   */
  widgetInsuranceTypeSelected(
    properties: WidgetInsuranceTypeSelectedProperties,
    options?: EventOptions,
  ) {
    return this.track(new WidgetInsuranceTypeSelected(properties), options);
  }

  /**
   * Widget Pricing Step Viewed
   *
   * [View in Tracking Plan](https://data.amplitude.com/asktia/Asktia/events/main/latest/Widget%20Pricing%20Step%20Viewed)
   *
   * **Event to track when a user views the pricing step for a widget on the web platform**
   *
   * @param options Amplitude event options.
   */
  widgetPricingStepViewed(
    options?: EventOptions,
  ) {
    return this.track(new WidgetPricingStepViewed(), options);
  }

  /**
   * Your Care Viewed
   *
   * [View in Tracking Plan](https://data.amplitude.com/asktia/Asktia/events/main/latest/Your%20Care%20Viewed)
   *
   * When a member views the your care page in the portal
   *
   * Owner: ari.weiss@asktia.com
   *
   * @param options Amplitude event options.
   */
  yourCareViewed(
    options?: EventOptions,
  ) {
    return this.track(new YourCareViewed(), options);
  }
}

export const ampli = new Ampli()

// BASE TYPES
type BrowserOptions = amplitude.Types.BrowserOptions

export type BrowserClient = amplitude.Types.BrowserClient
export type BaseEvent = amplitude.Types.BaseEvent
export type IdentifyEvent = amplitude.Types.IdentifyEvent
export type GroupEvent = amplitude.Types.GroupIdentifyEvent
export type Event = amplitude.Types.Event
export type EventOptions = amplitude.Types.EventOptions
export type Result = amplitude.Types.Result
