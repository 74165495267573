import { Blocks, Box, Container, Flex, Heading } from '@asktia/tia-ui'
import { FC, ReactNode, useEffect } from 'react'
import { Header } from 'src/components/Blocks'
import { View } from 'src/components/Blocks/View'
import { PaymentWarningBanner } from 'src/components/PaymentWarningBanner'
import { SuggestedAppointmentsBanner } from 'src/flows/AppointmentBooking/SuggestedAppointmentsBanner'
import { useAmpli } from 'src/hooks'
import { ServiceLines } from './CareTypes'
import { Offerings } from './Offerings'
import { useQueries } from 'react-query'
import { getCareTypes } from 'src/flows/AppointmentBooking/useCareTypes'
import { getCurrentUser, useUserInfo } from 'src/hooks/useUserInfo'
import { fetchOfferings } from 'src/hooks/useOfferings'
import { Navigate } from 'react-router'
import { useUpcomingAppointments } from 'src/hooks/useUpcomingAppointments'
import { useAppointmentHistory } from 'src/hooks/useAppointmentHistory'
import { useSearchParams } from 'react-router-dom'
import TiaIcon from '../../assets/tia-icon-without-ball.svg'
import { motion, MotionStyle, AnimatePresence } from 'framer-motion'
import { Element as ScrollTarget } from 'react-scroll'
import { InsuranceWarningBanner } from 'src/components/InsuranceWarningBanner'
import { ActionsToComplete } from './ActionsToComplete'
import { OnboardingRecommendedActions } from './OnboardingRecommendedActions'
import { PredictablePaymentsFooter } from 'src/pages/Home/PredictablePaymentsFooter'
import { useAmpliFeatureFlag } from 'src/AmplitudeExperimentProvider'
import { useModal } from 'react-modal-hook'
import { PreviewExplanation } from 'src/components/Preview/PreviewExplanation'

const SEASONAL_PROFILES = ['flu_or_cold', 'flu_shot', 'rash_or_skin_changes']

/**
 * list of queries we know the homepage needs
 * a hacky solution to preloading a bunch of queries
 *
 * ideally we could solve this with Suspense
 * but
 * - RQ Suspense is experimental
 * - RR v6.4 requires big changes to how routes are defined
 * - the new TanStack router is very new
 */
const homepageQueries = [
    {
        queryKey: ['care-types'],
        queryFn: getCareTypes
    },
    {
        queryKey: ['current-user'],
        queryFn: getCurrentUser
    },
    {
        queryKey: ['offerings', SEASONAL_PROFILES],
        queryFn: fetchOfferings(SEASONAL_PROFILES)
    }
]

export const Home = () => {
    const predictablePaymentsReleased =
        useAmpliFeatureFlag('predictable-payments-released') === 'treatment'

    const { homeViewed } = useAmpli()

    useEffect(() => {
        homeViewed()
    }, [homeViewed])

    const { user } = useUserInfo()

    const [showPreviewExplanationModal] = useModal(
        () => <PreviewExplanation showWhatsMissing={true} />,
        []
    )

    useEffect(() => {
        if (user?.membershipSignupStatus === 'preview') {
            showPreviewExplanationModal()
        }
    }, [user])

    return (
        <View sx={{ paddingBottom: 0 }}>
            <Container>
                <ScrollTarget name="book-appointment-scroll-target"></ScrollTarget>
                <div
                    id="book-appointment"
                    style={{
                        display: 'block',
                        position: 'relative',
                        top: '-120px'
                    }}
                ></div>
                <Heading h1>Book an appointment</Heading>

                <ServiceLines sx={{ mb: 8 }} />
            </Container>

            {predictablePaymentsReleased && <PredictablePaymentsFooter />}
        </View>
    )
}

const AnimatedLogo: FC = () => {
    const ballStyle: MotionStyle = {
        width: 20,
        height: 20,
        borderRadius: '100%',
        backgroundColor: '#EF308D',
        position: 'absolute',
        left: 44
    }

    return (
        <Flex
            sx={{
                width: '128px',
                height: '68px',
                position: 'relative'
            }}
        >
            <motion.div
                animate={{
                    y: [-6, -14, -16]
                }}
                transition={{
                    duration: 0.4,
                    yoyo: Infinity,
                    ease: 'easeOut'
                }}
                style={ballStyle}
            />
            <img src={TiaIcon} width="128px" height="68px" />
        </Flex>
    )
}

export const LoadingScreen: FC<{
    isLoading: boolean
    children?: ReactNode
}> = ({ isLoading, children }) => {
    return (
        <>
            <AnimatePresence>
                {isLoading && (
                    <motion.div
                        initial={{ opacity: 0 }}
                        animate={{
                            opacity: 1
                        }}
                        exit={{
                            opacity: 0
                        }}
                        transition={{
                            duration: 0.4
                        }}
                        style={{
                            position: 'absolute',
                            display: 'flex',
                            justifyContent: 'center',
                            alignItems: 'center',
                            width: '100vw',
                            height: '100vh'
                        }}
                    >
                        <AnimatedLogo />
                    </motion.div>
                )}
            </AnimatePresence>
            <motion.div
                initial={{ opacity: 0 }}
                animate={{
                    opacity: isLoading ? 0 : 1
                }}
                transition={{
                    duration: 1
                }}
            >
                {!isLoading && children}
            </motion.div>
        </>
    )
}

function useShouldSeeOnboarding() {
    const [searchParams] = useSearchParams()
    const { appointments: upcomingAppt, isLoading: isLoadingUpcoming } =
        useUpcomingAppointments()
    const { appointments: historicAppt, isLoading: isLoadingHistory } =
        useAppointmentHistory()

    const hasSignupQueryParam = searchParams.get('fromSignup') === 'true'
    const isNewUser = upcomingAppt.length === 0 && historicAppt.length === 0

    const shouldShow = isNewUser && hasSignupQueryParam

    return {
        show: shouldShow,
        isLoading: isLoadingHistory || isLoadingUpcoming
    }
}

const HomeTopDashboardPortion = () => {
    const { appointments: upcomingAppt, isLoading: isLoadingUappt } =
        useUpcomingAppointments()
    const { appointments: historicAppt, isLoading: isLoadingHappt } =
        useAppointmentHistory()

    if (isLoadingUappt || isLoadingHappt) {
        return null
    }

    const neverHadAppointmentsBefore =
        upcomingAppt.length === 0 && historicAppt.length === 0

    return (
        <Box
            sx={{
                mb: 8,
                position: 'relative'
            }}
        >
            {neverHadAppointmentsBefore ? (
                <OnboardingRecommendedActions />
            ) : (
                <ActionsToComplete />
            )}
        </Box>
    )
}

function Homepage() {
    const queries = useQueries(homepageQueries)
    const { show, isLoading } = useShouldSeeOnboarding()

    if (show) {
        return <Navigate to="/landing/welcome" />
    }

    return (
        <LoadingScreen isLoading={queries.some(q => q.isLoading) || isLoading}>
            <Header showBack={false} />
            <Box
                sx={{
                    position: 'sticky',
                    top: [64, 80],
                    zIndex: 3
                }}
            >
                <PaymentWarningBanner />
                <SuggestedAppointmentsBanner />
                <InsuranceWarningBanner />
            </Box>

            <HomeTopDashboardPortion />
            <Home />
        </LoadingScreen>
    )
}

export { Homepage }
