import { Box, Checkbox, Flex, Label } from '@asktia/tia-ui'
import { format } from 'date-fns'
import * as ThemeUI from 'theme-ui'
import { FC, useEffect } from 'react'
import { useFieldArray, useFormContext } from 'react-hook-form'
import { Divider } from 'src/components/Blocks'
import { PlusIcon } from 'src/components/Blocks/Icons'
import { useGetImmunizations } from '../../hooks/useGetImmunizations'
import { useAmpli } from 'src/hooks'

export interface ImmunizationFieldProps {
    label: string
    name: string
    immunizationCode: string
    defaultSelected?: boolean
    supportText?: string

    onCheckboxClick?: (
        evt: React.MouseEvent<HTMLInputElement, MouseEvent>
    ) => void
}

export const ImmunizationField: FC<ImmunizationFieldProps> = ({
    label,
    name,
    immunizationCode,
    defaultSelected,
    supportText,
    onCheckboxClick
}) => {
    const { addedImmunization, removedImmunization } = useAmpli()
    const { immunizations, isLoading } = useGetImmunizations()
    const datesName = `${name}-dates`

    const { fields, append, remove } = useFieldArray({
        name: datesName
    })

    const { register, watch, setValue } = useFormContext()
    const isChecked = watch(name)

    useEffect(() => {
        if (isLoading) {
            return
        }

        if (defaultSelected) {
            setValue(name, true)
        }

        const dates = immunizations
            .filter(
                (x: any) =>
                    x.vaccineCode.coding[0].code.toString() ===
                    immunizationCode.toString()
            )
            .map((x: any) => ({
                value: format(new Date(x.occurrenceDateTime), 'yyyy-MM-dd')
            }))

        if (dates.length > 0 && fields.length < dates.length) {
            dates.forEach((date: any) => append(date))
        }

        if (dates.length === 0 && fields.length === 0) {
            append({ value: '' })
        }
    }, [immunizationCode, immunizations, isLoading, fields])

    return (
        <>
            <Label
                sx={{
                    alignItems: 'center',
                    width: '100%',
                    fontSize: 4,
                    cursor: 'pointer'
                }}
            >
                <Checkbox
                    pretty
                    sx={{ minWidth: 'auto' }}
                    name={name}
                    onClick={evt => onCheckboxClick && onCheckboxClick(evt)}
                />
                {label}
            </Label>
            {supportText && (
                <Label
                    sx={{
                        margin: 0,
                        fontSize: 0,
                        color: 'supportText',
                        marginLeft: 56
                    }}
                >
                    {supportText}
                </Label>
            )}

            {isChecked &&
                fields.map((field, i) => (
                    <Box key={field.id}>
                        <Label sx={{ mt: 4 }}>Date</Label>
                        <ThemeUI.Input
                            type="date"
                            ref={register()}
                            name={`${datesName}.${i}.value`}
                            defaultValue={field.value}
                            placeholder={'mm/dd/yyyy'}
                            sx={{
                                'mb': 0,
                                'color': 'text',
                                '::-webkit-calendar-picker-indicator': {
                                    filter: 'invert(71%) sepia(2%) saturate(5054%) hue-rotate(319deg) brightness(92%) contrast(68%)'
                                },
                                ':disabled': {
                                    'borderColor': undefined,
                                    '::-webkit-calendar-picker-indicator': {
                                        visibility: 'visible',
                                        filter: 'invert(70%) sepia(5%) saturate(1779%) hue-rotate(314deg) brightness(91%) contrast(77%)'
                                    }
                                }
                            }}
                        />
                        {fields.length > 1 && (
                            <Label
                                sx={{
                                    mt: 1,
                                    mb: 0,
                                    fontSize: 0,
                                    color: 'raspberry',
                                    textDecoration: 'underline',
                                    cursor: 'pointer'
                                }}
                                onClick={() => {
                                    remove(i)
                                    removedImmunization()
                                }}
                            >
                                Remove
                            </Label>
                        )}
                    </Box>
                ))}

            {isChecked && (
                <Flex
                    sx={{
                        justifyContent: 'flex-end',
                        alignItems: 'center',
                        mt: 2
                    }}
                >
                    <Label
                        onClick={() => {
                            append({ value: '' })
                            addedImmunization()
                        }}
                        sx={{
                            display: 'flex',
                            alignItems: 'center',
                            width: 'fit-content',
                            color: 'raspberry',
                            cursor: 'pointer',
                            fontSize: 0,
                            mb: 0
                        }}
                    >
                        <PlusIcon color="raspberry" sx={{ mr: 2 }} /> Add
                        another
                    </Label>
                </Flex>
            )}
            <Divider sx={{ marginTop: 2, marginBottom: 2 }} />
        </>
    )
}
