import { useQuery } from 'react-query'
import { fetcher } from 'src/fetcher'
import { BASE_API_URL } from 'src/globals'

async function getImmunization() {
    return await fetcher(`${BASE_API_URL}/member-service/fhir/immunization`)
}

export const useGetImmunizations = () => {
    const { data, isLoading } = useQuery(
        ['get-immunizations'],
        getImmunization,
        {
            cacheTime: Infinity,
            staleTime: Infinity,
            refetchOnWindowFocus: false
        }
    )

    return {
        isLoading,
        immunizations: data || []
    }
}
