import { AsyncSelect, Box, Label, Text } from '@asktia/tia-ui'
import { FC, useEffect } from 'react'
import { FieldValues, useFormContext, UseFormMethods } from 'react-hook-form'
import { fetcher } from 'src/fetcher'
import { ImmunizationField } from 'src/flows/questionnaires/MedicalHistory/pages/Immunizations/components/ImmunizationField'
import {
    MedplumImmunizationItem,
    MedplumValueSetResponse
} from '../../../../types'
import { BASE_API_URL } from 'src/globals'
import { recommendedImmunizations } from 'src/flows/questionnaires/MedicalHistory/pages/Immunizations/components/RecommendedImmunizations'
import { uniqBy } from 'lodash'
import { useAmpli } from 'src/hooks'

interface ImmunizationOption {
    label: string
    value: string
    system: string
}

export const convertToApiPayload = (values: any): MedplumImmunizationItem[] => {
    const result: MedplumImmunizationItem[] = []
    const othersImmunizations = values.others || []

    if (othersImmunizations.length === 0) {
        return []
    }

    for (const immunization of othersImmunizations) {
        const checkBox = values[`other-${immunization.value}`]

        if (!checkBox) {
            continue
        }

        const dates = values[`other-${immunization.value}-dates`]?.map(
            (x: any) => x?.value
        )

        for (const date of dates) {
            result.push({
                resourceType: 'Immunization',
                status: 'completed',
                vaccineCode: {
                    coding: [
                        {
                            code: immunization.value,
                            display: immunization.label,
                            system: immunization.system
                        }
                    ],
                    text: immunization.label
                },
                occurrenceDateTime: new Date(date).toISOString()
            })
        }
    }
    return result
}

export const convertToFormPayload = (
    values: MedplumImmunizationItem[],
    formMethods: UseFormMethods<FieldValues>
) => {
    const recommendedImmunizationCodes = recommendedImmunizations.map(
        x => x.code
    )
    formMethods.setValue('otherSelect', '')

    formMethods.setValue(
        'others',
        uniqBy(
            values
                .filter(
                    x =>
                        !recommendedImmunizationCodes.includes(
                            x.vaccineCode.coding[0].code
                        )
                )
                .map(current => ({
                    label: current.vaccineCode.coding[0].display,
                    value: current.vaccineCode.coding[0].code,
                    system: current.vaccineCode.coding[0].system
                })),
            'value'
        )
    )
}

export const OtherImmunizations: FC = () => {
    const { clickedOnOtherImmunization, searchImmunization } = useAmpli()
    const { register, getValues, watch, setValue } = useFormContext()
    const otherSelect = watch('otherSelect')
    const otherImmunizations = watch('others')

    useEffect(() => {
        register('others')
    }, [])

    async function fetchVaccinesValueSetTypeahead(
        searchTerm: string
    ): Promise<ImmunizationOption[]> {
        if (!searchTerm) {
            // empty search errors out on backend
            return []
        }

        const res = await fetcher<MedplumValueSetResponse>(
            `${BASE_API_URL}/member-service/fhir/value-set?search=${searchTerm}`
        )

        if (res && res.expansion && res.expansion.contains) {
            const data = res.expansion.contains.map(valueSet => ({
                label: valueSet.display,
                value: valueSet.code,
                system: valueSet.system
            }))

            searchImmunization({ AmountOfImmunizationsFound: data.length })
            return data
        } else {
            return []
        }
    }

    useEffect(() => {
        if (otherSelect) {
            const values = getValues()
            const otherImmunizations = values['others'] || []

            setValue('others', [...otherImmunizations, otherSelect])
            setValue('otherSelect', '')
        }
    }, [otherSelect])

    return (
        <Box sx={{ mt: 5 }}>
            <Label sx={{ fontSize: 7, mb: 0 }}>Other Immunizations</Label>

            <Text
                sx={{
                    fontSize: 0,
                    color: 'supportText',
                    mt: 3,
                    mb: 4,
                    display: 'block'
                }}
            >
                If you've had other immunizations than the recommended list
                above, please add them.
            </Text>

            <Label sx={{ fontSize: 0, color: 'supportText' }}>
                Search Immunization
            </Label>
            <AsyncSelect
                name="otherSelect"
                loadOptions={async (searchTerm: string) =>
                    fetchVaccinesValueSetTypeahead(searchTerm)
                }
                getOptionValue={opt => opt?.value}
                hideSelectedOptions
                placeholder="e.g. Varicella shot"
            />

            {otherImmunizations?.map((immunization: ImmunizationOption) => (
                <ImmunizationField
                    key={`immunization-field-${immunization.value}`}
                    label={immunization.label}
                    name={`other-${immunization.value}`}
                    immunizationCode={immunization.value}
                    defaultSelected
                    onCheckboxClick={evt =>
                        clickedOnOtherImmunization({
                            Checked: (evt.target as any).checked
                        })
                    }
                />
            ))}
        </Box>
    )
}
