import { LayoutGrid, Flex, Text, Box, Heading } from '@asktia/tia-ui'

import { ReactComponent as Maintenance } from 'src/assets/billing-maintenance.svg'

const Maintenace = () => {
    return (
        <LayoutGrid columns="one">
            <Flex sx={{ flexDirection: 'column' }}>
                <Box
                    sx={{
                        display: 'flex',
                        justifyContent: 'center',
                        mt: 6
                    }}
                >
                    <Maintenance />
                </Box>
                <Heading h3>This page is under maintenance</Heading>
                <Text variant="supportingInfo">
                    We’re currently performing scheduled maintenance to improve
                    our services. We apologize for any inconvenience this may
                    cause. Please check back later for updates. Thank you for
                    your patience!
                </Text>
            </Flex>
        </LayoutGrid>
    )
}

export default Maintenace
