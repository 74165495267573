import {
    Box,
    Flex,
    Heading,
    Image,
    LayoutGrid,
    Link,
    Paragraph,
    Spinner,
    Text,
    Button
} from '@asktia/tia-ui'
import { format } from 'date-fns'
import { useParams } from 'react-router'
import { BackButton } from 'src/components/Blocks/BackButton'
import { Body } from 'src/components/Blocks/Body'
import { NavBarHeading } from 'src/components/Blocks/NavBarHeading'
import Linkify from 'src/components/Linkify'
import { PageHeader } from 'src/components/PageHeader'
import { Link as RouterLink } from 'react-router-dom'
import {
    Thread,
    useThread,
    DocumentReference,
    useMessagesForThread
} from 'src/pages/Chat/Thread/useThread'
import { SendMessageForm } from './SendMessageForm'
import { Message as MessageProps } from './useThread'
import { AttachmentIcon } from 'src/components/Blocks/Icons'
import { getRefinedDate } from 'src/utils'

const getFileDisplayName = (documentReference: DocumentReference) => {
    const name =
        documentReference.category !== 'Uncategorized'
            ? documentReference.category
            : documentReference.filename
    return name.length > 27 ? `${name.substr(0, 24)}...` : name
}

const getAttachmentDownloadUrl = (
    threadId: number,
    documentReference: DocumentReference
) => {
    let extension = '.jpg'

    switch (documentReference.mimeType) {
        case 'image/png':
            extension = '.png'
            break
        case 'image/heic':
            extension = '.heic'
            break
        case 'application/pdf':
            extension = '.pdf'
            break
    }

    return `/chat/thread/${threadId}/attachment/${documentReference.uuid}${extension}`
}

const Message = (props: { message: MessageProps; thread: Thread }) => {
    const author = props.thread.users.find(u => u.id === props.message.from)
    const authorName = author?.isPatient ? '' : author?.displayName

    const avatar =
        'https://assets.asktia.com/images/pastel_rebrand/logo-black.svg'

    const messageBody = props.message.body?.split('\n')
    const hasAttachment =
        props.message.documentReferences &&
        props.message.documentReferences.length > 0

    return (
        <Flex
            sx={{
                flexDirection: 'row',
                py: 4,
                alignItems: 'flex-start'
            }}
        >
            <Flex
                sx={{
                    minWidth: 40,
                    minHeight: 40,
                    width: 40,
                    height: 40,
                    maxWidth: 40,
                    maxHeight: 40,
                    bg: author?.isPatient
                        ? 'inputOutlineHover'
                        : 'secondaryBackground',
                    borderRadius: '100%',
                    alignItems: 'center',
                    justifyContent: 'center',
                    mr: 2
                }}
            >
                {author?.isPatient ? (
                    <Text
                        sx={{
                            color: 'white',
                            fontSize: 4,
                            fontWeight: 'bold'
                        }}
                    >
                        {author?.givenName?.toUpperCase()?.charAt(0)}
                        {author?.familyName?.toUpperCase().charAt(0)}
                    </Text>
                ) : (
                    <Image
                        src={avatar}
                        sx={{
                            px: 2,
                            minWidth: 40,
                            width: 40,
                            height: 40
                        }}
                    />
                )}
            </Flex>
            <Box>
                <Heading h4 sx={{ marginBottom: 1 }}>
                    {authorName}
                </Heading>
                {messageBody &&
                    messageBody.map((p, i) => (
                        <Paragraph key={i}>
                            <Linkify>{p}</Linkify>
                        </Paragraph>
                    ))}
                {hasAttachment &&
                    props.message?.documentReferences?.map(document => (
                        <Flex key={document.filename}>
                            <AttachmentIcon
                                fileType={
                                    document.mimeType.includes('pdf')
                                        ? 'pdf'
                                        : 'image'
                                }
                                color="cream"
                            />
                            <Link
                                href={getAttachmentDownloadUrl(
                                    props.message.threadId,
                                    document
                                )}
                                download={true}
                            >
                                {getFileDisplayName(document)}
                            </Link>
                        </Flex>
                    ))}
                <Text variant="supportingInfo" sx={{ fontSize: 0 }}>
                    {getRefinedDate(props.message.created)}
                </Text>
            </Box>
        </Flex>
    )
}

const Messages = (props: { threadId: number }) => {
    const { thread } = useThread(props.threadId!)
    const { isLoading, messages } = useMessagesForThread(props.threadId)

    if (!thread || !messages) {
        return <Spinner />
    }

    return (
        <Box
            sx={{
                height: '100%',
                overflow: 'auto',
                paddingBottom: 120,
                borderTop: '1px solid',
                borderColor: 'cardOutline'
            }}
        >
            {messages.map(message => (
                <Message message={message} thread={thread} key={message.id} />
            ))}
            <Box
                sx={{
                    borderTop: '1px solid',
                    borderColor: 'cardOutline',
                    mt: 4,
                    pt: 2
                }}
            >
                <Text
                    color="supportText"
                    sx={{
                        fontSize: 0
                    }}
                >
                    Thanks for your message! We are experiencing a high volume
                    of messages. Please expect a minimum of 3 business days to
                    receive a response. If you are experiencing an emergency,
                    please call 911.
                </Text>
            </Box>
        </Box>
    )
}

export const ThreadPage = () => {
    const params = useParams()
    // wouldn't route here without threadId
    const threadId = Number(params.threadId)
    const { thread } = useThread(threadId)

    return (
        <Body>
            <PageHeader
                mobileBack={<BackButton href="/chat" variant="white" />}
            />
            <NavBarHeading titleAlign="left" href="/chat" showBackButton>
                Re: {thread?.subject}
            </NavBarHeading>

            <LayoutGrid columns="one">
                <Box
                    sx={{
                        position: 'relative',
                        height: 'calc(100vh - 145px)', // - header and submit form
                        pl: [5, 0],
                        pr: [5, 0]
                    }}
                >
                    {/* wouldn't route here without threadId */}
                    <Messages threadId={threadId} />
                    {thread?.status === 'Archived' && (
                        <Text
                            sx={{
                                fontFamily: 'body',
                                color: 'supportText',
                                fontSize: 3
                            }}
                        >
                            Your care coordinator has closed this concern.
                            Please send us a new message if you have a new
                            concern.
                        </Text>
                    )}
                    {thread?.status !== 'Archived' && (
                        <SendMessageForm threadId={threadId} />
                    )}
                    {thread?.status === 'Archived' && (
                        <Button
                            mt={4}
                            as={RouterLink}
                            // @ts-ignore: TS doesn't understand objects can be indexed by string
                            to="/chat/"
                            fullWidth
                        >
                            Back To Inbox
                        </Button>
                    )}
                </Box>
            </LayoutGrid>
        </Body>
    )
}
