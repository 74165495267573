import { FC, useEffect } from 'react'
import { Flex, Text, Input, SelectWithMagnifyingGlass } from '@asktia/tia-ui'
import { FieldValues, UseFormMethods } from 'react-hook-form'
import {
    getDropdownOptions,
    DropdownSearchItem,
    formatGroupLabel,
    formatOptionLabel
} from 'src/pages/Appointments/components/FeelingFilters'
import { FilterHamburger } from 'src/pages/Appointments/components/FilterHamburger'
import { AppointmentProfile } from 'src/types'
import { useAmpli } from 'src/hooks'

export interface INewFeelingFilter {
    formMethods: UseFormMethods<FieldValues>
    offerings: AppointmentProfile[]
    numActiveFilters: number
    onFilterHamburger: () => void
}

type GroupedOption = {
    label: string
    options: DropdownSearchItem[]
}

function getLabel(
    options: GroupedOption[],
    symptom?: string,
    appointment?: string
) {
    const flatOptions = options.map(g => g.options).flat()

    return (
        flatOptions.find(o => o.value === symptom || o.value === appointment)
            ?.label || ''
    )
}

export const NewFeelingFilter: FC<INewFeelingFilter> = ({
    offerings,
    formMethods,
    numActiveFilters,
    onFilterHamburger
}) => {
    const { filterAppointmentsByKeyword } = useAmpli()
    const options = offerings ? getDropdownOptions(offerings) : []

    function onDropdownChange(option: DropdownSearchItem) {
        // the dropdown is being cleared
        if (!option) {
            formMethods.setValue('symptom', '')
            formMethods.setValue('appointment', '')
            return
        }
        // TODO: TM-4729 update for appointment filter type possibility
        filterAppointmentsByKeyword({
            filterType: option.type,
            filterKeyword: option.label
        })

        // Clear values, then set the one we care about
        formMethods.setValue('symptom', '')
        formMethods.setValue('appointment', '')

        if (option) {
            formMethods.setValue(option.type, option.value)
        }
    }

    useEffect(() => {
        const formValues = formMethods.getValues()
        const symptom = formValues.symptom
        const appointment = formValues.appointment

        // we prioritize filling in the symptom
        if (symptom) {
            formMethods.setValue('ignore_me', {
                type: 'symptom',
                value: symptom,
                label: getLabel(options, symptom)
            })
        }

        if (appointment) {
            formMethods.setValue('ignore_me', {
                type: 'appointment',
                value: appointment,
                label: getLabel(options, undefined, appointment)
            })
        }
    }, [])

    return (
        <Flex
            sx={{
                flexDirection: 'row',
                justifyContent: 'space-between',
                alignItems: ['flex-end', 'flex-end', 'center'],
                mb: 4
            }}
        >
            <Flex
                sx={{
                    alignItems: ['flex-start', 'center'],
                    flexDirection: ['column', 'row'],
                    flex: 1,
                    width: '100%'
                }}
            >
                <Text
                    sx={{
                        mr: [0, 2],
                        fontFamily: 'inferi',
                        fontWeight: 350,
                        fontSize: 32,
                        lineHeight: '36px',
                        mb: [2, 0]
                    }}
                >
                    I'm looking for support with
                </Text>

                <Input type="hidden" name="symptom" />
                <Input type="hidden" name="appointment" />
                <SelectWithMagnifyingGlass
                    name="ignore_me"
                    options={options}
                    isSearchable
                    isClearable
                    placeholder={
                        <div id="select-placeholder">
                            Search symptoms, feelings, appointments
                        </div>
                    }
                    onChange={option => {
                        onDropdownChange(option as DropdownSearchItem)
                    }}
                    formatOptionLabel={formatOptionLabel}
                    formatGroupLabel={formatGroupLabel}
                    sx={{
                        'width': '100%',
                        'flex': 1,
                        'margin': 0,
                        '#select-placeholder': {
                            margin: 0,
                            pr: 1,
                            whiteSpace: 'nowrap',
                            overflow: 'hidden',
                            textOverflow: 'ellipsis',
                            maxWidth: '95%'
                        }
                    }}
                />
            </Flex>

            <FilterHamburger
                onClick={onFilterHamburger}
                numActiveFilters={numActiveFilters}
            />
        </Flex>
    )
}
